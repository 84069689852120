import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import {
  checkIfCompleted,
  findAccountsByGroups,
  slugify,
} from '../../utilities';

import ButtonGroup from '../ButtonGroup';
import Spiral from '../visualizations/Spiral';
import WithAccounts from '../hoc/WithAccounts';
import WithProgress from '../hoc/WithProgress';
import WithUi from '../hoc/WithUi';

import { SelectionButton } from '../styled/buttons';
import { H1, P } from '../styled/type';
import { LockIcon, RightArrow } from '../styled/icons';
import {
  Flex,
  FlexRow,
  PaperContentWidth,
  SelectionButtonContainer,
} from '../styled/containers';
import {
  InitializeAnimationFadeDelay1,
  InitializeAnimationFadeDelay2,
  InitializeAnimationFadeDelay3,
  InitializeAnimationFadeDelay4,
} from '../styled/animation';

class ActionTasks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      overlay: {
        accounts: [],
        isActive: false,
      },
    };

    this.boundCalculateOverlay = this.calculateOverlay.bind(this);
  }
  calculateOverlay(isEnabled, account = '', groups) {
    const {
      accounts,
      ui: { areInteractionsBlocked },
    } = this.props;

    // prevent if transitioning/animating
    if (areInteractionsBlocked) {
      return;
    }

    const activeAccounts = (groups &&
      findAccountsByGroups(accounts, groups)) || [account];

    this.setState({
      overlay: {
        activeAccounts,
        isEnabled,
      },
    });
  }
  render() {
    const {
      boundCalculateOverlay,
      props: {
        accounts,
        action: { copy, tasks },
        actionSlug,
        exit,
        moduleSlug,
        progress,
      },
      state: { overlay },
    } = this;

    return (
      <PaperContentWidth wide paddingTop="xl">
        <FlexRow>
          <Flex paddingRight="xl">
            <InitializeAnimationFadeDelay1>
              <H1>Pick a Task</H1>
              <P>{copy}</P>
            </InitializeAnimationFadeDelay1>

            {tasks.map(({ groups, name }) => (
              <InitializeAnimationFadeDelay2 key={name}>
                <SelectionButtonContainer>
                  <LockIcon
                    paddingRight="sm"
                    large
                    isComplete={checkIfCompleted(slugify(name), progress)}
                  />
                  <SelectionButton
                    onMouseEnter={() =>
                      boundCalculateOverlay(true, null, groups)
                    }
                    onMouseLeave={() => boundCalculateOverlay(false)}
                    to={`/secure/${moduleSlug}/${actionSlug}/${slugify(name)}`}>
                    {name}
                  </SelectionButton>
                  <RightArrow />
                </SelectionButtonContainer>
              </InitializeAnimationFadeDelay2>
            ))}
          </Flex>
          <Flex>
            <InitializeAnimationFadeDelay4>
              <Spiral
                calculateOverlay={this.boundCalculateOverlay}
                dataset={accounts}
                launchModal={() => {}}
                overlay={overlay}
              />
            </InitializeAnimationFadeDelay4>
          </Flex>
        </FlexRow>

        <InitializeAnimationFadeDelay3>
          <ButtonGroup
            buttonSecondaryText="Back"
            onClickButtonSecondary={exit}
          />
        </InitializeAnimationFadeDelay3>
      </PaperContentWidth>
    );
  }
}

const propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      groups: PropTypes.array,
      tasks: PropTypes.array,
    })
  ),
  action: PropTypes.shape({
    copy: PropTypes.string,
    introCopy: PropTypes.string,
    name: PropTypes.string,
  }),
  actionSlug: PropTypes.string,
  exit: PropTypes.func.isRequired,
  moduleSlug: PropTypes.string,
  progress: PropTypes.array,
};
ActionTasks.propTypes = propTypes;

export default compose(
  WithAccounts,
  WithProgress,
  WithUi
)(ActionTasks);
