import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addAccount } from '../../actions';

import DevAccountTable from './DevAccountTable';

import {
  findAccountInAccountsByName,
  mergeInputAccountWithLookupAccount,
} from '../../utilities';

import { ButtonPrimary } from '../styled/buttons';
import { ContentWidth, Paper, Table, Td } from '../styled/containers';
import { InnerSection, Section } from '../styled/dev';
import { H1, H2, P } from '../styled/type';

import { ACCOUNTS_FOUND_BY_SCAN } from '../../data/accountsFoundByScan.js';

class ScanResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountsToConfirm: ACCOUNTS_FOUND_BY_SCAN,
      accountToConfirm: {},
      currentStep: 0,
      noNewFound: false,
    };
    this.boundFindNonDuplicate = this.findNonDuplicate.bind(this);
  }
  componentDidMount() {
    const { currentStep } = this.state;

    this.boundFindNonDuplicate(currentStep);
  }

  findNonDuplicate = innerStep => {
    const {
      props: { accounts },
      state: { accountsToConfirm },
    } = this;

    // we've reached the end of accountsToConfirm
    if (innerStep === accountsToConfirm.length) {
      this.setState({
        noNewFound: true,
      });
      return;
    }

    const { name } = accountsToConfirm[innerStep];
    const accountToConfirm = mergeInputAccountWithLookupAccount(name);

    const userAlreadyHasThisAccount = findAccountInAccountsByName(
      accountToConfirm.name,
      accounts
    );

    // see if accounts contain account already
    if (userAlreadyHasThisAccount) {
      this.boundFindNonDuplicate(innerStep + 1);
    } else {
      this.setState({
        accountToConfirm: accountToConfirm,
        currentStep: innerStep,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentStep !== this.state.currentStep) {
      this.boundFindNonDuplicate(this.state.currentStep);
    }
  }
  nextAccount() {
    const { accountsToConfirm, currentStep } = this.state;

    if (currentStep === accountsToConfirm.length - 1) {
      this.props.history.push('/');
    } else {
      this.setState({
        currentStep: this.state.currentStep + 1,
      });
    }
  }
  denyAccount() {
    this.nextAccount();
  }
  confirmAccount(accountToAdd) {
    this.nextAccount();
    this.props.addAccount(accountToAdd.name, accountToAdd.group);
  }
  render() {
    const {
      props: { accounts },
      state: { accountsToConfirm, accountToConfirm, noNewFound },
    } = this;

    const accountGroups = ['bank', 'payment', 'investment'];
    const remainingFinancialAccounts = accounts.filter(
      ({ groups = [] }) =>
        groups.includes('financial') &&
        !groups.includes('bank') &&
        !groups.includes('payment') &&
        !groups.includes('investment')
    );

    return (
      <ContentWidth wide paddingTop="lg2">
        <Paper>
          <H1>
            <span role="img" aria-label="$">
              {'💲'}
            </span>
          </H1>
          {!noNewFound ? (
            <InnerSection>
              <P>
                Our scan found {accountsToConfirm.length} financial accounts in
                your inbox:
              </P>

              <Table>
                <tbody>
                  <tr>
                    <Td>{accountToConfirm.name}</Td>
                    <Td>
                      <button
                        onClick={() => this.confirmAccount(accountToConfirm)}>
                        ?
                      </button>
                    </Td>
                    <Td>
                      <button
                        onClick={() => this.confirmAccount(accountToConfirm)}>
                        confirm
                      </button>
                    </Td>
                    <Td>
                      <button
                        onClick={() => this.denyAccount(accountToConfirm)}>
                        deny
                      </button>
                    </Td>
                  </tr>
                </tbody>
              </Table>
            </InnerSection>
          ) : (
            <P>No new accounts found.</P>
          )}
          {accountGroups.map(accountGroup => {
            const accountsFilteredByGroup = accounts.filter(({ groups = [] }) =>
              groups.includes(accountGroup)
            );
            return (
              <Section key={accountGroup}>
                <H2>{accountGroup}</H2>
                {this.props.accounts.length > 0 ? (
                  <div>
                    <DevAccountTable
                      accounts={accountsFilteredByGroup}
                      accountsToHighlight={accountsToConfirm}
                      showDeleteButton
                    />
                  </div>
                ) : (
                  <p>no accounts</p>
                )}
              </Section>
            );
          })}
          <Section>
            <H2>other financial</H2>
            {remainingFinancialAccounts.length > 0 ? (
              <div>
                <DevAccountTable
                  accounts={remainingFinancialAccounts}
                  accountsToHighlight={accountsToConfirm}
                  showDeleteButton
                />
              </div>
            ) : (
              <p>no accounts</p>
            )}
          </Section>
          <Section>
            <ButtonPrimary as={Link} to="/">
              Home
            </ButtonPrimary>
          </Section>
        </Paper>
      </ContentWidth>
    );
  }
}

const mapStateToProps = ({ accounts }) => ({
  accounts,
});
export default connect(
  mapStateToProps,
  { addAccount }
)(ScanResults);
