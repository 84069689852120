import React from 'react';
import PropTypes from 'prop-types';

import { filterAccountsByCategory } from '../../utilities';

import Spiral from '../visualizations/Spiral';
import WithAccounts from '../hoc/WithAccounts';

import { Flex, FlexRow, PaperContentWidth } from '../styled/containers';
import { H1 } from '../styled/type';
import {
  InitializeAnimationFadeDelay1,
  InitializeAnimationFadeDelay3,
} from '../styled/animation';

const SlideConclusion = ({ accounts, categoryParam }) => (
  <PaperContentWidth wide>
    <FlexRow>
      <Flex paddingRight="xl" paddingTop="xxl">
        <InitializeAnimationFadeDelay1 />
        <H1 maxWidth="80%">
          Nice! You've captured some of the most important accounts in your
          digital footprint.
        </H1>
      </Flex>
      <Flex paddingTop="xl">
        <InitializeAnimationFadeDelay3>
          <Spiral
            calculateOverlay={() => {}}
            dataset={accounts}
            launchModal={() => {}}
            overlay={{
              isEnabled: true,
              activeAccounts: filterAccountsByCategory(accounts, categoryParam),
            }}
          />
        </InitializeAnimationFadeDelay3>
      </Flex>
    </FlexRow>
  </PaperContentWidth>
);

const propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      groups: PropTypes.array,
      tasks: PropTypes.array,
    })
  ),
  categoryParam: PropTypes.string.isRequired,
};
SlideConclusion.propTypes = propTypes;

export default WithAccounts(SlideConclusion);
