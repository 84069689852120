import React, { Component } from 'react';

import { H2 } from '../styled/type';
import { Section } from '../styled/dev';
import { Td, Tr } from '../styled/containers';

class ResourceRequestTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: {
        cosmic: {
          name: 'Cosmic JS GraphQL endpoint',
          url: 'https://api.cosmicjs.com/v1/ds-demo',
          status: 'untested',
          data: null,
        },
        s3: {
          name: 'S3',
          url: 'https://s3-us-west-2.amazonaws.com/ds-web-demo/test-image.png',
          status: 'untested',
          data: null,
        },
        control: {
          name: 'control (should fail)',
          url: 'https://not-a-real-endpoint.test',
          status: 'untested',
        },
      },
    };

    this.boundRunTest = this.runTest.bind(this);
  }
  runTest(resourceKey) {
    const { url } = this.state.resources[resourceKey];

    this.setState({
      resources: {
        ...this.state.resources,
        [resourceKey]: {
          ...this.state.resources[resourceKey],
          status: 'in progress',
        },
      },
    });

    // success/failure handlers
    const testSuccess = (resourceKey, data) => {
      console.log('testSuccess()', data);
      this.setState({
        resources: {
          ...this.state.resources,
          [resourceKey]: {
            ...this.state.resources[resourceKey],
            status: 'success',
            data,
          },
        },
      });
    };
    const testFailure = resourceKey => {
      console.log('testFailure()', resourceKey);
      this.setState({
        resources: {
          ...this.state.resources,
          [resourceKey]: {
            ...this.state.resources[resourceKey],
            status: 'failure',
          },
        },
      });
    };

    // fetch and call state-update handler
    fetch(url, {
      origin: 'http://localhost:3000',
    })
      .then(res => {
        console.log('res', res);

        if (!res.ok) {
          return testFailure(resourceKey);
        }

        const data = resourceKey === 'cosmic' ? res.json() : res.blob();
        return data;
      })
      .then(data => {
        return testSuccess(resourceKey, data);
      })
      .catch(err => {
        return testFailure(resourceKey);
      });
  }
  render() {
    const { resources } = this.state;
    return (
      <Section>
        <H2>Resource Tests</H2>
        <table>
          <thead>
            <Tr>
              <Td>Name</Td>
              <Td>URL</Td>
              <Td>status</Td>
            </Tr>
          </thead>
          <tbody>
            {Object.keys(resources).map(resourceKey => {
              const { name, status, url } = resources[resourceKey];
              return (
                <Tr key={url}>
                  <Td>{name}</Td>
                  <Td>{url}</Td>
                  <Td>
                    {status === 'untested' ? (
                      <button onClick={() => this.boundRunTest(resourceKey)}>
                        Run test
                      </button>
                    ) : (
                      <span>{status}</span>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </tbody>
        </table>
      </Section>
    );
  }
}

export default ResourceRequestTest;
