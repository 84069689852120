import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { getOuterHeight } from '../../utilities';

import WithAccounts from '../hoc/WithAccounts';
import WithMasterModuleList from '../hoc/WithMasterModuleList';
import WithUi from '../hoc/WithUi';

import styled from 'styled-components';
import { ContentWidth } from '../styled/containers';
import { H1 } from '../styled/type';
import { InitializeAnimationFadeDelay3 } from '../styled/animation';

const HomeHeaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: ${props => !props.isHome && 'none'};
`;
const H1Container = styled.div`
  margin-top: ${props => props.theme.spacing.vertical.lg};
  margin-bottom: ${props => props.theme.spacing.vertical.xl};
  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    max-width: 60%;
  }
`;

class HomeHeader extends Component {
  componentDidMount() {
    if (this.props.isHome) {
      const homeHeaderHeight = getOuterHeight(this.headerContainerRef);
      this.props.setHomeHeaderHeight(homeHeaderHeight);
    }
  }
  componentDidUpdate(prevProps) {
    const { isHome: oldIsHome } = prevProps;
    const { isHome: newIsHome, setHomeHeaderHeight } = this.props;
    const isHomeHasChanged = newIsHome !== oldIsHome;

    if (isHomeHasChanged && newIsHome) {
      const homeHeaderHeight = getOuterHeight(this.headerContainerRef);
      setHomeHeaderHeight(homeHeaderHeight);
    } else if (isHomeHasChanged && !newIsHome) {
      setHomeHeaderHeight(0);
    }
  }
  render() {
    return (
      <HomeHeaderContainer
        ref={ref => (this.headerContainerRef = ref)}
        isHome={this.props.isHome}>
        <ContentWidth fullHeight marginBottom={0} paddingTop={0} wide>
          <H1Container>
            <InitializeAnimationFadeDelay3>
              <H1
                color="white"
                marginBottom={0}
                lineHeight="compressedAlt"
                size="h1Alt">
                Welcome! Are you ready to make your digital footprint safer?
              </H1>
            </InitializeAnimationFadeDelay3>
          </H1Container>
        </ContentWidth>
      </HomeHeaderContainer>
    );
  }
}

const propTypes = {
  isHome: PropTypes.bool,
};
HomeHeader.propTypes = propTypes;

export default compose(
  WithAccounts,
  WithMasterModuleList,
  WithUi
)(HomeHeader);
