import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import styled from 'styled-components';

import WithAnimation from './hoc/WithAnimation';
import { TRANSITION } from '../constants/general.js';
const { MD_DURATION } = TRANSITION;

export const StyledAnimationLinkPaper = styled.div`
  flex: 1;
  display: ${props => (props.display ? props.display : 'inline-block')};
  cursor: pointer;
`;

class AnimationLinkPaper extends Component {
  clickHandler() {
    const {
      history,
      location: { pathname },
      to,
    } = this.props;

    if (pathname === to) {
      return;
    }

    this.props.setIsPaperIn(false);

    setTimeout(() => {
      history.push(to);
    }, MD_DURATION);
  }
  render() {
    const { children, display } = this.props;
    return (
      <StyledAnimationLinkPaper
        display={display}
        onClick={() => this.clickHandler()}>
        {children}
      </StyledAnimationLinkPaper>
    );
  }
}

const propTypes = {
  display: PropTypes.string,
  to: PropTypes.string.isRequired,
};
AnimationLinkPaper.propTypes = propTypes;

export default compose(
  withRouter,
  WithAnimation
)(AnimationLinkPaper);
