import React from 'react';
import { Link } from 'react-router-dom';

import ReactSVG from 'react-svg';

import styled from 'styled-components';
import { fontSizer, transitionXs, transitionMd } from './mixins';

import { ASSET_PATH } from '../../constants/general.js';

// headings
export const Heading = styled.h1`
  max-width: ${props => props.maxWidth && props.maxWidth};
  margin: 0;
  margin-top: ${props => props.theme.spacing.vertical[props.marginTop]};
  margin-bottom: ${props => props.theme.spacing.vertical[props.marginBottom]};
  font-family: ${props => props.theme.type.family.heading};
  color: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.gray7};
  ${props =>
    fontSizer(
      props.theme.type.size[props.size],
      props.theme.type.multipliers.heading,
      props.theme.breakpoints
    )};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};
  line-height: ${props =>
    props.lineHeight
      ? props.theme.type.lineHeight[props.lineHeight]
      : props.theme.type.lineHeight.regular};
  text-transform: ${props => props.uppercase && 'uppercase'};
  -webkit-font-smoothing: antialiased;
  cursor: default;
  letter-spacing: ${props =>
    props.letterSpacing
      ? props.theme.type.letterSpacing[props.letterSpacing]
      : props.theme.type.letterSpacing.regular};
  border-bottom: ${props =>
    props.borderBottom && `1px solid ${props.theme.colors.grayFade}`};

  a:hover {
    color: ${props => props.hoverColor && props.theme.colors[props.hoverColor]};
  }
`;

export const H1 = props => (
  <Heading
    {...props}
    as="h1"
    children={props.children}
    color={props.color ? props.color : 'gray4'}
    fontWeight={props.fontWeight ? props.fontWeight : 500}
    letterSpacing="narrow"
    lineHeight="compressedAlt"
    marginBottom={
      typeof props.marginBottom !== 'undefined' ? props.marginBottom : 'md'
    }
    size="h1"
  />
);
export const H2 = props => (
  <Heading
    {...props}
    as="h2"
    children={props.children}
    color={props.color ? props.color : 'gray7'}
    fontWeight={props.fontWeight ? props.fontWeight : 500}
    letterSpacing="narrow"
    marginBottom="sm2"
    size="h2"
    uppercase
  />
);
export const H3 = props => (
  <Heading
    {...props}
    as="h3"
    children={props.children}
    color="gray4"
    size="h3"
  />
);
export const H4 = props => (
  <Heading
    {...props}
    as="h4"
    children={props.children}
    color="gray4"
    marginBottom="xs"
    size="h4"
  />
);
export const H5 = props => (
  <Heading
    {...props}
    as="h5"
    children={props.children}
    marginBottom="lg"
    size="h5"
  />
);
export const H6 = props => (
  <Heading
    {...props}
    as="h6"
    children={props.children}
    color="gray9"
    fontWeight={500}
    letterSpacing="narrow"
    size="h6"
    uppercase
  />
);
export const NumOfNum = styled.span`
  font-size: ${props =>
    props.small ? props.theme.type.size.xxs : props.theme.type.size.lg}px;
  color: gray;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: ${props => props.theme.spacing.vertical.sm};

  span {
    color: ${props => props.theme.colors.primary};
  }
`;

const StackedContainer = styled.div`
  display: inline-block;
  text-align: right;
`;

export const StackedH2 = props => (
  <StackedContainer>
    <Heading
      {...props}
      as="h5"
      children={props.children}
      color="gray7"
      marginBottom={0}
      size="h5"
      uppercase
    />
  </StackedContainer>
);

// body
export const P = styled.p`
  max-width: ${props => props.maxWidth && props.maxWidth};
  margin-top: ${props =>
    typeof props.marginTop !== 'undefined' &&
    props.theme.spacing.vertical[props.marginTop]};
  margin-bottom: ${props =>
    typeof props.marginBottom !== 'undefined'
      ? props.theme.spacing.vertical[props.marginBottom]
      : props.theme.spacing.vertical.lg};
  margin-left: ${props => props.centered && 'auto'};
  margin-right: ${props => props.centered && 'auto'};
  ${props =>
    fontSizer(
      props.size
        ? props.theme.type.size[props.size]
        : props.theme.type.size.body,
      props.theme.type.multipliers.body,
      props.theme.breakpoints
    )};
  color: ${props => props.color && props.theme.colors[props.color]};
  font-weight: ${props => props.bold && 'bold'};
  text-align: ${props => props.centered && 'center'};
  cursor: default;
`;
export const SmallText = styled.span`
  max-width: 80%;
  margin: 0 0 ${props => props.theme.spacing.vertical.md};
  color: ${props => props.color && props.theme.colors[props.color]};
  letter-spacing: ${props => props.theme.type.letterSpacing.regular};
  font-family: ${props => props.theme.type.family.heading};
  line-height: ${props => props.theme.type.lineHeight.extended};
  text-transform: uppercase;
  font-weight: ${props => props.fontWeight && props.fontWeight};
  font-size: ${props => props.theme.type.size.sm}px;
  ${props =>
    fontSizer(
      props.theme.type.size.xs,
      props.theme.type.multipliers.body,
      props.theme.breakpoints
    )};
`;
export const Em = styled.span`
  font-style: italic;
`;

// links
export const NavLink = styled(Link)`
  display: block;
  font-family: ${props => props.theme.type.family.heading};
  text-transform: uppercase;
  font-size: ${props => props.theme.type.size.sm}px;
`;
export const NavClickLink = styled.a`
  display: block;
  font-family: ${props => props.theme.type.family.heading};
  text-transform: uppercase;
  font-size: ${props => props.theme.type.size.sm}px;
  cursor: pointer;
`;
export const Breadcrumb = styled.a`
  &:hover {
    color: ${props => props.theme.colors.gray7};
  }
`;
export const CurrentBreadcrumb = styled.a`
  cursor: default;
  color: ${props => props.theme.colors.gray7};
  opacity: 1 !important;
`;
export const DummyBreadcrumb = styled.a`
  cursor: default;
  color: ${props => props.theme.colors.gray7};
`;
export const DisplayStateLink = styled.a.attrs({
  color: props => {
    if (props.highlight) {
      return props.theme.colors.primary;
    } else if (props.mute) {
      return props.theme.colors.gray7;
    } else {
      return props.theme.colors.gray7;
    }
  },
})`
  display: inline-block;
  margin-bottom: ${props => props.theme.spacing.vertical.xs};
  color: ${props => props.color};
  ${props =>
    fontSizer(
      props.theme.type.size.sm,
      props.theme.type.multipliers.body,
      props.theme.breakpoints
    )};
  font-weight: 500;
  letter-spacing: ${props => props.theme.type.letterSpacing.narrow};
  line-height: ${props => props.theme.type.lineHeight.compressed};
  ${transitionMd};
  cursor: ${props => (props.cursor ? props.cursor : 'pointer')};

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;
const LinkBasicContainer = styled.div`
  display: inline-block;
`;
const LinkBasicInner = styled(Link)`
  color: ${props => props.theme.colors.premium};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '500')};
  cursor: pointer;
  text-transform: capitalize;
  ${transitionXs};

  &:hover {
    color: ${props => props.theme.colors.premiumHover};
  }
`;
const ExternalLinkContainer = styled.div`
  display: inline-block;
  padding-left: ${props => props.theme.spacing.horizontal.sm};
  width: 11px;

  path {
    fill: ${props => props.theme.colors.premium};
    stroke: ${props => props.theme.colors.premium};
  }
`;
export const LinkBasic = props =>
  props.icon ? (
    <LinkBasicContainer>
      <LinkBasicInner {...props}>{props.children}</LinkBasicInner>
      <ExternalLinkContainer>
        <ReactSVG src={`${ASSET_PATH}/svg/external-link.svg`} />
      </ExternalLinkContainer>
    </LinkBasicContainer>
  ) : (
    <LinkBasicInner {...props}>{props.children}</LinkBasicInner>
  );

const TaskOutboundLinkContainer = styled.a`
  color: ${props => props.theme.colors.premium};
  cursor: pointer;
  ${transitionXs};

  &:hover {
    color: ${props => props.theme.colors.premiumHover};

    path {
      fill: ${props => props.theme.colors.premiumHover};
      stroke: ${props => props.theme.colors.premiumHover};
    }
  }
`;
export const TaskOutboundLink = props => (
  <TaskOutboundLinkContainer {...props}>
    {props.children}
    <ExternalLinkContainer>
      <ReactSVG src={`${ASSET_PATH}/svg/external-link.svg`} />
    </ExternalLinkContainer>
  </TaskOutboundLinkContainer>
);

export const HomeModalActionLink = styled(Link)`
  margin-bottom: ${props => props.theme.spacing.vertical.xs};
  color: ${props => props.theme.colors.gray4};
  font-weight: bold;
  cursor: pointer;
`;

export const HomeModalTaskLink = styled(Link)`
  margin-bottom: ${props => props.theme.spacing.vertical.xs};
  color: ${props => props.theme.colors.gray4};
  cursor: pointer;
`;

// Home
export const AccordionHeading = styled(H3)`
  margin-bottom: ${props => props.theme.spacing.vertical.md};
`;
