import React from 'react';

import WithAnimation from '../hoc/WithAnimation';

import styled from 'styled-components';
import {
  bgGradient,
  flexRowSpaceBetweenCenter,
  fontSizer,
  transitionXs,
  transitionMd,
  transitionSm,
  transitionXl,
} from './mixins';

export const BodyBg = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  ${bgGradient};
`;
export const ContentWidth = styled.div`
  position: ${props => props.position && props.position};
  box-sizing: border-box;
  height: ${props => props.fullHeight && '100%'};
  max-width: ${props => (props.wide ? '95%' : '600px')};
  padding-top: ${props =>
    props.paddingTop && props.theme.spacing.vertical[props.paddingTop]};
  padding-left: ${props => props.wide && '3em'};
  padding-right: ${props => props.wide && '3em'};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${props => props.theme.spacing.vertical[props.marginTop]};
  margin-bottom: ${props =>
    typeof props.marginBottom !== 'undefined'
      ? props.theme.spacing.vertical[props.marginBottom]
      : props.theme.spacing.vertical.xxl};

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    max-width: ${props => props.wide && '96%'};
  }
  @media (min-width: ${props => props.theme.breakpoints.md}px) {
    max-width: ${props => props.wide && '95%'};
  }
  @media (min-width: ${props => props.theme.breakpoints.lg}px) {
    max-width: ${props => props.wide && '92%'};
  }
  @media (min-width: ${props => props.theme.breakpoints.xl}px) {
    max-width: ${props => props.wide && '86%'};
  }
  @media (min-width: ${props => props.theme.breakpoints.xxl}px) {
    max-width: ${props => props.wide && '80%'};
  }
`;
export const Center = styled.div`
  margin-top: ${props =>
    props.marginTop && props.theme.spacing.vertical[props.marginTop]};
  margin-bottom: ${props =>
    props.marginBottom && props.theme.spacing.vertical[props.marginBottom]};
  text-align: center;
`;
export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%; // ancestors also need 100% height
`;
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-content: stretch;
  padding-top: ${props =>
    props.paddingTop && props.theme.spacing.vertical[props.paddingTop]};
`;
export const FlexRowVAlign = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const PaperContainer = styled.div`
  position: relative;
  min-height: ${props => props.theme.hardCodedSizes.minPaperHeight}px;
  background: ${props => props.theme.colors.white};
`;
const PaperFader = styled.div`
  opacity: ${props => (props.isIn ? 1 : 0)};
  ${transitionSm};
`;
export const Paper = WithAnimation(props => (
  <PaperContainer {...props}>
    <PaperFader isIn={props.animation.isPaperIn}>{props.children}</PaperFader>
  </PaperContainer>
));
export const PaperContentWidth = styled.div.attrs({
  sidepadding: props => (props.wide ? '7%' : `${100 / 6}%`),
})`
  padding-top: ${props =>
    props.paddingTop && props.theme.spacing.vertical[props.paddingTop]};
  padding-left: ${props => props.sidepadding};
  padding-right: ${props => props.sidepadding};
`;
export const PaperSmall = styled.div`
  display: ${props => props.flex && 'flex'};
  flex-direction: ${props => props.flex && 'row'};
  background: ${props => props.theme.colors.white};
  margin-bottom: ${props => props.theme.spacing.vertical.lg};
  padding-top: ${props => props.theme.spacing.vertical.md};
  padding-bottom: ${props => props.theme.spacing.vertical.lg};
  padding-left: ${props => props.theme.spacing.fixed.accordionIndentation};
  padding-right: ${props => props.theme.spacing.fixed.accordionIndentationAlt};
`;

export const NavContainer = styled.nav`
  border: 1px solid ${props => props.theme.colors.grayFade};
  position: fixed;
  top: 5vh;
  right: 6vh;
  padding: 2em;
  line-height: 2;
  opacity: 0.6;
  background: #fff;
  z-index: ${props => props.theme.zIndex.one};
  ${transitionMd};

  &:hover {
    opacity: 0.95;
  }
  a:hover {
    color: ${props => props.theme.colors.primary};
  }
`;
export const Table = styled.table`
  height: ${props =>
    props.theme.hardCodedSizes.containedInnerPaperContentHeight}px;
  margin-bottom: ${props => props.theme.spacing.vertical.lg};
  margin-left: ${props =>
    props.marginSides && props.theme.spacing.horizontal[props.marginSides]};
  margin-right: ${props =>
    props.marginSides && props.theme.spacing.horizontal[props.marginSides]};
  padding: ${props => props.theme.spacing.horizontal.sm}
    ${props => props.theme.spacing.vertical.lg}
    ${props => props.theme.spacing.vertical.sm2};
  border: 2px solid ${props => props.theme.colors.grayFadeAltLightest};
`;
export const Th = styled.th`
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
  &:first-child {
    text-align: left;
  }
`;
export const Tr = styled.tr`
  padding: ${props => props.theme.spacing.vertical.sm2}
    ${props => props.theme.spacing.vertical.lg};
  td {
    background: ${props =>
      props.highlightAccount && props.theme.colors.greenFade};
  }
`;
export const Td = styled.td`
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
  position: relative;
  ${props =>
    fontSizer(
      props.theme.type.size.sm,
      props.theme.type.multipliers.body,
      props.theme.breakpoints
    )};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-child {
    min-width: 140px;
    text-align: left;
  }
`;
const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  padding: 0;
  margin: 1px 0 0 -2px;
  border: 0;
  opacity: 0;
  height: ${props => props.theme.spacing.horizontal.lg};
  width: ${props => props.theme.spacing.horizontal.lg};
  position: absolute;
  white-space: nowrap;
`;
const StyledCheckbox = styled.div`
  display: inline-block;
  height: ${props => props.theme.spacing.vertical.sm2};
  width: ${props => props.theme.spacing.vertical.sm2};
  border: 1px solid
    ${props =>
      props.value ? props.theme.colors.primary : props.theme.colors.gray9};
  background: ${props => props.value && props.theme.colors.primary};
`;
export const Checkbox = ({ value, ...props }) => (
  <CheckboxContainer>
    <HiddenCheckbox value={value} {...props} />
    <StyledCheckbox value={value} />
  </CheckboxContainer>
);

// Visualization
export const VisualizationContainer = styled.div`
  position: relative;
  top: -30px;
  height: 280px;
  width: 280px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${props => props.theme.breakpoints.lg}px) {
    height: 448px;
    width: 448px;
  }
  @media (min-width: ${props => props.theme.breakpoints.xl}px) {
    height: 560px;
    width: 560px;
  }
`;

// Module Intro & Actions
export const IntroSVGContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 80%;

  // for ReactSVG container divs
  svg {
    max-height: 360px;
  }
`;
export const FlexRowSVGContainer = styled.div`
  height: 300px;
  margin: 0 ${props => props.theme.spacing.horizontal.xl} 0 auto;
  text-align: right;

  // for ReactSVG container divs
  div,
  svg {
    height: 100%;
  }
`;
export const ModuleActionContainer = styled.div`
  display: inline-block;

  a {
    display: block;
  }
  & + & {
    margin-left: ${props => props.theme.spacing.horizontal.xl};
  }
`;
export const ModuleActionsProgressContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing.vertical.lg};
  text-align: center;
`;
export const SelectionButtonContainer = styled.div`
  ${flexRowSpaceBetweenCenter};
  padding-top: ${props => props.theme.spacing.vertical.sm2};
  padding-bottom: ${props => props.theme.spacing.vertical.sm2};

  a {
    flex: 1;
  }
`;
export const ButtonGroupContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: ${props => props.theme.spacing.vertical.xl};
`;
export const ProgressContainer = styled.div`
  display: inline-block;
  width: 310px;
`;
export const NumContainer = styled.div`
  display: inline-block;
  margin-left: 48px;
`;
export const Flex = styled.div`
  flex: ${props => (props.flex ? props.flex : 1)};
  padding-top: ${props =>
    props.paddingTop && props.theme.spacing.vertical[props.paddingTop]};
  padding-right: ${props =>
    props.paddingRight && props.theme.spacing.horizontal[props.paddingRight]};
  padding-left: ${props =>
    props.paddingLeft && props.theme.spacing.horizontal[props.paddingLeft]};
  align-self: ${props => props.alignSelf && props.alignSelf};
`;
export const Left = styled.div`
  flex: 1;
`;
export const Right = styled.div`
  flex: 1;
`;
export const BreadcrumbsContainer = styled.div`
  a {
    ${transitionXs};
  }
`;

export const InteractionsBlocker = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${props => props.theme.zIndex.four};
  pointer-events: ${props => !props.areInteractionsBlocked && 'none'};
`;

/*
 * ContentBackground
 *
 * The structural complexity exists largely because the Modal itself (the
 * "front part" and its contents) is nested within the ContentBackground div,
 * while the Modal underlay (the semi-transparent black hit-area background)
 * comes later in the DOM (not nested inside ContentBackground). This leads to
 * z-index issues.
 *
 * The "clear" prop exists because the Onboarding page doesn't want the gray
 * background.
 */
const ContentBackgroundWrapper = styled.div`
  position: relative;
  flex: 1;
`;

// "Slide" controls the up/down animation to accommodate HomeHeader
const ContentBackgroundSlide = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(${props => (props.top ? props.top : 0)}px);
  ${transitionXs};
`;
// "BgColor" will fade the color for transitioning e.g. Onboarding -> Discovery
const ContentBackgroundBgColor = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props =>
    props.clear ? 'transparent' : props.theme.colors.grayBg};
  ${transitionXl};
`;
const ContentBackgroundInnerWrapper = styled.div`
  position: relative;
  padding-top: ${props => props.top}px;
`;
export const ContentBackground = ({ children, clear, top }) => (
  <ContentBackgroundWrapper>
    <ContentBackgroundSlide top={top}>
      <ContentBackgroundBgColor clear={clear} />
    </ContentBackgroundSlide>
    <ContentBackgroundInnerWrapper top={top}>
      {children}
    </ContentBackgroundInnerWrapper>
  </ContentBackgroundWrapper>
);
