import { ASSET_PATH } from '../constants/general.js';

export const DISCOVERY_SLIDES_CONTENT = {
  financial: [
    {
      groupName: 'intro',
      copy:
        'Let’s start with your financial accounts. They help you manage your money, payments, and investments.',
      introSvg: `${ASSET_PATH}/svg/financial-intro.svg`,
    },
    {
      groupName: 'banks',
      title: 'Banks',
      copy:
        'Your checking account, savings account, credit card, mortgage, car loan—any institution with your money.',
    },
    {
      groupName: 'payments',
      title: 'Payments',
      copy:
        'Companies you use to send money to your friend for brunch or collect money from customers.',
    },
    {
      groupName: 'investments',
      title: 'Investments',
      copy: 'Institutions that help you manage your investments.',
    },
    {
      groupName: 'other',
      title: 'Other Financial Accounts',
      copy:
        "Is there something we haven't covered? Maybe you have accounts for budgeting or tax preparation.",
    },
    {
      groupName: 'summary',
      copy:
        'Great! Here are the accounts we will add to your digital footprint:',
    },
    {
      groupName: 'conclusion',
      copy:
        'Nice! You’ve captured some of the most important accounts in your digital footprint.',
    },
  ],
  productivity: [
    {
      groupName: 'intro',
      copy:
        'Some accounts help you store files, communicate with others, and generally organize your life.',
      introSvg: `${ASSET_PATH}/svg/productivity-intro.svg`,
    },
    {
      groupName: 'email',
      title: 'Email',
      copy:
        'Some of the most important correspondence we do is by email these days. And email is often the gateway to the rest of your accounts.',
    },
    {
      groupName: 'cloud-services',
      title: 'Cloud Services',
      copy:
        'Think of these as the organizational structure to your digital existence. They help you organize your files, your data, even your thoughts.',
    },
    {
      groupName: 'smart-home',
      title: 'Smart Home',
      copy:
        'It’s a brave new world for the internet of things. Accounts that have been helping you in other arenas are now available for the home as well.',
    },
    {
      groupName: 'other',
      title: 'Other',
      copy:
        'There are plenty of other organizational tools out there that help you in your work and your personal life.',
    },
    {
      groupName: 'summary',
      copy:
        'Great! Here are the accounts we will add to your digital footprint:',
    },
    {
      groupName: 'conclusion',
      copy:
        'Nice! You’ve captured some of the most important accounts in your digital footprint.',
    },
  ],
  social: [
    {
      groupName: 'intro',
      copy:
        'Some accounts help you store files, communicate with others, and generally organize your life.',
      introSvg: `${ASSET_PATH}/svg/social-intro.svg`,
    },
    {
      groupName: 'social-networking',
      title: 'Social Networking',
      copy:
        'Accounts that help you keep up with friends, family, and even colleagues.',
    },
    {
      groupName: 'media-sharing',
      title: 'Media Sharing',
      copy:
        'Accounts with a focus on sharing and consuming photos, videos and music.',
    },
    {
      groupName: 'microblogging',
      title: 'Microblogging',
      copy:
        'Whether it’s a brief diatribe or a lengthy dissertation, these are the accounts that help you document and circulate your thoughts.',
    },
    {
      groupName: 'other',
      title: 'Other',
      copy:
        'There are lots of other ways to connect in this day and age, from crowdsourcing to group messaging. Enter all those other accounts here.',
    },
    {
      groupName: 'summary',
      copy:
        'Great! Here are the accounts we will add to your digital footprint:',
    },
    {
      groupName: 'conclusion',
      copy:
        'Nice! You’ve captured some of the most important accounts in your digital footprint.',
    },
  ],
  health: [
    {
      groupName: 'intro',
      copy:
        'Get help tracking the accounts that keep track of your health, fitness and medical records.',
      introSvg: `${ASSET_PATH}/svg/health-intro.svg`,
    },
    {
      groupName: 'fitness',
      title: 'Fitness',
      copy:
        'It may seem small, but the accounts that track your fitness goals know a lot about you!',
    },
    {
      groupName: 'medical',
      title: 'Medical',
      copy:
        'There are many accounts out there that help you track your own symptoms, learn what to look for, and even document your DNA makeup.',
    },
    {
      groupName: 'insurance',
      title: 'Insurance',
      copy:
        'The companies that help you get access to medical care also have access to a lot of your medical history.',
    },
    {
      groupName: 'other',
      title: 'Other',
      copy:
        'Health can also be about quality of life. Maybe you have accounts that help you maintain your mental health? Or even an app that reminds you to drink water?',
    },
    {
      groupName: 'summary',
      copy:
        'Great! Here are the accounts we will add to your digital footprint:',
    },
    {
      groupName: 'conclusion',
      copy:
        'Nice! You’ve captured some of the most important accounts in your digital footprint.',
    },
  ],
  shopping: [
    {
      groupName: 'intro',
      copy:
        'The companies that help you buy things wouldn’t be able to do so without a little help from your financial data.',
      introSvg: `${ASSET_PATH}/svg/shopping-intro.svg`,
    },
    {
      groupName: 'online-marketplaces',
      title: 'Online Marketplaces',
      copy:
        'Some sites allow you to shop for many brands or even kinds of products without being a brand unto itself.',
    },
    {
      groupName: 'traditional-brands',
      title: 'Traditional Brands',
      copy:
        'Other times you buy directly from a more traditional brand, whether it’s at their website or in a brick and mortar location.',
    },
    {
      groupName: 'online-retailers',
      title: 'Online Retailers',
      copy:
        'These days there are plenty of brands you never even encounter in a store, in fact their business models are dependent on you creating an online account.',
    },
    {
      groupName: 'other',
      title: 'Other',
      copy:
        'Shopping doesn’t have to be limited to traditional products, these days we shop for experiences, meals, even places to live with online accounts.',
    },
    {
      groupName: 'summary',
      copy:
        'Great! Here are the accounts we will add to your digital footprint:',
    },
    {
      groupName: 'conclusion',
      copy:
        'Nice! You’ve captured some of the most important accounts in your digital footprint.',
    },
  ],
  other: [
    {
      groupName: 'intro',
      copy:
        'You may have some accounts that don’t fit into any of the other categories, and there’s definitely a couple that have accounts on you whether or not you realized.',
      introSvg: `${ASSET_PATH}/svg/other-intro.svg`,
    },
    {
      groupName: 'other',
      title: 'Other',
      copy:
        'Did you think of some accounts that are important to you that don’t fit in other categories? Fill them in here, and we’ll do the same if we come across any.',
    },
    {
      groupName: 'summary',
      copy:
        'Great! Here are the accounts we will add to your digital footprint:',
    },
    {
      groupName: 'conclusion',
      copy:
        'Nice! You’ve captured some of the most important accounts in your digital footprint.',
    },
  ],
};
