import React, { Component } from 'react';
import { connect } from 'react-redux';

import { clearData, updateUser } from '../../actions';

import { ContentWidth, Paper, PaperContentWidth } from '../styled/containers';
import { Section } from '../styled/dev';
import { H1, H2 } from '../styled/type';

import { HIDE_CONFIRMATIONS } from '../../constants/general.js';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formName: '',
      formEmail: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitUser = this.submitUser.bind(this);
  }
  componentDidMount() {
    this.setState({
      formName: this.props.name,
      formEmail: this.props.email,
    });
  }
  handleInputChange({ target: { name, value } }) {
    this.setState({
      [name]: value,
    });
  }
  logout = () => {
    if (HIDE_CONFIRMATIONS || window.confirm('Confirm logout?')) {
      this.props.clearData();
      this.setState({
        formName: '',
        formEmail: '',
      });
    }
  };
  submitUser = () => {
    const {
      props: { updateUser },
      state: { formName, formEmail },
    } = this;
    updateUser(formName, formEmail);
  };
  render() {
    const isSignedIn = !!this.props.name;
    return (
      <ContentWidth wide paddingTop="xl">
        <Paper>
          <PaperContentWidth wide paddingTop="xl">
            <H1>Login</H1>

            {isSignedIn ? (
              <Section>
                <H2>Signed in</H2>
                <ul>
                  <li>Name: {this.props.name}</li>
                  <li>Email: {this.props.email}</li>
                </ul>
                <button onClick={() => this.logout()} topMargin>
                  logout
                </button>
              </Section>
            ) : (
              <Section>
                <form>
                  <fieldset>
                    <label>Name:</label>
                    <input
                      name="formName"
                      type="text"
                      value={this.state.formName}
                      onChange={this.handleInputChange}
                    />
                  </fieldset>
                  <fieldset>
                    <label>Email:</label>
                    <input
                      name="formEmail"
                      type="text"
                      value={this.state.formEmail}
                      onChange={this.handleInputChange}
                    />
                  </fieldset>
                </form>
                <button onClick={() => this.submitUser()} large topMargin="3em">
                  login
                </button>
              </Section>
            )}
          </PaperContentWidth>
        </Paper>
      </ContentWidth>
    );
  }
}

const mapStateToProps = ({ user: { name, email } }) => ({
  name,
  email,
});
const mapDispatchToProps = {
  clearData,
  updateUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
