import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import WithUi from './hoc/WithUi';

import { InitializeAnimationFade } from './styled/animation';
import { ModalContainer, ModalInner } from './styled/modal';

class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollTop: 0,
    };
  }
  componentDidUpdate({ ui: { isModalVisible: prevIsModalVisible } }) {
    const {
      ui: { isModalVisible: nextIsModalVisible },
    } = this.props;

    if (!prevIsModalVisible && nextIsModalVisible) {
      const scrollTop = document.documentElement.scrollTop;
      this.setState({
        scrollTop,
      });
    }
  }
  nextSlide() {
    this.setState({
      activeSlide: this.state.activeSlide + 1,
    });
  }
  render() {
    const {
      props: {
        children,
        ui: { isModalVisible },
      },
      state: { scrollTop },
    } = this;

    return isModalVisible ? (
      <ModalContainer scrollTop={scrollTop}>
        <InitializeAnimationFade isIn={isModalVisible}>
          <ModalInner>{children}</ModalInner>
        </InitializeAnimationFade>
      </ModalContainer>
    ) : (
      <div />
    );
  }
}

const propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
};
WithUi.propTypes = propTypes;

export default compose(WithUi)(Modal);
