export const PROFILES = [
  {
    user: {
      name: 'Nico',
      email: 'nico@protonmail.com',
    },
    accounts: [
      'Chase Bank',
      'fidelity',
      'fifth third bank',
      'great lakes borrower service',
      'john hancock retirement',
      'learnvest',
      'mint',
      'paypal',
      'square',
      'us bank',
      'adobe',
      'dropbox',
      'evernote',
      'github',
      'godaddy',
      'gmail',
      'quip',
      'slack',
      'unroll me',
      'wetransfer',
      'wordpress',
      'zenefits',
      'behance',
      'facebook',
      'instagram',
      'kickstarter',
      'linkedin',
      'myspace',
      'pinterest',
      'quora',
      'tumblr',
      '23andme',
      'kaiser permanente',
      'amour vert',
      'apple',
      'best buy',
      'bloomthat',
      'crate and barrel',
      'everlane',
      'filson',
      'gabriella ny bridal salon',
      'grovemade',
      'hardgraft',
      'heath ceramics',
      'kaufmann mercantile',
      'kiehls',
      'loho bride',
      'lunya',
      'machine era co',
      'madewell',
      'joss and main',
      'orchard mile',
      'pottery barn',
      'recliner',
      'reformation',
      'rei',
      'rent the runway',
      'spring',
      'stitch fix',
      'sur la table',
      'the citizenry',
      'west elm',
      'zola',
    ],
  },
  {
    user: {
      name: 'Li',
      email: 'li@hotmail.com',
    },
    accounts: [
      'Chase Bank',
      'us bank',
      'fifth third bank',
      'paypal',
      'square',
      'john hancock',
      'fidelity',
      'vanguard',
      'mint',
      'great lakes borrower service',
      'gmail',
      'transunion',
      'experian',
      'google drive',
      'evernote',
      'quip',
      'dropbox',
      'slack',
      'adobe',
      'github',
      'godaddy',
      'wordpress',
      'google home',
      'twitter',
      'facebook',
      'alexa',
      'pinterest',
      'fitbit',
      'peloton',
      'kaiser',
    ],
  },
  {
    user: {
      name: 'Sophie',
      email: 'sophie@gmail.com',
    },
    accounts: [
      'Chase Bank',
      'us bank',
      'fifth third bank',
      'paypal',
      'square',
      'john hancock',
      'fidelity',
      'mint',
      'experian',
      'gmail',
      'google drive',
      'evernote',
      'quip',
      'dropbox',
      'slack',
      'adobe',
      'github',
      'godaddy',
      'wordpress',
    ],
  },
  {
    user: {
      name: 'Arwen',
      email: 'arwen@hotmail.com',
    },
    accounts: [
      'Chase Bank',
      'us bank',
      'fifth third bank',
      'paypal',
      'gmail',
      'evernote',
      'dropbox',
      'slack',
      'github',
    ],
  },
  {
    user: {
      name: 'Desean',
      email: 'ds@example.com',
    },
    accounts: ['Goldman Sachs', 'merrill lynch', 'gmail', 'pinterest'],
  },
];
