import React, { Component } from 'react';

import {
  findActionByModuleSlugAndActionSlug,
  findModuleBySlug,
  findTaskByModuleActionTaskSlugs,
} from '../../utilities';

import { MASTER_MODULE_LIST } from '../../data/masterModuleList.js';

const WithAction = WrappedComponent => {
  return class extends Component {
    render() {
      const {
        actionSlug = '',
        moduleSlug = '',
        taskSlug = '',
      } = this.props.match.params;
      const module = findModuleBySlug(MASTER_MODULE_LIST, moduleSlug);
      const action =
        findActionByModuleSlugAndActionSlug(
          MASTER_MODULE_LIST,
          moduleSlug,
          actionSlug
        ) || {};
      const task =
        findTaskByModuleActionTaskSlugs(
          MASTER_MODULE_LIST,
          moduleSlug,
          actionSlug,
          taskSlug
        ) || {};
      const { steps = {} } = task;
      return (
        <WrappedComponent
          action={action}
          module={module}
          steps={steps}
          task={task}
          {...this.props}
        />
      );
    }
  };
};

export default WithAction;
