import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactSVG from 'react-svg';

import WithAccounts from '../../hoc/WithAccounts';

import {
  DiscoveryIconSVGContainer,
  RightArrowButtonAlt,
  StyledInput,
} from '../../styled/discovery';
import { SelectionButtonContainer } from '../../styled/containers';

class AddAccountForm extends Component {
  constructor(props) {
    super(props);

    // "form" prefix distinguishes local form control from redux
    this.state = {
      formAccountName: '',
      formGroup: this.props.groupName || '',
    };
    this.boundHandleInputChange = this.handleInputChange.bind(this);
    this.boundFormAddAccount = this.formAddAccount.bind(this);

    this.nameInput = React.createRef();
    this.form = React.createRef();
  }
  keypressHandler = e => {
    if (!this.nameInput) return;
    const formIsFocused = this.nameInput.id === document.activeElement.id;

    if (e.key === 'Enter' && formIsFocused) {
      e.preventDefault();
      this.formAddAccount();
    }
  };
  componentDidMount() {
    window.addEventListener('keypress', this.keypressHandler.bind(this));
    this.form.current.addEventListener('submit', e => {
      // prevent page refresh
      e.preventDefault();
    });
  }
  componentWillUnmount() {
    window.removeEventListener('keypress', this.keypressHandler.bind(this));
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.groupName !== this.props.groupName) {
      this.setState({
        formGroup: this.props.groupName,
      });
    }
  }
  handleInputChange({ target: { name, value } }) {
    this.setState({
      [name]: value,
    });
  }
  formAddAccount = () => {
    const {
      props: { addAccount, categoryParam },
      state: { formAccountName, formGroup },
    } = this;

    if (this.state.formAccountName !== '') {
      addAccount(formAccountName, formGroup, categoryParam);
      this.setState({
        formAccountName: '',
      });
    }
  };
  render() {
    const { icon } = this.props;
    return (
      <form ref={this.form}>
        <SelectionButtonContainer>
          <DiscoveryIconSVGContainer>
            <ReactSVG src={icon} />
          </DiscoveryIconSVGContainer>
          <StyledInput
            name="formAccountName"
            id="formAccountName"
            type="text"
            value={this.state.formAccountName}
            onChange={this.boundHandleInputChange}
            ref={ref => (this.nameInput = ref)}
          />
          <RightArrowButtonAlt
            onClick={() => this.boundFormAddAccount()}
            id="submitGroup"
          />
        </SelectionButtonContainer>
      </form>
    );
  }
}

const propTypes = {
  addAccount: PropTypes.func.isRequired,
  categoryParam: PropTypes.string.isRequired,
  groupName: PropTypes.string,
  icon: PropTypes.string.isRequired,
};
AddAccountForm.propTypes = propTypes;

export default WithAccounts(AddAccountForm);
