import React from 'react';
import { compose } from 'redux';

import WithAnimation from './hoc/WithAnimation';

import { AnimationFade } from './styled/animation';

export default compose(WithAnimation)(props => (
  <AnimationFade isIn={props.animation.isRouteIn}>
    {props.children}
  </AnimationFade>
));
