import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import {
  findAccountsByGroups,
  filterModulesActionsTasksByAccounts,
  getAllTaskGroupsFromAction,
} from '../utilities';

import AnimationFadeRoute from './AnimationFadeRoute';
import ForceDirectedGraph from './visualizations/ForceDirectedGraph';
import GetStarted from './home/GetStarted';
import HomeAccountAccordions from './home/HomeAccountAccordions';
import HomeModal from './home/HomeModal';
import HomeModuleAccordions from './home/HomeModuleAccordions';
import Modal from './Modal';
import Spiral from './visualizations/Spiral';
import WithAccounts from './hoc/WithAccounts';
import WithMasterModuleList from './hoc/WithMasterModuleList';
import WithUi from './hoc/WithUi';
import WithUser from './hoc/WithUser';

import { ContentWidth, FlexRow } from './styled/containers';
import { HomeCenter, HomeLeft, HomeRight } from './styled/home';
import { H2 } from './styled/type';
import {
  InitializeAnimationFadeDelay1,
  InitializeAnimationFadeDelay2,
  InitializeAnimationFadeDelay3,
  InitializeAnimationFadeDelay4,
} from './styled/animation';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      overlay: {
        accounts: [],
        isActive: false,
      },
    };

    this.boundCalculateOverlay = this.calculateOverlay.bind(this);
    this.boundLaunchModal = this.launchModal.bind(this);
  }
  componentDidMount() {
    const {
      accounts,
      location: { search },
      maybeLoadProfile,
      profiles,
    } = this.props;

    const noRedirect = search.includes('no-redirect');
    const attemptRedirect = accounts.length === 0;

    // instead of going to onboarding, load Nico profile
    /* attemptRedirect && !noRedirect && this.props.history.push('/onboarding'); */
    attemptRedirect && !noRedirect && maybeLoadProfile(profiles[0]);
  }
  calculateOverlay(isEnabled, account = '', action) {
    /*
     * Don't update the overlay if the modal is open or interactions
     * are happening (because page transitions/animations).
     */
    if (this.props.ui.isModalVisible || this.props.ui.areInteractionsBlocked) {
      return;
    }

    const { accounts, masterModuleList } = this.props;
    const actionRollupGroups = getAllTaskGroupsFromAction(action);
    const activeAccounts = (action &&
      findAccountsByGroups(accounts, actionRollupGroups)) || [account];

    // TODO see if this logic can completely move to HomeModal
    const activeModulesActionsTasks = action
      ? [action]
      : activeAccounts &&
        filterModulesActionsTasksByAccounts(
          masterModuleList,
          activeAccounts.length > 0 ? activeAccounts : accounts
        );

    this.setState({
      overlay: {
        activeAccounts,
        activeModulesActionsTasks,
        isEnabled,
      },
    });
  }
  launchModal(node) {
    this.props.showModal();
  }
  render() {
    const {
      boundLaunchModal,
      props: {
        accounts,
        ui: { showAlternateVisualization },
        masterModuleList,
      },
      state: { overlay },
    } = this;

    return (
      <AnimationFadeRoute>
        <ContentWidth wide paddingTop="xl">
          <FlexRow>
            <HomeLeft>
              <InitializeAnimationFadeDelay1>
                <GetStarted
                  accounts={accounts}
                  masterModuleList={masterModuleList}
                />
              </InitializeAnimationFadeDelay1>

              <InitializeAnimationFadeDelay2>
                <H2>Take Action</H2>
                <HomeModuleAccordions
                  key={module.name}
                  calculateOverlay={this.boundCalculateOverlay}
                  masterModuleList={masterModuleList}
                />
              </InitializeAnimationFadeDelay2>
            </HomeLeft>

            {accounts.length > 0 && (
              <HomeCenter>
                <InitializeAnimationFadeDelay4>
                  {showAlternateVisualization ? (
                    <ForceDirectedGraph
                      calculateOverlay={this.boundCalculateOverlay}
                      dataset={accounts}
                      launchModal={boundLaunchModal}
                      overlay={overlay}
                    />
                  ) : (
                    <Spiral
                      calculateOverlay={this.boundCalculateOverlay}
                      dataset={accounts}
                      launchModal={boundLaunchModal}
                      overlay={overlay}
                    />
                  )}
                </InitializeAnimationFadeDelay4>
              </HomeCenter>
            )}

            <HomeRight>
              <InitializeAnimationFadeDelay3>
                <H2>My Accounts</H2>
                {accounts.length > 0 ? (
                  <HomeAccountAccordions
                    accounts={accounts}
                    calculateOverlay={this.boundCalculateOverlay}
                    launchModal={boundLaunchModal}
                    overlay={overlay}
                  />
                ) : (
                  <p>no accounts</p>
                )}
              </InitializeAnimationFadeDelay3>
            </HomeRight>
          </FlexRow>
          <Modal>
            {this.props.ui.isModalVisible && (
              <HomeModal
                masterModuleList={masterModuleList}
                overlay={overlay}
              />
            )}
          </Modal>
        </ContentWidth>
      </AnimationFadeRoute>
    );
  }
}

const propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      groups: PropTypes.array,
      tasks: PropTypes.array,
    })
  ),
  ui: PropTypes.shape({
    showAlternateVisualization: PropTypes.bool,
  }),
};
Home.propTypes = propTypes;

export default compose(
  WithAccounts,
  WithMasterModuleList,
  WithUi,
  WithUser
)(Home);
