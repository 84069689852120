import union from 'lodash/union';
import uuid from 'uuid';

import {
  findAccountInAccountsByName,
  mergeInputAccountWithLookupAccount,
  slugify,
} from '../utilities';

import * as types from '../constants/actions';
import { HIDE_CONFIRMATIONS } from '../constants/general.js';

// ui
export const toggleVisualization = () => ({
  type: types.TOGGLE_VISUALIZATION,
});
export const showModal = () => ({
  type: types.SET_MODAL_VISIBILITY,
  isModalVisible: true,
});
export const hideModal = () => ({
  type: types.SET_MODAL_VISIBILITY,
  isModalVisible: false,
});
export const toggleModal = () => (dispatch, getState) => {
  const { isModalVisible } = getState();
  dispatch({
    type: types.SET_MODAL_VISIBILITY,
    isModalVisible: !isModalVisible,
  });
};
export const setHomeHeaderHeight = homeHeaderHeight => ({
  type: types.SET_HOME_HEADER_HEIGHT,
  homeHeaderHeight,
});
export const setAreInteractionsBlocked = areInteractionsBlocked => ({
  type: types.SET_ARE_INTERACTIONS_BLOCKED,
  areInteractionsBlocked,
});

// animation
export const setIsInitialLoadIn = () => ({
  type: types.SET_IS_INITIAL_LOAD_IN,
  isInitialLoadIn: true,
});
export const setIsPaperIn = (isPaperIn = true) => dispatch => {
  // when the paper is "out," we want to prevent user from clicking on other links
  dispatch(setAreInteractionsBlocked(!isPaperIn));

  dispatch({
    type: types.SET_IS_PAPER_IN,
    isPaperIn: isPaperIn,
  });
};
export const setIsRouteIn = (isRouteIn = true) => dispatch => {
  dispatch(setAreInteractionsBlocked(!isRouteIn));

  dispatch({
    type: types.SET_IS_ROUTE_IN,
    isRouteIn: isRouteIn,
  });
};
export const setIsSlideIn = (isSlideIn = true) => dispatch => {
  dispatch(setAreInteractionsBlocked(!isSlideIn));

  dispatch({
    type: types.SET_IS_SLIDE_IN,
    isSlideIn: isSlideIn,
  });
};
export const setAnimationsIn = () => dispatch => {
  dispatch(setIsRouteIn());
  dispatch(setIsPaperIn());
  dispatch(setIsSlideIn());
};

// user
export const updateUser = (name, email) => ({
  type: types.UPDATE_USER,
  name,
  email,
});

// progress
export const addProgressEntry = ({
  moduleSlug,
  actionSlug,
  taskSlug,
  accountName,
  isStarted,
  isCompleted,
  meta,
}) => {
  return {
    type: types.ADD_PROGRESS,
    newProgress: {
      moduleSlug,
      actionSlug,
      taskSlug,
      accountName,
      isStarted,
      isCompleted,
      meta,
    },
  };
};
const deleteAllProgress = id => ({
  type: types.DELETE_ALL_PROGRESS,
  id,
});

// account
export const addAccount = (name, group = '', category = '') => (
  dispatch,
  getState
) => {
  const inputAccount = {
    name,
    // convert group:string → groups:array
    groups: group.split(',').map(slugify),
  };

  // "overwrite" or take properties from the lookup
  const newAccount = mergeInputAccountWithLookupAccount(inputAccount.name);

  // disallow duplicate account creation
  const { accounts: existingUserAccounts } = getState();
  const accountAlreadyExists = !!findAccountInAccountsByName(
    newAccount.name,
    existingUserAccounts
  );
  if (accountAlreadyExists) {
    return;
  }

  // include user-inputted group(s) and filter empties
  newAccount.groups = union(inputAccount.groups, newAccount.groups, [
    category,
  ]).filter(group => group !== '');

  newAccount.id = uuid();

  // overwrite lookup category if category is provided via Discovery
  if (!!category) {
    newAccount.category = category;
  }

  dispatch({
    type: types.ADD_ACCOUNT,
    newAccount,
  });
};
export const deleteAccountById = id => ({
  type: types.DELETE_ACCOUNT_BY_ID,
  id,
});
export const deleteAccountByName = name => ({
  type: types.DELETE_ACCOUNT_BY_NAME,
  name,
});
const deleteAllAccounts = id => ({
  type: types.DELETE_ALL_ACCOUNTS,
  id,
});
export const addAccounts = accounts => dispatch => {
  accounts.forEach(account => {
    dispatch(addAccount(account));
  });
};

// dev
export const loadProfile = profile => dispatch => {
  dispatch(updateUser(profile.user.name, profile.user.email));
  dispatch(deleteAllAccounts());

  profile.accounts.forEach(account => {
    dispatch(addAccount(account));
  });
};

export const clearData = () => dispatch => {
  if (
    HIDE_CONFIRMATIONS ||
    window.confirm('Confirm: clear user and account data?')
  ) {
    dispatch(updateUser('', ''));
    dispatch(deleteAllAccounts());
    dispatch(deleteAllProgress());
  }
};
