import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactSVG from 'react-svg';

import AnimationLinkRoute from './AnimationLinkRoute';
import { Arrow, AccordionIconSVGContainer } from './styled/accordion';
import { NumOfNum } from './styled/type';
import { PlusAddButton } from './styled/buttons';

import {
  AHCenter,
  AHLeft,
  AHRight,
  AccordionBody,
  AccordionBodyContainer,
  AccordionContainer,
  AccordionHead,
  AccordionHeading,
  ProgressIndicator,
} from './styled/accordion';
import { H3 } from './styled/type';

class Accordion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCollapsed: true,
      bodyContainerHeight: 0,
    };
  }
  componentDidMount() {
    if (this.props.startExpanded) {
      this.setState({
        isCollapsed: false,
        bodyContainerHeight: this.bodyRef.clientHeight,
      });
    }
  }
  toggleCollapsed() {
    this.setState({
      isCollapsed: !this.state.isCollapsed,
      bodyContainerHeight: this.state.isCollapsed
        ? this.bodyRef.clientHeight
        : 0,
    });
  }
  render() {
    const {
      props: {
        addLink,
        body,
        icon,
        heading,
        headingLink,
        progress: { numberCompleted, total },
      },
      state: { isCollapsed },
    } = this;
    const capitalizedHeading = heading[0].toUpperCase() + heading.substr(1);

    // 'AH' means 'AccordionHead'
    return (
      <AccordionContainer>
        <AccordionHead>
          <AHLeft onClick={() => this.toggleCollapsed()}>
            {icon ? (
              <AccordionIconSVGContainer>
                <ReactSVG src={icon} />
              </AccordionIconSVGContainer>
            ) : (
              <Arrow
                isPointingDown={isCollapsed}
                onClick={() => this.toggleCollapsed()}
              />
            )}
          </AHLeft>
          <AHCenter>
            <AccordionHeading>
              {headingLink ? (
                <H3>
                  <AnimationLinkRoute to={headingLink}>
                    {capitalizedHeading}
                  </AnimationLinkRoute>
                </H3>
              ) : (
                <H3>
                  <span>{capitalizedHeading}</span>
                </H3>
              )}
            </AccordionHeading>
            {!!total && (
              <ProgressIndicator
                numberCompleted={numberCompleted}
                total={total}
              />
            )}
          </AHCenter>
          <AHRight>
            {!!total && (
              <NumOfNum small>
                <span>{numberCompleted}</span> of <span>{total}</span>
              </NumOfNum>
            )}
            {addLink && (
              <AnimationLinkRoute to={addLink}>
                <PlusAddButton />
              </AnimationLinkRoute>
            )}
          </AHRight>
        </AccordionHead>
        <AccordionBodyContainer
          style={{ height: this.state.bodyContainerHeight }}>
          <AccordionBody ref={ref => (this.bodyRef = ref)}>
            {body}
          </AccordionBody>
        </AccordionBodyContainer>
      </AccordionContainer>
    );
  }
}

Accordion.defaultProps = {
  progress: {
    numberCompleted: 0,
    total: 0,
  },
};
const propTypes = {
  addLink: PropTypes.string,
  body: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  heading: PropTypes.string.isRequired,
  headingLink: PropTypes.string,
  progress: PropTypes.shape({
    numberCompleted: PropTypes.number,
    total: PropTypes.number,
  }),
};
Accordion.propTypes = propTypes;

export default Accordion;
