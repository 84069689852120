import React, { Component } from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import merge from 'lodash/merge';

import { sanitize } from '../../../utilities/';

import { SmallText } from '../../styled/type';
import { Checkbox, Table, Td, Th } from '../../styled/containers';

class TaskTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkboxValues: {},
    };

    this.boundHandleInputChange = this.handleInputChange.bind(this);
  }
  handleInputChange(e, accountName, columnName) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const checkboxUpdate = {
      [columnName]: {
        [accountName]: value,
      },
    };
    this.setState({
      checkboxValues: merge(this.state.checkboxValues, checkboxUpdate),
    });
  }
  render() {
    const { accountGroup, accounts, checkboxColumns } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            <Th>
              <SmallText fontWeight="bold" color="gray7">
                {accountGroup && `${accountGroup} `}
                account
              </SmallText>
            </Th>
            {checkboxColumns.map(column => (
              <Th key={sanitize(column)}>
                <SmallText fontWeight="bold" color="gray7">
                  {column}
                </SmallText>
              </Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {accounts.map(({ id, name: accountName, groups = [] }) => (
            <tr key={id}>
              <Td>{accountName}</Td>
              {checkboxColumns.map(column => {
                const columnName = sanitize(column);
                const isChecked = get(this.state.checkboxValues, [
                  columnName,
                  accountName,
                ]);
                return (
                  <Td key={columnName}>
                    <Checkbox
                      value={isChecked}
                      onChange={e =>
                        this.boundHandleInputChange(e, accountName, columnName)
                      }
                    />
                  </Td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

const propTypes = {
  accountGroup: PropTypes.string,
  accounts: PropTypes.array.isRequired,
  checkboxColumns: PropTypes.array,
};
TaskTable.propTypes = propTypes;

export default TaskTable;
