import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import WithAnimation from './hoc/WithAnimation';

class RouteChangeInterceptor extends Component {
  componentDidUpdate(prevProps, prevState) {
    const {
      location: { pathname: oldPath, search: oldSearch },
    } = prevProps;
    const {
      location: { pathname: newPath, search: newSearch },
    } = this.props;
    if (newPath !== oldPath || newSearch !== oldSearch) {
      // setTimeout is necessary to render a "faded out" state before activation
      setTimeout(() => {
        this.props.setAnimationsIn();
      }, 0);
    }
  }
  render() {
    const { children } = this.props;
    return <Fragment>{children}</Fragment>;
  }
}

const propTypes = {
  setAnimationsIn: PropTypes.func.isRequired,
};
RouteChangeInterceptor.propTypes = propTypes;

export default compose(
  withRouter,
  WithAnimation
)(RouteChangeInterceptor);
