import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteAccountByName } from '../../../actions';

import keys from 'lodash/keys';
import fp from 'lodash/fp';
import merge from 'lodash/merge';

import { SmallText } from '../../styled/type';
import { Checkbox, Table, Td, Th, Tr } from '../../styled/containers';

class AccountCheckboxTable extends Component {
  constructor(props) {
    super(props);

    // start with all checkboxes checked
    this.state = {
      checkboxValues: Object.assign(
        {},
        ...this.props.filteredAccounts.map(account => ({
          [account.name]: true,
        }))
      ),
    };

    this.boundHandleInputChange = this.handleInputChange.bind(this);
  }
  componentWillUnmount() {
    const { checkboxValues } = this.state;

    const rejectedAccounts = fp.compose(
      keys,
      fp.pickBy(value => !value)
    )(checkboxValues);

    rejectedAccounts &&
      rejectedAccounts.forEach(account =>
        this.props.deleteAccountByName(account)
      );
  }
  handleInputChange(
    {
      target: { checked },
    },
    accountName
  ) {
    const checkboxUpdate = {
      [accountName]: checked,
    };
    this.setState({
      checkboxValues: merge(this.state.checkboxValues, checkboxUpdate),
    });
  }
  render() {
    const { accountGroup, filteredAccounts } = this.props;
    const accountsExist = !!filteredAccounts.length;
    return (
      <Table marginSides="sm">
        <thead>
          <tr>
            <Th>
              <SmallText fontWeight="bold" color="gray7">
                {accountGroup}
              </SmallText>
            </Th>
            {accountsExist && (
              <Th>
                <SmallText fontWeight="bold" color="gray7">
                  Add
                </SmallText>
              </Th>
            )}
          </tr>
        </thead>
        <tbody>
          {accountsExist ? (
            filteredAccounts.map(({ id, name: accountName }) => {
              const isChecked = this.state.checkboxValues[accountName];
              return (
                <Tr key={id}>
                  <Td>{accountName}</Td>
                  <Td>
                    <Checkbox
                      defaultChecked={isChecked}
                      value={isChecked}
                      onChange={e =>
                        this.boundHandleInputChange(e, accountName)
                      }
                    />
                  </Td>
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td>
                <em>no accounts</em>
              </Td>
            </Tr>
          )}
        </tbody>
      </Table>
    );
  }
}

const propTypes = {
  accountGroup: PropTypes.string.isRequired,
  deleteAccountByName: PropTypes.func.isRequired,
  filteredAccounts: PropTypes.array.isRequired,
};
AccountCheckboxTable.propTypes = propTypes;

export default connect(
  null,
  { deleteAccountByName }
)(AccountCheckboxTable);
