import { UPDATE_USER } from '../constants/actions';

const initialState = {
  name: '',
  email: '',
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER:
      return {
        name: action.name,
        email: action.email,
      };

    default:
      return state;
  }
}
