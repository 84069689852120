import { ASSET_PATH } from '../constants/general.js';

export const anonymizeFinancialAccountsTasks = [
  {
    name: 'Anonymize your username and password',
    introCopy:
      'When you first opened up your online bank account, did you create a username and password once and then never think about them again? Now it’s time to make them airtight.',
    confirmCopy: 'You did it! Your financial accounts appreciate it.',
    icon: `${ASSET_PATH}/svg/secure-finance/05A1-TaskIntro.svg`,
    groups: ['financial'],
    steps: [
      {
        stepType: 'table',
        heading: 'Anonymize your username and passwords',
        copy:
          'Change your username and password for the first financial account in the list. For your username, make sure it doesn’t include any personal information (for example, your name or the street you live on).  Then, change your password for that account.  The best way to make sure you’re creating the strongest password is to use a password manager to completely randomize your passwords. If you’re not using a password manager, learn some techniques for creating strong passwords [link to “make your logins stronger” action].',
        accountGroup: 'financial',
        checkboxColumns: ['password', 'username', 'maybe later'],
      },
    ],
  },
  {
    name: 'Create a secret email',
    introCopy:
      'The best way to use email to keep your sensitive accounts safe is to use an entirely different email address for your financial accounts than you use for your less sensitive accounts. Make sure you create an email address that does not contain any personal information (like your name).',
    confirmCopy: 'You did it! Don’t worry; we won’t tell a soul.',
    icon: `${ASSET_PATH}/svg/secure-finance/05A2-TaskIntro.svg`,
    groups: ['banks', 'investments'],
    steps: [
      {
        stepType: 'link',
        heading: 'Create a new email address for financial accounts',
        copy:
          'First, create your new email address. If you’re creating the address in Gmail, it’s easy to do: just click the top-right icon with your name and photo, and select “Add account” to start. Remember: the email address should not contain any personal information.',
        icon: `${ASSET_PATH}/svg/secure-finance/06A2-TaskStep.svg`,
        actionButton: {
          actionButtonLink: 'https://gmail.com',
          actionButtonText: 'Gmail',
        },
      },
      {
        stepType: 'table',
        heading: 'Update your accounts to use your new email',
        copy:
          'Once you’ve created that new secret email, it’s time to put it to use. Go through all the financial accounts from our list and update your email address to the new one.',
        accountGroup: 'financial',
        checkboxColumns: ['did it!', 'maybe later'],
      },
    ],
  },
];
export const setFinancialAlertsTasks = [
  {
    name: 'Set alerts for your banks',
    introCopy:
      'At minimum, there are a few essential aspects of your financial health that you’ll want to track. For credit cards, you’ll want to be notified whenever you hit your limit or make an unusually large purchase. For you checking or savings account, set up notifications for when there is unusual activity on your account or when your username or password is changed. You can also set alerts for when you withdraw money or use your debit card.',
    confirmCopy: 'You did it! Task completed.',
    groups: ['banks'],
    steps: [
      {
        stepType: 'link-table',
        heading: 'Set up security alerts',
        copy:
          'Log in to your bank’s website or app and go to the Alerts section.\n\nLook out for alerts related to security. For example, set up text alerts for when your username or password has changed, someone has attempted to retrieve your username, or your access has been suspended due to too many login attempts.',
        accountGroup: 'financial',
      },
      {
        stepType: 'table',
        heading: 'Set up transaction alerts',
        copy:
          'Now it’s time to set your transaction alerts. These alerts will notify you whenever you withdraw money from an account, use a debit card, or use your credit card, depending your type of account. Credit card accounts might also have options to alert you whenever charges are made from a gas station or international location - both potential indicators of fraudulent usage.',
        accountGroup: 'financial',
        checkboxColumns: ['done', 'maybe later'],
      },
    ],
  },
];
export const monitorAndFreezeCreditTasks = [
  {
    name: 'Get a free annual credit report',
    introCopy:
      'Each of the three credit bureaus - Experian, Equifax, and TransUnion - will provide you with a free yearly annual credit report through a single simple website, AnnualCreditReport.com. Not only does this credit report give you a good snapshot of your financial health, but also you can use it to identify and fix discrepancies, like fraudulent accounts that were opened in your name. Do this before you freeze your credit to make sure you’re starting with a clean slate.',
    confirmCopy: 'Monitor and protect credit Task completed.',
    groups: ['credit'],
    accountsToAdd: ['transunion', 'equifax', 'experian'],
    icon: `${ASSET_PATH}/svg/protect-credit/credit-monitor-1.svg`,
    steps: [
      {
        stepType: 'link',
        heading: 'Get reports from the "big three"',
        copy:
          'To get your free report from the big three credit agencies, go to http://www.AnnualCreditReport.com and fill out the required information. By law, you are entitled to one free credit report per year.',
        icon: `${ASSET_PATH}/svg/06A2-TaskStep.svg`,
        actionButton: {
          actionButtonLink: 'http://www.AnnualCreditReport.com',
          actionButtonText: 'Annual Credit Report',
        },
      },
    ],
  },
  {
    name: 'Freeze your credit at the “big three” bureaus',
    introCopy:
      'Initiating a credit freeze (also known as a security freeze) restricts your credit file so no one can access it unless you authorize the credit bureaus to allow it. This doesn’t impact your current relationships with banks and other institutions, but new companies who make requests to access your credit will need to get your explicit authorization. This is especially critical if you suspect that some of your information (especially your social security number) has already been breached. \n\n The credit bureaus are required by law to freeze your credit at your request, for free. Many companies also offer to lock your credit. So what’s the difference? For starters, locking your credit is typically done for a fee as part of a membership program. When you lock your credit, it might be easier to unlock on the fly... but it’s also less secure than freezing your credit. \n\n Another option worth considering is credit monitoring. Whereas a credit report is just a snapshot in time, credit monitoring gives you a monthly view of your credit score and lets you keep tabs on credit requests.',
    confirmCopy: 'Task completed.',
    groups: ['credit'],
    accountsToAdd: ['transunion', 'equifax', 'experian'],
    icon: `${ASSET_PATH}/svg/protect-credit/credit-monitor-2.svg`,
    steps: [
      {
        stepType: 'link',
        heading: 'Freeze your credit at Experian',
        copy:
          'To freeze your credit at Experian, create a free account on their website or call 1-888-397-3742. You will need to create a PIN that you will use to unfreeze your credit in the future.',
        icon: `${ASSET_PATH}/svg/06A2-TaskStep.svg`,
        actionButton: {
          actionButtonLink:
            'https://www.experian.com/freeze/center.html#content-01',
          actionButtonText: 'Experian',
        },
      },
      {
        stepType: 'link',
        heading: 'Freeze your credit at Equifax',
        copy:
          'To freeze your credit at Equifax, create a free account on their website or call (800) 685-1111. You will need to create a PIN that you will use to unfreeze your credit in the future.',
        icon: `${ASSET_PATH}/svg/06A2-TaskStep.svg`,
        actionButton: {
          actionButtonLink:
            'https://www.equifax.com/personal/credit-report-services/',
          actionButtonText: 'Equifax',
        },
      },
      {
        stepType: 'link',
        heading: 'Freeze your credit at TransUnion',
        copy:
          'To freeze your credit at TransUnion, create a free account on their website or download their app. You will need to create a PIN that you will use to unfreeze your credit in the future.',
        icon: `${ASSET_PATH}/svg/06A2-TaskStep.svg`,
        actionButton: {
          actionButtonLink: 'https://www.transunion.com/credit-freeze',
          actionButtonText: 'TransUnion',
        },
      },
    ],
  },
];
export const checkKidsCreditTasks = [
  {
    name: 'Scan your child’s info for ID theft at the credit bureaus',
    introCopy:
      'Two of the three credit bureaus will run a scan of your child’s social security number and birthdate to check for ID theft on their website. If there’s any result at all, you’ll know to check further for other evidence of ID theft.',
    confirmCopy: 'Task completed.',
    groups: ['credit'],
    accountsToAdd: ['transunion', 'equifax', 'experian'],
    icon: `${ASSET_PATH}/svg/protect-credit/credit-kids-1.svg`,
    steps: [
      {
        stepType: 'link',
        heading:
          'Check your child’s social security number for ID theft through Experian',
        copy:
          'When you sign up for a free credit report on Experian, you also have the option to scan your children’s social security numbers at the same time. Children under 18 should not have a credit file at all, so if the scan produces a result, it’s a sign of fraudulent activity.',
        icon: `${ASSET_PATH}/svg/06A2-TaskStep.svg`,
        actionButton: {
          actionButtonLink:
            'http://www.experian.com/consumer-products/free-child-identity-theft-scan.html',
          actionButtonText: 'Experian',
        },
      },
      {
        stepType: 'link',
        heading:
          'Check your child’s social security number for ID theft through TransUnion',
        copy:
          'TransUnion allows you to submit a child identity theft inquiry for free through its website. Results will be delivered to your email inbox. When you sign up, make sure you have your kid’s social security number handy - you’ll need it for the scan.',
        icon: `${ASSET_PATH}/svg/06A2-TaskStep.svg`,
        actionButton: {
          actionButtonLink:
            'https://www.transunion.com/credit-disputes/child-identity-theft-inquiry-form',
          actionButtonText: 'TransUnion',
        },
      },
    ],
  },
  {
    name: 'Request a credit report for your kids',
    introCopy:
      'Getting your child’s credit report from all of the big three credit bureaus takes some effort, but is worthwhile if you suspect that your child’s social security number may have been compromised. If your kid is over 13, getting their credit report is a lot easier.',
    confirmCopy: 'Task completed.',
    groups: ['credit'],
    accountsToAdd: ['transunion', 'equifax', 'experian'],
    icon: `${ASSET_PATH}/svg/protect-credit/credit-kids-2.svg`,
    steps: [
      {
        stepType: 'link',
        heading:
          'For kids under 13, download and fill out the paperwork for each of the three credit bureaus to get a credit report',
        copy:
          'You’ll need to assemble some paperwork and send it directly to the credit unions. Instructions can be found here.',
        icon: `${ASSET_PATH}/svg/06A2-TaskStep.svg`,
        actionButton: {
          actionButtonLink:
            'https://help.equifax.com/s/article/If-I-am-not-paying-for-a-product-that-includes-child-monitoring',
          actionButtonText: 'Equifax',
        },
      },
      {
        stepType: 'link',
        heading:
          'For kids 13 and over, get a credit report through AnnualCreditReport.com',
        copy:
          'You can request your teen’s credit report for all three bureaus through AnnualCreditReport.com. As with adults, you’ll need to submit some basic personal information for your child, including social security number.',
        icon: `${ASSET_PATH}/svg/06A2-TaskStep.svg`,
        actionButton: {
          actionButtonLink:
            'https://www.annualcreditreport.com/requestReport/landingPage.action',
          actionButtonText: 'Annual Credit Report',
        },
      },
    ],
  },
  {
    name: 'Freeze your child’s credit',
    introCopy:
      'Freezing your child’s credit requires some effort and comes with risks; freezing a child’s credit requires that the credit bureaus create a credit file for your child first, which could make your child’s identity vulnerable if the bureau’s data is stolen. However, if you’re concerned that your child’s data might be at risk, experts recommend taking the steps to freeze their credit.',
    confirmCopy: 'Task completed.',
    groups: ['credit'],
    accountsToAdd: ['transunion', 'equifax', 'experian'],
    icon: `${ASSET_PATH}/svg/protect-credit/credit-kids-3.svg`,
    steps: [
      {
        stepType: 'link',
        heading: 'Freeze your child’s credit report at Equifax',
        copy:
          'You’ll need to fill out and mail a form to Equifax to create and freeze your child’s credit report, along with documentation of your child’s identity and your authority over her credit. Equifax will give you a PIN that you’ll use to unfreeze the report when needed. You’ll need to keep this PIN until your child becomes an adult, so make sure it’s in a safe place.',
        icon: `${ASSET_PATH}/svg/06A2-TaskStep.svg`,
        actionButton: {
          actionButtonLink:
            'https://help.equifax.com/s/article/How-do-I-place-a-security-freeze-on-a-protected-consumer-s-and-or-minor-s-Equifax-credit-file',
          actionButtonText: 'Equifax',
        },
      },
      {
        stepType: 'link',
        heading: 'Freeze your child’s credit report at TransUnion',
        copy:
          'You’ll need to mail in documents to TransUnion that prove the identity of your child and your authority over your child’s credit.',
        icon: `${ASSET_PATH}/svg/06A2-TaskStep.svg`,
        actionButton: {
          actionButtonLink: 'https://www.transunion.com/credit-freeze',
          actionButtonText: 'TransUnion',
        },
      },

      {
        stepType: 'link',
        heading: 'Freeze your child’s credit report at Experian',
        copy:
          'You’ll need to fill out and mail a form to Experian to create and freeze your child’s credit report, along with documentation of your child’s identity and your authority over her credit.',
        icon: `${ASSET_PATH}/svg/06A2-TaskStep.svg`,
        actionButton: {
          actionButtonLink:
            'https://www.experian.com/fraud/form-minor-child.html',
          actionButtonText: 'Experian',
        },
      },
    ],
  },
];

// Protect Your Accounts
export const makeYourLoginsSaferTasks = [
  {
    name: 'Strengthen weak passwords for key accounts',
    introCopy:
      'Remember that Facebook password you hastily created a while back? You know, the one that combined your street name with “123”? You should change that, ASAP. For accounts that contain sensitive information about you -- for example, financial, medical, and social accounts, or even shopping accounts like Amazon -- be extra vigilant about creating unique and hard-to-crack passwords.',
    confirmCopy: 'Task completed.',
    icon: `${ASSET_PATH}/svg/protect-accounts/protect-login-1.svg`,
    groups: [
      'financial',
      'email',
      'cloud',
      'networking',
      'medical',
      'insurance',
    ],
    steps: [
      {
        stepType: 'table',
        heading: 'Identify your “most important accounts” that need updating',
        copy:
          '“Most important accounts” refers to accounts you use often and that contain personal information about you. Start with social, email accounts, medical and finance accounts.',
        accountGroup: 'financial',
        checkboxColumns: ['done', 'maybe later'],
      },
      {
        stepType: 'table',
        heading:
          'Create a strong and unique password for each of these accounts',
        copy:
          'A strong password has at least 12 characters and a mix of uppercase, lowercase, numbers and symbols, and it should not contain any dictionary words or places. The easiest way to create a strong password that you won’t forget is to use a Password Manager.',
        accountGroup: 'financial',
        checkboxColumns: ['done', 'maybe later'],
      },
    ],
  },
  {
    name: 'Use a strategy to overhaul your passwords',
    introCopy:
      'A common complaint about strong passwords is that they’re hard to remember. And that can be true, unless you use a strategy for creating passwords that are memorable to you but obscure to everyone else. Follow these steps to make your password harder to crack.',
    confirmCopy: 'Task completed.',
    groups: ['financial', 'productivity', 'social', 'health', 'shopping'],
    icon: `${ASSET_PATH}/svg/protect-accounts/protect-login-2.svg`,
    steps: [
      {
        stepType: 'link',
        heading: 'Try a mnemonic strategy for creating passwords',
        copy:
          'As you might recall from school, mnemonics are a great to memorize chunks of information. First choose a phrase, poem or song lyric you know you’ll remember -- say,  “Hickory dickory dock,the mouse ran up the clock” and then make it unrecognizable to anyone but you. You could use the first letter of each word in the phrase: hdd,tmrutc. (You could also reverse the phrase if you have a shorter phrase to start with).',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
      {
        stepType: 'link',
        heading: 'Add symbols, digits and uppercase letters',
        copy:
          'Now that you’ve got your base password, based on your song or catchphrase, it’s time to make it even more unique by adding symbols, numbers, or uppercase letters. Try to avoid obvious substitutions, like substituting @ for “a.” In our example, we might end up with hdD!,tmrutC9.',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
      {
        stepType: 'link',
        heading: 'Make it long to make it strong',
        copy:
          'Once you have figured out what your strategy will be for creating your password, make sure you’ve made it long enough - a minimum of 10 characters. Looks like our example barely passes muster.',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
      {
        stepType: 'link',
        heading:
          'Repeat process for each password - or just use a password manager',
        copy:
          'If you’re planning to use a password manager -- and you really should! -- you’ll only need to create one password: the master password for your password manager. If you’re not, you’ll need to repeat this process for each new password you create.',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
    ],
  },
  {
    name: 'Hack-proof your security questions',
    introCopy:
      'Creating strong passwords is important, but security questions also can be a critical vulnerability in your login process. Security questions (such as your childhood street or mother’s maiden name) are “shared secrets” used to verify your identity in order to complete your login or retrieve your password. But often, the answers are not so secret, especially in an era of social sharing and big data--and this can be a pretty big security hole. Here’s how to plug it.',
    confirmCopy: 'Task completed.',
    groups: ['financial', 'productivity', 'social', 'health', 'shopping'],
    icon: `${ASSET_PATH}/svg/protect-accounts/protect-login-3.svg`,
    steps: [
      {
        stepType: 'link',
        heading: 'Choose a set of fake security answers',
        copy:
          'If you have a Password Manager, you can use it to set all your security answers in the same way you set your password. If you don’t have a Password Manager, write down a set of fake answers for common security questions on a piece of paper or someplace safe. For example, your answer for “What is your mother’s maiden name?” might be “Life-Noodle46” or something equally random, and you can use this whenever you encounter that specific security question on a website. Important: Don’t lose those answers. Retrieving security answers is much more difficult than passwords.',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
      {
        stepType: 'link',
        heading: 'Change your security answers for critical accounts',
        copy:
          'Now that you’ve created some fake security answers, it’s time to log in to your critical accounts -- finance, health, email, and social -- and reset your security questions. Each website has a different way of doing this; for example, to change your security answers in iTunes, you’ll need to go to iforgot.apple.com, log in and follow the instructions there.',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
    ],
  },
];
export const usePasswordManagerTasks = [
  {
    name: 'Install your password manager',
    introCopy:
      'Congratulations! You’ve taken a big step in securing your online identity. A password manager is going to make your life easier and your logins way more secure. We’ll show you what to do first.',
    confirmCopy: 'Task completed.',
    groups: ['cloud'],
    icon: `${ASSET_PATH}/svg/protect-accounts/protect-password-1.svg`,
    steps: [
      {
        stepType: 'link',
        heading: 'Set up your password manager account',
        copy:
          'To get started, first go through the setup process for your password manager.',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
      {
        stepType: 'link',
        heading: 'Create your master password',
        copy:
          'There’s only one password you’ll need to remember from now on, but it’s a biggie: your master password for your password manager. Make sure this is a memorable password, and write it down in a safe place if you need to. This password will be impossible to retrieve if you forget it.',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
      {
        stepType: 'link',
        heading: 'Download your secret key',
        copy:
          'Once you have created your master password, you will download a pdf containing a secret key. Print this out with your master password and keep it in a safe place. If you lose your computer or have a hard drive failure, this will help you to get your passwords back.',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
      {
        stepType: 'link',
        heading: 'Install your desktop app',
        copy:
          'Now you’re ready to install your apps. Follow the instructions to download the desktop app for your platform. ',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
      {
        stepType: 'link',
        heading: 'Install your smartphone app',
        copy:
          'Once you’ve installed your password manager on your desktop, be sure to install the app on your phone. This will enable you to use your password manager with your apps as well as websites you visit on your phone. ',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
      {
        stepType: 'link',
        heading: 'Install browser extensions',
        copy:
          'Once you’ve installed the application, it’s time to install your browser extensions. Whether you’re using Chrome, Safari, or Firefox, password manager extensions allow you to create passwords and safely log in through your browser with a single click.',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
    ],
  },
  {
    name: 'Get started with your password manager',
    introCopy:
      'Once you’ve installed your password manager, it’s time to start using it. This will entail using the password manager not only for new accounts, but doing back to existing accounts and changing your passwords there, too. And finally, you can use your password manager for more than just passwords. We recommend using it for your security questions, too.',
    confirmCopy: 'Task completed.',
    groups: ['financial', 'productivity', 'social', 'health', 'shopping'],
    icon: `${ASSET_PATH}/svg/protect-accounts/protect-password-2.svg`,
    steps: [
      {
        stepType: 'link',
        heading: 'Make your password manager your default',
        copy:
          'If you’ve been using your browser or your device’s built-in password management capabilities, take a moment to make your new password manager your default password manager. This will prevent conflicts in where your data is getting stored.',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
      {
        stepType: 'link',
        heading: 'Migrate your browser autofill logins to your vault',
        copy:
          'You’ll need to start by moving all your current passwords to your new password manager. Most password managers refer to the login storage area as a “vault,” so that’s what we’ll call it here. If your browser has been set up to autofill your passwords, that means you have passwords are stored in your browser. Follow the instructions for your individual password manager to export the passwords from your browser and into your new password manager.',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
      {
        stepType: 'link',
        heading: 'Migrate logins stored on your device to your vault',
        copy:
          'You might have passwords stored on the device itself - both Windows and Mac machines have some built-in password management (Apple’s is called Keychain). Find the passwords on your drive and copy them to the new password manager using the instructions in the password manager.',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
      {
        stepType: 'link',
        heading: 'Migrate the rest of your logins to your vault',
        copy:
          'If you don’t have your logins stored on your device or in your browser, you’ll need to add logins to your password manager as you log in to your regularly visited websites. Notice the new icon in your login box; this will give you an option for saving your logins to your vault as you log in.',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
      {
        stepType: 'link',
        heading: 'Create stronger passwords using your password manager',
        copy:
          'Once you’ve migrated your logins to your password manager’s vault, it’s time to go through your logins and make them stronger. To do this, you’ll need to log in to each site individually and change your password using your new password manager’s recommended strong password. Do this for all the sites in your vault to ensure that each password is unique and secure.',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
      {
        stepType: 'link',
        heading: 'Migrate your security questions to your password manager',
        copy:
          'Once you’ve updated your passwords, now you can use your password manager to store unique, random answers for your security questions. Starting with your most important finance accounts, go through your security answers and use your password manager to store random passwords as answers. This eliminates actually personally identifiable information from your security answers, and makes it much easier to fill them when you need them.',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
    ],
  },
];
export const use2FATasks = [
  {
    name: 'Enable 2FA on your email accounts',
    introCopy:
      'First, change your verification settings for your most important and frequently used accounts: your email.',
    confirmCopy: 'Task completed.',
    groups: ['email'],
    icon: `${ASSET_PATH}/svg/protect-accounts/protect-2fa-1.svg`,
    steps: [
      {
        stepType: 'link',
        heading: 'Open your email and find your 2FA settings',
        copy:
          'How you enable two-factor authentication (also known as verification) is a little different for each website, so you might have to look for it in your settings. For Gmail, for example, you can set 2FA by clicking the icon with your photo on the top right of your mail screen, clicking the Google Account button, and selecting “Signing in to Google” in the “Sign-in and security” section. From there you can select 2-Step Verification and follow the instructions. ',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
      {
        stepType: 'link',
        heading: 'Choose how you want to receive your login code',
        copy:
          'Many sites will give you multiple options for how you want to verify your login - that is, how you want the code to be sent that you will use as your additional login step. On Gmail, you’ll have several options for how you want to verify your login, including notifications, a text or voice message, or using an authenticator app to generate a code.',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
      {
        stepType: 'link',
        heading: 'Finish changing your settings',
        copy:
          'Once you’ve decided how to receive your code, you’ll go through the new process to finalize your settings. Make sure you have your second device nearby so you can enter the code to confirm your choice. ',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
      {
        stepType: 'link',
        heading: 'Repeat for all your email accounts',
        copy:
          'Finished with your first email account? Complete the same process with your remaining email accounts.',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
    ],
  },
  {
    name: 'Enable 2FA on your financial accounts',
    introCopy:
      'Once you’ve secured your email, it’s time to enable 2FA on your financial accounts.',
    confirmCopy: 'Task completed.',
    groups: ['banks'],
    icon: `${ASSET_PATH}/svg/protect-accounts/protect-2fa-2.svg`,
    steps: [
      {
        stepType: 'table',
        heading: 'Open your online bank account and find your 2FA settings',
        copy:
          'How you enable two-factor authentication (also known as verification) is a little different for each website, so you might have to look for it in your settings. ',
        accountGroup: 'financial',
        checkboxColumns: ['done', 'maybe later'],
      },
      {
        stepType: 'table',
        heading: 'Choose how you want to receive your login code',
        copy:
          'Many sites will give you multiple options for how you want to verify your login - that is, how you want the code to be sent that you will use as your additional login step. Go ahead and select from the available options for your financial website.',
        accountGroup: 'financial',
        checkboxColumns: ['done', 'maybe later'],
      },
      {
        stepType: 'table',
        heading: 'Finish changing your settings',
        copy:
          'Once you’ve decided how to receive your code, you’ll go through the new process to finalize your settings. Make sure you have your second device nearby so you can enter the code to confirm your choice.',
        accountGroup: 'financial',
        checkboxColumns: ['done', 'maybe later'],
      },
      {
        stepType: 'table',
        heading: 'Repeat for all your financial accounts',
        copy:
          'Finished with your first financial account? Complete the same process with your remaining financial accounts.',
        accountGroup: 'financial',
        checkboxColumns: ['done', 'maybe later'],
      },
    ],
  },
  {
    name: 'Enable 2FA on your social media accounts',
    introCopy:
      'Social media apps and websites also enable two-factor authentication, but only if you’re logging in from a new browser or device. That way you don’t have to log in and verify your login every time you visit the site, but you still get extra protection to prevent unauthorized logins.',
    confirmCopy: 'Task completed.',
    groups: ['networking', 'social media'],
    icon: `${ASSET_PATH}/svg/protect-accounts/protect-2fa-3.svg`,
    steps: [
      {
        stepType: 'table',
        heading: 'Open your social media account and find your 2FA settings',
        copy:
          'Log in to the social media website or app and go to your Settings > Privacy and security > Two-factor authentication. ',
        accountGroup: 'financial',
        checkboxColumns: ['done', 'maybe later'],
      },
      {
        stepType: 'table',
        heading: 'Choose how you want to receive your login code',
        copy:
          'Most social media sites let you receive the code via SMS or via an authentication app. ',
        accountGroup: 'financial',
        checkboxColumns: ['done', 'maybe later'],
      },
      {
        stepType: 'table',
        heading: 'Finish changing your settings',
        copy:
          'Once you’ve decided how to receive your code, you’ll go through the new process to finalize your settings. Make sure you have your second device nearby so you can enter the code to confirm your choice. ',
        accountGroup: 'financial',
        checkboxColumns: ['done', 'maybe later'],
      },
      {
        stepType: 'table',
        heading: 'Repeat for all your social media accounts',
        copy:
          'Finished with your first social media account? Complete the same process with your remaining accounts.',
        accountGroup: 'financial',
        checkboxColumns: ['done', 'maybe later'],
      },
    ],
  },
];
export const checkAccountBreachTasks = [
  {
    name: 'Update information for breached accounts',
    introCopy:
      'If an account scan has revealed accounts that have been breached, it’s important to figure out which information might have been compromised, and then take action to lock down your account. Here’s how to do it.',
    confirmCopy: 'Task completed.',
    groups: ['breach'],
    accountsToAdd: ['exactis', 'apollo'],
    icon: `${ASSET_PATH}/svg/secure-data/data-breach-1.svg`,
    steps: [
      {
        stepType: 'link',
        heading: 'Figure out what data was stolen',
        copy:
          'First, you’ll need to figure out what data might have been stolen for each of your breached accounts. If it was hashed data or if the data was encrypted, that probably means you have lower risk - but there’s still a chance a hacker might be able to decrypt the data. If your email, passwords, social security, credit card info, or address was breached, you’ll need to take further action to secure your data.',
        actionButton: {
          actionButtonLink: 'https://haveibeenpwned.com',
          actionButtonText: '',
        },
      },
      {
        stepType: 'table',
        heading: 'Change your passwords',
        copy:
          'Regardless of which data was stolen, go ahead and change your passwords for all impacted accounts. Perhaps this is a good time to consider a password manager.',
        accountGroup: 'breach',
        checkboxColumns: ['done', 'maybe later'],
      },
      {
        stepType: 'link',
        heading:
          'If your credit card info was breached, call your credit card provider',
        copy:
          'If your credit card info was compromised, call your credit card company to let them know - you can dial the number that’s on your credit card. They might need to issue you a new card.',
        actionButton: {
          actionButtonLink: '',
          actionButtonText: '',
        },
      },
      {
        stepType: 'table',
        heading: 'Put extra protections on your login',
        copy:
          'Enable 2FA authentication, if it’s available, on your breached accounts. This will give your account an extra layer of protection.',
        accountGroup: 'breach',
        checkboxColumns: ['done', 'maybe later', 'not applicable'],
      },
    ],
  },
  {
    name: 'Change logins that share exposed data',
    introCopy:
      'Do you use the same username or password across multiple accounts? If so, you’ll need to track down those other accounts and make sure you change the exposed username or password. ',
    confirmCopy: 'Task completed.',
    groups: ['exposed'],
    accountsToAdd: ['exactis', 'apollo', 'equifax'],
    icon: `${ASSET_PATH}/svg/secure-data/data-breach-2.svg`,
    steps: [
      {
        stepType: 'link',
        heading: 'Identify usernames and passwords in breached data',
        copy:
          'Determine if the compromised data in your breached accounts includes login information that is shared by other accounts, like username or password.',
        actionButton: {
          actionButtonLink: 'https://haveibeenpwned.com',
          actionButtonText: '',
        },
      },
      {
        stepType: 'table',
        heading:
          'Change your login for accounts that use the breached username or password',
        copy:
          'Hopefully, you’re using a different username and password for each account. If not - if you use the same password or username across multiple accounts - now is the time to go through those accounts and create unique usernames and passwords for each of them.',
        accountGroup: 'exposed',
        checkboxColumns: ['done', 'maybe later', 'not applicable'],
      },
    ],
  },
  {
    name: 'Run breach scans on secondary email addresses',
    introCopy:
      'Remember that Hotmail account you closed last year? Or that second email address you don’t use very often? It’s time to check those accounts for breaches and lock down any breached accounts.',
    confirmCopy: 'Task completed.',
    groups: ['breach plus'],
    accountsToAdd: ['exactis', 'apollo'],
    icon: `${ASSET_PATH}/svg/secure-data/data-breach-3.svg`,
    steps: [
      {
        stepType: 'link',
        heading: 'Identify usernames and passwords in breached data',
        copy:
          'Determine if the compromised data in your breached accounts includes login information that is shared by other accounts, like username or password.',
        actionButton: {
          actionButtonLink: 'https://haveibeenpwned.com',
          actionButtonText: '',
        },
      },
      {
        stepType: 'table',
        heading:
          'Change your login for accounts that use the breached username or password',
        copy:
          'Hopefully, you’re using a different username and password for each account. If not - if you use the same password or username across multiple accounts - now is the time to go through those accounts and create unique usernames and passwords for each of them.',
        accountGroup: 'breach plus',
        checkboxColumns: ['done', 'maybe later', 'not applicable'],
      },
    ],
  },
];
