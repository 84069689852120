import styled, { css } from 'styled-components';

// Onboarding
export const OnboardingSVGContainer = styled.div`
  position: relative;
  height: 50vh;
  text-align: center;

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  svg {
    height: 100%;
  }
`;
export const PositionAbsolute = styled.div`
  position: absolute;
  top: ${props => (props.top ? props.top : 0)};
  bottom: ${props => props.bottom && props.bottom};
  left: ${props => props.left && props.left};
  right: ${props => props.right && props.right};
`;

const posSvg = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
`;
export const OldSvg = styled.div`
  ${posSvg};
`;
export const NewSvg = styled.div`
  ${posSvg};
`;
