import theme from '../components/styled/theme';

export function phyllotaxis(radius, width, height, i) {
  const theta = Math.PI * (3 - Math.sqrt(5));
  const r = radius * Math.sqrt(i);
  const a = theta * i;
  return {
    x: width / 2 + r * Math.cos(a),
    y: height / 2 + r * Math.sin(a),
  };
}

export function getColor(category, isActive) {
  switch (category) {
    case 'financial':
      return isActive
        ? theme.colors.viz.financial
        : theme.colors.viz.financialFade;
    case 'productivity':
      return isActive
        ? theme.colors.viz.productivity
        : theme.colors.viz.productivityFade;
    case 'social':
      return isActive ? theme.colors.viz.social : theme.colors.viz.socialFade;
    case 'shopping':
      return isActive
        ? theme.colors.viz.shopping
        : theme.colors.viz.shoppingFade;
    case 'health':
      return isActive ? theme.colors.viz.health : theme.colors.viz.healthFade;
    case 'dummy':
      return theme.colors.grayFade;
    default:
      return isActive ? theme.colors.viz.other : theme.colors.viz.otherFade;
  }
}
