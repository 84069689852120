import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import WithUi from './hoc/WithUi';

import { AnimationFade } from './styled/animation';
import { UnderlayHitArea } from './styled/modal';

class ModalUnderlay extends Component {
  nextSlide() {
    this.setState({
      activeSlide: this.state.activeSlide + 1,
    });
  }
  render() {
    const {
      hideModal,
      ui: { isModalVisible },
    } = this.props;
    return (
      <AnimationFade isIn={isModalVisible}>
        <UnderlayHitArea
          onClick={() => hideModal()}
          isClickable={isModalVisible}
        />
      </AnimationFade>
    );
  }
}

const propTypes = {
  ui: PropTypes.shape({
    isModalVisible: PropTypes.bool,
  }),
};
ModalUnderlay.propTypes = propTypes;

export default compose(WithUi)(ModalUnderlay);
