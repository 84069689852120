import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteAccountById } from '../../../actions';

import uuid from 'uuid';
import times from 'lodash/times';

import { AccountListing, AccountTableContainer } from '../../styled/discovery';

class AccountTable extends Component {
  render() {
    const { filteredAccounts, deleteAccountById } = this.props;
    const fillNumber = 5 - filteredAccounts.length;
    return (
      <AccountTableContainer showBottomFade={filteredAccounts.length > 5}>
        {filteredAccounts.map(({ id, name, groups = [] }) => (
          <AccountListing
            key={name}
            onDoubleClick={() => deleteAccountById(id)}>
            {name}
          </AccountListing>
        ))}
        {fillNumber > 0 &&
          times(fillNumber, () => <AccountListing key={uuid()} />)}
      </AccountTableContainer>
    );
  }
}

const propTypes = {
  filteredAccounts: PropTypes.array.isRequired,
  deleteAccountById: PropTypes.func.isRequired,
};
AccountTable.propTypes = propTypes;

export default connect(
  null,
  { deleteAccountById }
)(AccountTable);
