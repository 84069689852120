import React from 'react';

import AnimationLinkRoute from '../AnimationLinkRoute';

import styled from 'styled-components';
import { fontSizer, transitionOpacity, transitionXs } from './mixins';

export const HeaderContainer = styled.div`
  position: relative;
  width: 100%;

  & > div,
  & > hr {
    // stack in front of "depth dots"
    position: relative;
    z-index: ${props => props.theme.zIndex.one};
  }
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 50%;
    pointer-events: none;
  }

  // "depth dots"
  &:before {
    top: 40%;
    left: -1%;
    width: 15vw;
    height: 15vw;
    background: ${props => props.theme.colors.special.blueDepthDot1};
    filter: blur(60px);
  }
  &:after {
    top: -36%;
    right: 3%;
    width: 18vw;
    height: 18vw;
    background: ${props => props.theme.colors.special.blueDepthDot2};
    filter: blur(60px);
    z-index: ${props => props.theme.zIndex.zero};
  }
`;
export const HeaderInnerContainer = styled.div`
  height: ${props => props.theme.spacing.fixed.headerHeight};
`;
const LogoContainer = styled.div`
  margin-right: 0;
`;
const LogoInner = styled.div`
  display: inline-block;
  position: relative;
  ${transitionOpacity};
`;
export const Logo = props => (
  <LogoContainer>
    <AnimationLinkRoute to={props.to}>
      <LogoInner {...props}>
        <img src={props.img} alt="" />
      </LogoInner>
    </AnimationLinkRoute>
  </LogoContainer>
);
export const HeaderNavGroup = styled.div`
  display: ${props => (props.showMd ? 'none' : 'inline-block')};
  flex: ${props => props.flex && 1};

  @media (min-width: ${props => props.theme.breakpoints.md}px) {
    display: inline-block;
  }
`;
const HeaderLinkContainer = styled.div`
  display: inline-block;
  margin: ${props => (props.logotype ? '0 2.6em 0 0.4em' : '0 1.4em')};
`;
const InnerHeaderLink = styled.a`
  font-family: ${props => props.theme.type.family.heading};
  color: ${props => props.theme.colors.whiteAlt};
  ${props =>
    fontSizer(
      props.theme.type.size.h3,
      props.theme.type.multipliers.heading,
      props.theme.breakpoints
    )};
  font-weight: ${props => (props.logotype ? 300 : 500)};
  letter-spacing: ${props =>
    props.logotype
      ? props.theme.type.letterSpacing.extraWide
      : props.theme.type.letterSpacing.narrow};
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  opacity: 0.9;
  ${transitionXs};

  &:hover {
    opacity: 1;
    color: ${props => props.theme.colors.whiteAlt};
  }
`;
export const HeaderLink = props => (
  <HeaderLinkContainer {...props}>
    <AnimationLinkRoute to={props.to}>
      <InnerHeaderLink {...props} />
    </AnimationLinkRoute>
  </HeaderLinkContainer>
);
export const Hr = styled.hr`
  margin: 0;
  padding: 0;
  height: 1px;
  background: ${props => props.theme.colors.special.blueFadeHr};
  border: 0;
`;
