import React, { Component } from 'react';
import PropTypes from 'prop-types';

import merge from 'lodash/merge';

import { sanitize } from '../../../utilities/';

import { LinkBasic, SmallText } from '../../styled/type';
import { Table, Td, Th } from '../../styled/containers';

class TaskTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkboxValues: {},
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }
  handleInputChange(e, accountName, columnName) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const checkboxUpdate = {
      [columnName]: {
        [accountName]: value,
      },
    };
    this.setState({
      checkboxValues: merge(this.state.checkboxValues, checkboxUpdate),
    });
  }
  render() {
    const { accountGroup, accounts, checkboxColumns } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            <Th>
              <SmallText fontWeight="bold" color="gray7">
                {accountGroup && `${accountGroup} `}
                account
              </SmallText>
            </Th>
            {checkboxColumns.map(column => (
              <Th key={sanitize(column)} textAlign="left">
                <SmallText fontWeight="bold" color="gray7">
                  {column}
                </SmallText>
              </Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {accounts.map(
            ({
              id,
              name: accountName,
              groups = [],
              url = 'https://example.com',
            }) => (
              <tr key={id}>
                <Td>{accountName}</Td>
                <Td textAlign="left">
                  <LinkBasic
                    as="a"
                    href={url}
                    fontWeight="normal"
                    icon
                    target="_blank"
                    rel="noopener noreferrer">
                    Link to {accountName}
                  </LinkBasic>
                </Td>
              </tr>
            )
          )}
        </tbody>
      </Table>
    );
  }
}

const propTypes = {
  accountGroup: PropTypes.string,
  accounts: PropTypes.array.isRequired,
  checkboxColumns: PropTypes.array,
};
TaskTable.propTypes = propTypes;

export default TaskTable;
