import React from 'react';

import AnimationFadeRoute from './AnimationFadeRoute';

import { Center, ContentWidth, Paper } from './styled/containers';
import { H3 } from './styled/type';

const pageNotFound = props => (
  <AnimationFadeRoute>
    <ContentWidth wide paddingTop="lg2">
      <Paper>
        <Center>
          <H3>404 page not found</H3>
        </Center>
      </Paper>
    </ContentWidth>
  </AnimationFadeRoute>
);

export default pageNotFound;
