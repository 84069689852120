import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  addAccount,
  addAccounts,
  deleteAccountById,
  deleteAccountByName,
} from '../../actions';

const WithAccounts = WrappedComponent => {
  return class extends Component {
    render() {
      return (
        <WrappedComponent accounts={this.props.accounts} {...this.props} />
      );
    }
  };
};

const mapStateToProps = ({ accounts }) => ({
  accounts,
});
export default compose(
  connect(
    mapStateToProps,
    {
      addAccount,
      addAccounts,
      deleteAccountById,
      deleteAccountByName,
    }
  ),
  WithAccounts
);
