import React from 'react';
import PropTypes from 'prop-types';

import {
  HeaderInnerContainer,
  HeaderLink,
  HeaderNavGroup,
  Logo,
} from './styled/header';
import { ContentWidth, FlexRowVAlign } from './styled/containers';
import {
  InitializeAnimationFadeDelay1,
  InitializeAnimationFadeDelay3,
} from './styled/animation';

import logoImg from '../images/logo-fpo.png';

const HeaderInner = props => (
  <HeaderInnerContainer>
    <ContentWidth fullHeight marginBottom={0} paddingTop={0} wide>
      <FlexRowVAlign>
        <InitializeAnimationFadeDelay1>
          <Logo to="/onboarding" img={logoImg} />
        </InitializeAnimationFadeDelay1>
        <HeaderNavGroup flex>
          <InitializeAnimationFadeDelay3>
            <HeaderLink to="/" logotype={1}>
              Digital Safety
            </HeaderLink>
          </InitializeAnimationFadeDelay3>
        </HeaderNavGroup>
      </FlexRowVAlign>
    </ContentWidth>
  </HeaderInnerContainer>
);

const propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      groups: PropTypes.array,
      tasks: PropTypes.array,
    })
  ),
  masterModuleList: PropTypes.array.isRequired,
};
HeaderInner.propTypes = propTypes;

export default HeaderInner;
