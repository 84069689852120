import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { filterAccountsByGroup } from '../../utilities';

import TaskTable from './sub-components/TaskTable';

import { Flex, FlexRow, PaperContentWidth } from '../styled/containers';
import { H1, P } from '../styled/type';
import {
  InitializeAnimationFadeDelay1,
  InitializeAnimationFadeDelay2,
} from '../styled/animation';

class SlideStepTypeTable extends Component {
  render() {
    const {
      accounts,
      step: { accountGroup, checkboxColumns, copy, heading = '' },
      task: { name },
    } = this.props;

    const eligibleAccounts = filterAccountsByGroup(accounts, accountGroup);
    return (
      <PaperContentWidth wide paddingTop="xxl">
        <FlexRow>
          <Flex flex={2} paddingRight="xl">
            <InitializeAnimationFadeDelay1>
              <H1>{heading ? heading : name}</H1>
              <P>{copy}</P>
            </InitializeAnimationFadeDelay1>
          </Flex>
          <Flex flex={3} alignSelf="center">
            <InitializeAnimationFadeDelay2>
              <TaskTable
                accountGroup={accountGroup}
                accounts={eligibleAccounts}
                checkboxColumns={checkboxColumns}
              />
            </InitializeAnimationFadeDelay2>
          </Flex>
        </FlexRow>
      </PaperContentWidth>
    );
  }
}

const propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      groups: PropTypes.array,
      tasks: PropTypes.array,
    })
  ),
  step: PropTypes.shape({
    accountGroup: PropTypes.string.isRequired,
    checkboxColumns: PropTypes.array.isRequired,
    copy: PropTypes.string,
    heading: PropTypes.string,
  }),
  task: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};
SlideStepTypeTable.propTypes = propTypes;

export default SlideStepTypeTable;
