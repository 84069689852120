import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import uuid from 'uuid';

import { slugify } from '../../utilities';

import { P } from '../styled/type';
import { InnerSection } from '../styled/dev';

import ActionListWithMatchedAccounts from './ActionListWithMatchedAccounts';

const ModuleList = ({
  accounts,
  module: { name, actions },
  showMatchingAccounts,
}) => (
  <div>
    <P>
      <Link to={`/secure/${slugify(name)}?show-intro`}>{name}</Link>
    </P>
    <InnerSection>
      {actions.map(
        action =>
          showMatchingAccounts ? (
            <ActionListWithMatchedAccounts
              key={uuid()}
              accounts={accounts}
              action={action}
              moduleSlug={slugify(name)}
            />
          ) : (
            <P key={slugify(action.name)}>
              <Link
                to={`/secure/${slugify(name)}/${slugify(
                  action.name
                )}?show-intro`}>
                {action.name}
              </Link>
            </P>
          )
      )}
    </InnerSection>
  </div>
);

const propTypes = {
  accounts: PropTypes.array.isRequired,
  module: PropTypes.shape({
    actions: PropTypes.array,
    name: PropTypes.string.isRequired,
  }),
  showMatchingAccounts: PropTypes.bool,
};
ModuleList.propTypes = propTypes;

export default ModuleList;
