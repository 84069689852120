import React from 'react';
import PropTypes from 'prop-types';

import uuid from 'uuid';

import { checkIfCompleted, slugify } from '../../utilities';

import WithProgress from '../hoc/WithProgress';

import { H1, H2, H3, H5, NumOfNum, StackedH2 } from '../styled/type';
import {
  HomeModalHeading,
  HomeModalHeadingLeft,
  HomeModalHeadingRight,
  HomeModalModuleGroup,
  HomeModalActionsGroup,
  HomeModalModuleHeading,
  HomeModalModuleContent,
  HomeModalActionBody,
} from '../styled/home';
import { LockIcon } from '../styled/icons';
import { HomeModalActionLink, HomeModalTaskLink } from '../styled/type';
import { FlexRow } from '../styled/containers';

const HomeModal = ({ overlay, progress }) => {
  if (typeof overlay.activeAccounts === 'undefined') {
    return null;
  }

  const { name, groups } = overlay.activeAccounts[0];

  const numberOfCompletedTasks =
    overlay.activeModulesActionsTasks.flatMap(module =>
      module.actions.flatMap(({ tasks }) =>
        tasks.filter(({ name }) => checkIfCompleted(slugify(name), progress))
      )
    ).length || 0;
  const totalNumberOfTasks = overlay.activeModulesActionsTasks
    .map(({ actions }) =>
      actions.reduce((total, { tasks }) => total + tasks.length, 0)
    )
    .reduce((total, num) => total + num, 0);

  return !groups ? (
    <div />
  ) : (
    <div>
      <HomeModalHeading>
        <HomeModalHeadingLeft>
          <H1 marginBottom={0}>{name}</H1>
          <H5>{groups.map((group, i) => (i === 0 ? group : ` • ${group}`))}</H5>
          <H2>Actions to take for this account</H2>
        </HomeModalHeadingLeft>
        <HomeModalHeadingRight>
          <StackedH2>
            Completed
            <br />
            Actions
          </StackedH2>
          <NumOfNum>
            <span>{numberOfCompletedTasks}</span> of{' '}
            <span>{totalNumberOfTasks}</span>
          </NumOfNum>
        </HomeModalHeadingRight>
      </HomeModalHeading>
      {overlay.activeModulesActionsTasks &&
        overlay.activeModulesActionsTasks.map(module => {
          const totalNumberOfTasks = module.actions.reduce(
            (total, { tasks }) => total + tasks.length,
            0
          );
          return (
            !!totalNumberOfTasks && (
              <HomeModalModuleGroup key={module.name}>
                <FlexRow>
                  <HomeModalModuleHeading>
                    <H3 fontWeight="bold">{module.name}</H3>
                  </HomeModalModuleHeading>
                  <HomeModalModuleContent>
                    <HomeModalActionsGroup>
                      {module.actions.map(
                        action =>
                          !!action.tasks.length && (
                            <HomeModalActionBody key={action.name}>
                              <HomeModalActionLink
                                to={`/secure/${slugify(module.name)}/${slugify(
                                  action.name
                                )}?from-home`}>
                                {action.name}
                              </HomeModalActionLink>
                              {action.tasks.map(task => (
                                <div key={task.name}>
                                  <HomeModalTaskLink
                                    to={`/secure/${slugify(
                                      module.name
                                    )}/${slugify(action.name)}/${slugify(
                                      task.name
                                    )}`}>
                                    <LockIcon
                                      key={uuid()}
                                      marginRight="sm2"
                                      isComplete={checkIfCompleted(
                                        slugify(task.name),
                                        progress
                                      )}
                                    />
                                    {task.name}
                                  </HomeModalTaskLink>
                                </div>
                              ))}
                            </HomeModalActionBody>
                          )
                      )}
                    </HomeModalActionsGroup>
                  </HomeModalModuleContent>
                </FlexRow>
              </HomeModalModuleGroup>
            )
          );
        })}
    </div>
  );
};

const propTypes = {
  masterModuleList: PropTypes.array.isRequired,
  overlay: PropTypes.object,
};
HomeModal.propTypes = propTypes;

export default WithProgress(HomeModal);
