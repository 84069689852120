import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import styled from 'styled-components';

import WithAnimation from './hoc/WithAnimation';

import { TRANSITION } from '../constants/general.js';
const { MD_DURATION } = TRANSITION;

export const AnimationLinkRouteInner = styled.span`
  display: inline-block;
  cursor: pointer;
`;

class AnimationLinkRoute extends Component {
  clickHandler() {
    const {
      history,
      location: { pathname },
      to,
    } = this.props;

    if (pathname === to) {
      return;
    }

    this.props.setIsRouteIn(false);

    setTimeout(() => {
      history.push(to);
    }, MD_DURATION);
  }
  render() {
    return (
      <AnimationLinkRouteInner onClick={() => this.clickHandler()}>
        {this.props.children}
      </AnimationLinkRouteInner>
    );
  }
}

const propTypes = {
  to: PropTypes.string.isRequired,
};
AnimationLinkRoute.propTypes = propTypes;

export default compose(
  withRouter,
  WithAnimation
)(AnimationLinkRoute);
