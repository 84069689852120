import React, { Component } from 'react';
import PropTypes from 'prop-types';

import WithAccounts from '../hoc/WithAccounts';

class DevAddAccountForm extends Component {
  constructor(props) {
    super(props);

    // "form" prefix distinguishes local form control from redux
    this.state = {
      formAccountName: '',
      formGroup: this.props.groupName || '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.formAddAccount = this.formAddAccount.bind(this);

    this.nameInput = React.createRef();
    this.groupInput = React.createRef();
    this.submitInput = React.createRef();
    this.form = React.createRef();
  }
  keypressHandler = e => {
    if (!this.nameInput) return;
    const formIsFocused =
      this.nameInput.id === document.activeElement.id ||
      this.groupInput.id === document.activeElement.id ||
      this.submitInput.id === document.activeElement.id;

    if (e.key === 'Enter' && formIsFocused) {
      e.preventDefault();
      this.formAddAccount();
    }
  };

  componentDidMount() {
    window.addEventListener('keypress', this.keypressHandler.bind(this));
    this.form.current.addEventListener('submit', e => {
      // prevent page refresh
      e.preventDefault();
    });
  }
  componentWillUnmount() {
    window.removeEventListener('keypress', this.keypressHandler.bind(this));
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.groupName !== this.props.groupName) {
      this.setState({
        formGroup: this.props.groupName,
      });
    }
  }

  handleInputChange({ target: { name, value } }) {
    this.setState({
      [name]: value,
    });
  }
  formAddAccount = () => {
    const {
      props: { addAccount, categoryParam },
      state: { formAccountName, formGroup },
    } = this;

    if (this.state.formAccountName !== '') {
      addAccount(formAccountName, formGroup, categoryParam);
      this.setState({
        formAccountName: '',
      });
    }
  };
  render() {
    return (
      <form ref={this.form}>
        <fieldset>
          <label htmlFor="formAccountName">Name:</label>
          <input
            name="formAccountName"
            id="formAccountName"
            type="text"
            value={this.state.formAccountName}
            onChange={this.handleInputChange}
            ref={ref => (this.nameInput = ref)}
          />
        </fieldset>
        <fieldset>
          <label htmlFor="formGroup">Group:</label>
          <input
            name="formGroup"
            id="formGroup"
            type="text"
            value={this.state.formGroup}
            onChange={this.handleInputChange}
            ref={this.groupInput}
            disabled={this.props.lockGroup}
          />
        </fieldset>
        <button
          onClick={() => this.formAddAccount()}
          id="submitGroup"
          ref={this.submitInput}>
          add account
        </button>
      </form>
    );
  }
}

const propTypes = {
  addAccount: PropTypes.func.isRequired,
  categoryParam: PropTypes.string.isRequired,
  groupName: PropTypes.string,
  lockGroup: PropTypes.bool,
};
DevAddAccountForm.propTypes = propTypes;

export default WithAccounts(DevAddAccountForm);
