import {
  SET_IS_INITIAL_LOAD_IN,
  SET_IS_PAPER_IN,
  SET_IS_ROUTE_IN,
  SET_IS_SLIDE_IN,
} from '../constants/actions';

const initialState = {
  isInitialLoadIn: false,
  isPaperIn: true,
  isRouteIn: true,
  isSlideIn: true,
};

export default function animation(state = initialState, action) {
  switch (action.type) {
    case SET_IS_INITIAL_LOAD_IN:
      return { ...state, isInitialLoadIn: action.isInitialLoadIn };
    case SET_IS_PAPER_IN:
      return { ...state, isPaperIn: action.isPaperIn };
    case SET_IS_ROUTE_IN:
      return { ...state, isRouteIn: action.isRouteIn };
    case SET_IS_SLIDE_IN:
      return { ...state, isSlideIn: action.isSlideIn };

    default:
      return state;
  }
}
