import React from 'react';

import { ButtonPrimary, ButtonSecondary } from '../styled/buttons';
import { InnerSection, Section } from '../styled/dev';
import { H1, H2, H3, H4, H5, H6, P } from '../styled/type';

const ComponentsList = () => (
  <Section>
    <H2>Components</H2>
    <InnerSection>
      <H3>Headings</H3>
      <InnerSection>
        <H1>H1</H1>
        <H2>H2</H2>
        <H3>H3</H3>
        <H4>H4</H4>
        <H5>H5</H5>
        <H6>H6</H6>
      </InnerSection>
    </InnerSection>
    <InnerSection>
      <H3>copy</H3>
      <InnerSection>
        <P>
          Aliquam erat volutpat. Nunc eleifend leo vitae magna. In id erat non
          orci commodo lobortis. Proin neque massa, cursus ut, gravida ut,
          lobortis eget, lacus. Sed diam. Praesent fermentum tempor tellus.
          Nullam tempus. Mauris ac felis vel velit tristique imperdiet. Donec at
          pede. Etiam vel neque nec dui dignissim bibendum. Vivamus id enim.
          Phasellus neque orci, porta a, aliquet quis, semper a, massa.
          Phasellus purus. Pellentesque tristique imperdiet tortor. Nam euismod
          tellus id erat.
        </P>
        <P>
          Aliquam erat volutpat. Nunc eleifend leo vitae magna. In id erat non
          orci commodo lobortis. Proin neque massa, cursus ut, gravida ut,
          lobortis eget, lacus. Sed diam. Praesent fermentum tempor tellus.
          Nullam tempus. Mauris ac felis vel velit tristique imperdiet. Donec at
          pede. Etiam vel neque nec dui dignissim bibendum. Vivamus id enim.
          Phasellus neque orci, porta a, aliquet quis, semper a, massa.
          Phasellus purus. Pellentesque tristique imperdiet tortor. Nam euismod
          tellus id erat.
        </P>
      </InnerSection>
    </InnerSection>
    <InnerSection>
      <H3>Buttons</H3>
      <InnerSection>
        <ButtonPrimary>Button Primary</ButtonPrimary>
        <ButtonSecondary>Button Secondary</ButtonSecondary>
      </InnerSection>
    </InnerSection>
  </Section>
);

export default ComponentsList;
