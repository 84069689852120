import styled from 'styled-components';

import {
  flexRowSpaceBetweenCenter,
  transitionXs,
  transitionMdSCurve,
} from './mixins';

export const AccordionContainer = styled.div`
  border-top: 1px solid ${props => props.theme.colors.grayFadeAlt};
  border-bottom: 1px solid ${props => props.theme.colors.grayFadeAlt};

  & + & {
    border-top: 0;
  }
`;
export const AccordionHead = styled.div`
  ${flexRowSpaceBetweenCenter};
  padding-top: ${props => props.theme.spacing.vertical.sm2};
  padding-bottom: ${props => props.theme.spacing.vertical.sm2};
`;
export const AHLeft = styled.div`
  cursor: pointer;
  width: ${props => props.theme.spacing.fixed.accordionIndentation};
`;
export const AHCenter = styled.div`
  flex: 1;
`;
export const AHRight = styled.div`
  align-self: flex-end;
  text-align: right;
  margin-left: ${props => props.theme.spacing.horizontal.lg};
`;
export const AccordionBodyContainer = styled.div`
  position: relative;
  margin-left: ${props => props.theme.spacing.fixed.accordionIndentation};
  overflow: hidden;
  ${transitionMdSCurve};
`;
export const AccordionBody = styled.div`
  position: absolute;
  left: 0;
  display: block;
  padding-bottom: ${props => props.theme.spacing.vertical.md};
`;
export const AccordionHeading = styled.div``;

export const ProgressIndicator = styled.div.attrs({
  progresswidth: props => {
    if (props.numberCompleted && props.total) {
      return (props.numberCompleted / props.total) * 100;
    } else {
      return 0;
    }
  },
})`
  position: relative;
  width: 100%;
  height: 1px;
  margin-top: ${props => props.theme.spacing.vertical.sm};
  margin-bottom: ${props => props.theme.spacing.vertical.sm};
  background: ${props => props.theme.colors.grayb};
  border-radius: 2px;
  box-shadow: 0 0 1px ${props => props.theme.colors.grayb};

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${props => props.progresswidth}%;
    background: ${props => props.theme.colors.primary};
    box-shadow: 0 0 1px ${props => props.theme.colors.primary};
  }
`;

export const Arrow = styled.div.attrs({
  rotation: props => `rotate(${props.isPointingDown ? '135' : '-45'}deg)`,
  translation: props => `translateY(${props.isPointingDown ? '-30%' : '30%'})`,
})`
  position: relative;
  left: 5px;
  width: 13px;
  height: 13px;
  border-top: 2px solid ${props => props.theme.colors.gray9};
  border-right: 2px solid ${props => props.theme.colors.gray9};
  transform: ${props => props.translation} ${props => props.rotation};
  ${transitionXs};
`;

export const AccordionIconSVGContainer = styled.div`
  width: 20px;
  height: 22px;

  // target nested divs created by react-svg
  div {
    height: 100%;
  }
  svg {
    width: 100%;
    height: 100%;

    path {
      fill: ${props => props.theme.colors.gray9};
    }
  }
`;
