const assetPath = process.env.REACT_APP_ASSET_PATH
  ? process.env.REACT_APP_ASSET_PATH
  : '';

module.exports = {
  ASSET_PATH: assetPath,
  FALLBACK_SVG_ACTION: `${assetPath}/svg/02-ModuleActionsA.svg`,
  FALLBACK_SVG_ACTION_INTRO: `${assetPath}/svg/03A-ActionIntro.svg`,
  FALLBACK_SVG_MODULE_INTRO: `${assetPath}/svg/01-ModuleIntro.svg`,
  FALLBACK_SVG_TASK_INTRO: `${assetPath}/svg/05A1-TaskIntro.svg`,
  FALLBACK_SVG_TASK_STEP: `${assetPath}/svg/generic.svg`,
  HIDE_CONFIRMATIONS: true,
  SKIP_SPLASH: false,
  TRANSITION: {
    LG_DURATION: 800,
    SM_DURATION: 460,
    MD_DURATION: 580,
    SPLASH_DURATION: 7200,
  },
};
