import { ASSET_PATH } from '../constants/general.js';

export const ACCOUNT_CATEGORIES = [
  {
    name: 'financial',
    icon: `${ASSET_PATH}/svg/financial.svg`,
  },
  {
    name: 'productivity',
    icon: `${ASSET_PATH}/svg/productivity.svg`,
    introSvg: `${ASSET_PATH}/svg/productivity-intro.svg`,
  },
  {
    name: 'social',
    icon: `${ASSET_PATH}/svg/social.svg`,
    introSvg: `${ASSET_PATH}/svg/social-intro.svg`,
  },
  {
    name: 'health',
    icon: `${ASSET_PATH}/svg/health.svg`,
    introSvg: `${ASSET_PATH}/svg/health-intro.svg`,
  },
  {
    name: 'shopping',
    icon: `${ASSET_PATH}/svg/shopping.svg`,
    introSvg: `${ASSET_PATH}/svg/shopping-intro.svg`,
  },
  {
    name: 'other',
    icon: `${ASSET_PATH}/svg/other.svg`,
    introSvg: `${ASSET_PATH}/svg/other-intro.svg`,
  },
];
