import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ButtonGroup from '../ButtonGroup';
import SharedIntro from '../SharedIntro';

import { InitializeAnimationFadeDelay3 } from '../styled/animation';

const ModuleIntro = ({
  advanceSlide,
  exit,
  module: { icon, introCopy, name },
}) => (
  <Fragment>
    <SharedIntro copy={introCopy} icon={icon} />

    <InitializeAnimationFadeDelay3>
      <ButtonGroup
        buttonSecondaryText="Not Now"
        buttonPrimaryText="Get Started"
        onClickButtonSecondary={exit}
        onClickButtonPrimary={advanceSlide}
      />
    </InitializeAnimationFadeDelay3>
  </Fragment>
);

const propTypes = {
  advanceSlide: PropTypes.func.isRequired,
  exit: PropTypes.func.isRequired,
  module: PropTypes.shape({
    icon: PropTypes.icon,
    introCopy: PropTypes.string,
    name: PropTypes.string,
  }),
};
ModuleIntro.propTypes = propTypes;

export default ModuleIntro;
