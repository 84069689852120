import { combineReducers } from 'redux';

import accounts from './accounts';
import animation from './animation';
import progress from './progress';
import ui from './ui';
import user from './user';

const rootReducer = combineReducers({
  accounts,
  animation,
  progress,
  ui,
  user,
});

export default rootReducer;
