import {
  SET_ARE_INTERACTIONS_BLOCKED,
  SET_HOME_HEADER_HEIGHT,
  SET_MODAL_VISIBILITY,
  TOGGLE_VISUALIZATION,
} from '../constants/actions';

const initialState = {
  areInteractionsBlocked: false,
  homeHeaderHeight: 0,
  isModalVisible: false,
  showAlternateVisualization: false,
};

export default function ui(state = initialState, action) {
  switch (action.type) {
    case SET_ARE_INTERACTIONS_BLOCKED:
      return {
        ...state,
        areInteractionsBlocked: action.areInteractionsBlocked,
      };
    case SET_HOME_HEADER_HEIGHT:
      return {
        ...state,
        homeHeaderHeight: action.homeHeaderHeight,
      };
    case SET_MODAL_VISIBILITY:
      return {
        ...state,
        isModalVisible: action.isModalVisible,
      };
    case TOGGLE_VISUALIZATION:
      return {
        ...state,
        showAlternateVisualization: !state.showAlternateVisualization,
      };
    default:
      return state;
  }
}
