import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { clearData, loadProfile } from '../../actions';

import { HIDE_CONFIRMATIONS } from '../../constants/general.js';
import { PROFILES } from '../../data/profiles.js';

const WithUser = WrappedComponent => {
  return class extends Component {
    maybeLoadProfile = profile => {
      if (
        HIDE_CONFIRMATIONS ||
        window.confirm(
          `Confirm: load name ${
            profile.user.name
          }? Existing user and accounts will be overwritten.`
        )
      ) {
        this.props.loadProfile(profile);
      }
    };
    render() {
      return (
        <WrappedComponent
          maybeLoadProfile={this.maybeLoadProfile}
          profiles={PROFILES}
          user={this.props.user}
          {...this.props}
        />
      );
    }
  };
};

const mapStateToProps = ({ user }) => ({
  user,
});

export default compose(
  connect(
    mapStateToProps,
    {
      clearData,
      loadProfile,
    }
  ),
  WithUser
);
