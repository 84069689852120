import React from 'react';
import PropTypes from 'prop-types';

import { ButtonPrimary, ButtonSecondary } from './styled/buttons';
import { ButtonGroupContainer } from './styled/containers';

const ButtonGroup = ({
  buttonPrimaryText,
  buttonSecondaryText,
  onClickButtonPrimary,
  onClickButtonSecondary,
}) => (
  <ButtonGroupContainer>
    {buttonSecondaryText && (
      <ButtonSecondary onClick={onClickButtonSecondary}>
        {buttonSecondaryText}
      </ButtonSecondary>
    )}
    {buttonPrimaryText && (
      <ButtonPrimary onClick={onClickButtonPrimary}>
        {buttonPrimaryText}
      </ButtonPrimary>
    )}
  </ButtonGroupContainer>
);

const propTypes = {
  buttonPrimaryText: PropTypes.string,
  buttonSecondaryText: PropTypes.string,
  onClickButtonPrimary: PropTypes.func,
  onClickButtonSecondary: PropTypes.func,
};
ButtonGroup.propTypes = propTypes;

export default ButtonGroup;
