import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import ActionIntro from './ActionIntro';
import ActionTasks from './ActionTasks';
import AnimationFadeRoute from '../AnimationFadeRoute';
import Breadcrumbs from '../Breadcrumbs';
import WithAction from '../hoc/WithAction';
import WithAnimation from '../hoc/WithAnimation';

import { ContentWidth, Paper } from '../styled/containers';

import { TRANSITION } from '../../constants/general.js';
const { MD_DURATION } = TRANSITION;

class Action extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ActiveSlide: this.getAppropriateActionSlideComponent(),
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search !== prevProps.location.search) {
      this.setState({
        ActiveSlide: this.getAppropriateActionSlideComponent(),
      });
    }
  }
  getAppropriateActionSlideComponent() {
    return this.props.location.search.includes('show-intro')
      ? ActionIntro
      : ActionTasks;
  }
  exit() {
    const {
      history,
      location: { search },
      match: {
        params: { moduleSlug },
      },
      setIsPaperIn,
      setIsRouteIn,
    } = this.props;

    setIsPaperIn(false);

    if (search.includes('from-home')) {
      setIsRouteIn(false);
    }

    setTimeout(() => {
      if (search.includes('task')) {
        history.push(`/secure/${moduleSlug}`);
      } else {
        history.goBack();
      }
    }, MD_DURATION);
  }
  advanceSlide() {
    const {
      history,
      location: { pathname },
      setIsPaperIn,
    } = this.props;

    setIsPaperIn(false);

    setTimeout(() => {
      // only 'intro' uses advanceSlide(), and this will remove `?show-intro`
      history.push(pathname);
    }, MD_DURATION);
  }
  render() {
    const {
      props: {
        action,
        module,
        match: {
          params: { actionSlug, moduleSlug },
        },
      },
      state: { ActiveSlide },
    } = this;

    return (
      <AnimationFadeRoute>
        <ContentWidth wide paddingTop="lg2">
          <Breadcrumbs
            actionName={action.name}
            moduleName={module.name}
            moduleSlug={moduleSlug}
          />

          <Paper>
            <ActiveSlide
              action={action}
              actionSlug={actionSlug}
              exit={this.exit.bind(this)}
              moduleSlug={moduleSlug}
              advanceSlide={this.advanceSlide.bind(this)}
            />
          </Paper>
        </ContentWidth>
      </AnimationFadeRoute>
    );
  }
}

const propTypes = {
  action: PropTypes.shape({
    name: PropTypes.string.isRequired,
    groups: PropTypes.array,
    tasks: PropTypes.array,
  }),
  module: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  setIsPaperIn: PropTypes.func.isRequired,
  setIsRouteIn: PropTypes.func.isRequired,
};
Action.propTypes = propTypes;

export default compose(
  WithAction,
  WithAnimation
)(Action);
