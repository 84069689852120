import { css } from 'styled-components';

/*
 * type
 */
export const fontSizer = (baseSize, multiplier, breakpoints) => `
      font-size: ${baseSize}px;

      @media (min-width: ${breakpoints.sm}px) {
        font-size: ${baseSize * multiplier.sm}px;
      }
      @media (min-width: ${breakpoints.md}px) {
        font-size: ${baseSize * multiplier.md}px;
      }
      @media (min-width: ${breakpoints.lg}px) {
        font-size: ${baseSize * multiplier.lg}px;
      }
      @media (min-width: ${breakpoints.xl}px) {
        font-size: ${baseSize * multiplier.xl}px;
      }
      @media (min-width: ${breakpoints.xxl}px) {
        font-size: ${baseSize * multiplier.xxl}px;
      }
    `;

/*
 * transitions
 */
export const transitionXs = css`
  transition: all ${props => props.theme.transition.duration.xs}
    ${props => props.theme.transition.easing.default};
`;
export const transitionSm = css`
  transition: all ${props => props.theme.transition.duration.sm}
    ${props => props.theme.transition.easing.default};
`;
export const transitionMdSCurve = css`
  transition: all ${props => props.theme.transition.duration.md}
    ${props => props.theme.transition.easing.sCurve};
`;
export const transitionMd = css`
  transition: ${props => (!props.noAnimation ? 'all' : 'none')}
    ${props => props.theme.transition.duration.md}
    ${props => props.theme.transition.easing.default};
`;
export const transitionLg = css`
  transition: all ${props => props.theme.transition.duration.lg}
    ${props => props.theme.transition.easing.default};
`;
export const transitionXl = css`
  transition: all ${props => props.theme.transition.duration.xl}
    ${props => props.theme.transition.easing.default};
`;
export const transitionOpacity = css`
  ${transitionXs};
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`;

/*
 * other
 */
export const bgGradient = css`
  background: linear-gradient(
    to right,
    ${props => props.theme.colors.special.blueGradient1},
    ${props => props.theme.colors.special.blueGradient2}
  );
`;

export const flexRowSpaceBetweenCenter = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const verticalAlign = css`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;

export const overloadedButton = css`
  flex: 1;
  height: ${props => props.theme.hardCodedSizes.buttonHeight}px;
  line-height: ${props => props.theme.hardCodedSizes.buttonHeight}px;
  margin-right: ${props => props.theme.spacing.horizontal.xs};
  padding: 0 24px;
  font-size: ${props => props.theme.type.size.md}px;
  font-family: ${props => props.theme.type.family.heading};
  color: ${props => props.theme.colors.gray4};
  font-weight: 500;
  letter-spacing: ${props => props.theme.type.letterSpacing.wide};
  white-space: nowrap;
  text-align: ${props => props.textalign && props.textalign};
  background: ${props =>
    props.background ? props.background : props.theme.colors.grayFadeAlt};
  border-radius: ${props => props.theme.hardCodedSizes.buttonHeight / 2}px;
`;
