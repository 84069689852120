import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { addProgressEntry } from '../../actions';

import ReactSVG from 'react-svg';

import times from 'lodash/times';
import uuid from 'uuid';

import AnimationFadeRoute from '../AnimationFadeRoute';
import Breadcrumbs from '../Breadcrumbs';
import ButtonGroup from '../ButtonGroup';
import SlideConfirm from '../task/SlideConfirm';
import SlideIntro from '../task/SlideIntro';
import SlideStepTypeLink from '../task/SlideStepTypeLink';
import SlideStepTypeLinkTable from '../task/SlideStepTypeLinkTable';
import SlideStepTypeTable from '../task/SlideStepTypeTable';
import WithAccounts from '../hoc/WithAccounts';
import WithAction from '../hoc/WithAction';
import WithAnimation from '../hoc/WithAnimation';

import { AnimationFade } from '../styled/animation';
import { ContentWidth, Paper } from '../styled/containers';
import { Dot, DotContainer } from '../styled/task';
import {
  InitializeAnimationFadeDelay1,
  InitializeAnimationFadeDelay3,
} from '../styled/animation';

import { ASSET_PATH } from '../../constants/general.js';
import { TRANSITION } from '../../constants/general.js';
const { MD_DURATION } = TRANSITION;

/*
 * The current slide is maintained by Task's state, `activeSlideIndex`, and
 * `ActiveSlideComponent`. The `activeSlideIndex` is what really keeps up with the
 * current/active slide, and then the `ActiveSlideComponent` is what determines
 * which component will render.
 *
 * When a component asks to `advanceSlide`, it's actually asking to update the
 * parameter in the route (see App.js routes that match for `component={Task}`).
 *
 * Both on initial `componentDidMount` and `componentWillUpdate`, the controller
 * logic will update/set the new current/active slide using `gotoSlide`.
 */

class Task extends Component {
  constructor(props) {
    super(props);

    const {
      addAccounts,
      match: {
        params: { slideIndex: slideIndexParam },
      },
      steps,
      task,
    } = this.props;

    const slideIndex = parseInt(slideIndexParam) || 0;
    const numberOfSlides = 1 + steps.length + 1;

    // total # of slides = introSlide + number of steps + confirmSlide
    this.state = {
      ActiveSlideComponent: this.getAppropriateTaskSlideComponent(
        slideIndex,
        numberOfSlides
      ),
      activeSlideIndex: slideIndex || 0,
      numberOfSlides,
    };

    if (task.accountsToAdd && task.accountsToAdd.length) {
      addAccounts(task.accountsToAdd);
    }

    this.boundAdvanceSlide = this.advanceSlide.bind(this);
    this.boundDotClick = this.dotClick.bind(this);
    this.boundPreviousSlide = this.previousSlide.bind(this);
  }
  getAppropriateTaskSlideComponent(slideIndex, numberOfSlides) {
    const { steps } = this.props;

    /*
     * The strategy: match 'confirm" first, then any middle steps second (index
     * will be greater than 0 and less than total), then everything else falls
     * back to 'intro'.
     */
    if (slideIndex === numberOfSlides - 1) {
      return SlideConfirm;
    } else if (slideIndex > 0 && slideIndex < numberOfSlides - 1) {
      const stepType = steps[slideIndex - 1].stepType;

      switch (stepType) {
        case 'table':
          return SlideStepTypeTable;
        case 'link':
          return SlideStepTypeLink;
        case 'link-table':
          return SlideStepTypeLinkTable;
        default:
          return SlideStepTypeLink;
      }
    } else {
      return SlideIntro;
    }
  }
  componentDidMount() {
    this.updateSlideFromParamChange(
      parseInt(this.props.match.params.slideIndex) || 0
    );
  }
  componentDidUpdate(prevProps, prevState) {
    this.updateSlideFromParamChange(
      parseInt(this.props.match.params.slideIndex) || 0
    );
  }
  updateSlideFromParamChange(slideIndex) {
    const {
      state: { activeSlideIndex, numberOfSlides },
    } = this;

    if (slideIndex === activeSlideIndex) {
      return;
    }

    if (slideIndex > 0 && slideIndex <= numberOfSlides - 1) {
      this.gotoSlide(
        slideIndex,
        this.getAppropriateTaskSlideComponent(slideIndex, numberOfSlides)
      );
    } else {
      this.gotoSlide(0, SlideIntro);
    }
  }
  gotoSlide(slideIndex, slideComponent) {
    if (slideIndex === this.state.activeSlideIndex) {
      return;
    }
    this.setState({
      activeSlideIndex: slideIndex,
      ActiveSlideComponent: slideComponent,
    });
  }
  advanceSlide() {
    const {
      props: {
        history,
        match: {
          params: { actionSlug, moduleSlug, taskSlug },
        },
        setIsPaperIn,
        setIsSlideIn,
      },
      state: { activeSlideIndex, numberOfSlides },
    } = this;

    // user is advancing to SlideConfirm
    if (activeSlideIndex + 1 === numberOfSlides - 1) {
      this.completeTask();
    }

    if (activeSlideIndex + 1 >= numberOfSlides) {
      // exit Task altogether

      setIsPaperIn(false);

      setTimeout(() => {
        history.push(`/secure/${moduleSlug}/${actionSlug}?task-completed`);
      }, MD_DURATION);
    } else {
      // advance to next Task Slide

      setIsSlideIn(false);

      setTimeout(() => {
        // this is verbose to cover edge case of 'no trailing slash' (at intro)
        history.push(
          `/secure/${moduleSlug}/${actionSlug}/${taskSlug}/${activeSlideIndex +
            1}`
        );
      }, MD_DURATION);
    }
  }
  previousSlide() {
    const {
      props: {
        history,
        match: {
          params: { actionSlug, moduleSlug },
        },
        setIsPaperIn,
        setIsSlideIn,
      },
      state: { activeSlideIndex },
    } = this;

    if (activeSlideIndex - 1 < 0) {
      setIsPaperIn(false);

      setTimeout(() => {
        history.push(`/secure/${moduleSlug}/${actionSlug}?exited-task`);
      }, MD_DURATION);
    } else {
      setIsSlideIn(false);

      setTimeout(() => {
        history.push(`${activeSlideIndex - 1}`);
      }, MD_DURATION);
    }
  }
  completeTask() {
    const { actionSlug, moduleSlug, taskSlug } = this.props.match.params;
    this.props.addProgressEntry({
      actionSlug,
      isCompleted: true,
      moduleSlug,
      taskSlug,
    });
  }
  dotClick = i => {
    const {
      props: { history, setIsSlideIn },
      state: { activeSlideIndex },
    } = this;
    if (i !== activeSlideIndex) {
      setIsSlideIn(false);

      setTimeout(() => {
        history.push(`${i}`);
      }, MD_DURATION);
    }
  };
  render() {
    const {
      props: {
        accounts,
        action,
        animation: { isSlideIn },
        match: {
          params: { actionSlug, moduleSlug },
        },
        module,
        steps,
        task,
      },
      state: { ActiveSlideComponent, activeSlideIndex, numberOfSlides },
    } = this;

    const currentStep = steps[activeSlideIndex - 1] || {};

    const isFinalSlide = activeSlideIndex >= numberOfSlides - 1;
    let buttonPrimaryText;
    let buttonSecondaryText;

    if (isFinalSlide) {
      buttonSecondaryText = 'Back';
      buttonPrimaryText = 'Done';
    } else if (activeSlideIndex === 0) {
      buttonSecondaryText = 'Not Now';
      buttonPrimaryText = 'Get Started';
    } else {
      buttonSecondaryText = 'Back';
      buttonPrimaryText = 'Next';
    }

    return (
      <AnimationFadeRoute>
        <ContentWidth wide paddingTop="lg2">
          <Breadcrumbs
            actionName={action.name}
            actionSlug={actionSlug}
            moduleName={module.name}
            moduleSlug={moduleSlug}
            taskName={task.name}
          />

          <Paper wide={activeSlideIndex > 0}>
            <InitializeAnimationFadeDelay1>
              <DotContainer>
                {times(numberOfSlides, i => (
                  <Dot
                    key={uuid()}
                    active={i - 1 < activeSlideIndex}
                    onClick={() => this.boundDotClick(i)}>
                    <ReactSVG src={`${ASSET_PATH}/svg/lock-no-keyhole.svg`} />
                  </Dot>
                ))}
              </DotContainer>
            </InitializeAnimationFadeDelay1>

            <AnimationFade isIn={isSlideIn}>
              <ActiveSlideComponent
                accounts={accounts}
                actionName={action.name}
                step={currentStep}
                task={task}
              />
            </AnimationFade>

            <InitializeAnimationFadeDelay3>
              <ButtonGroup
                buttonSecondaryText={buttonSecondaryText}
                buttonPrimaryText={buttonPrimaryText}
                onClickButtonSecondary={this.boundPreviousSlide}
                onClickButtonPrimary={this.boundAdvanceSlide}
              />
            </InitializeAnimationFadeDelay3>
          </Paper>
        </ContentWidth>
      </AnimationFadeRoute>
    );
  }
}

const propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      groups: PropTypes.array,
      name: PropTypes.string.isRequired,
      tasks: PropTypes.array,
    })
  ),
  action: PropTypes.shape({
    groups: PropTypes.array,
    name: PropTypes.string.isRequired,
    tasks: PropTypes.array,
  }),
  addAccounts: PropTypes.func.isRequired,
  animation: PropTypes.shape({
    isSlideIn: PropTypes.bool.isRequired,
  }),
  module: PropTypes.shape({
    actions: PropTypes.string.array,
    copy: PropTypes.string.isRequired,
    introCopy: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  setIsPaperIn: PropTypes.func.isRequired,
  setIsSlideIn: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  task: PropTypes.object.isRequired,
};
Task.propTypes = propTypes;

export default compose(
  WithAction,
  WithAccounts,
  WithAnimation,
  connect(
    null,
    { addProgressEntry }
  )
)(Task);
