import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import DevAccountTable from './DevAccountTable';
import ComponentsList from './ComponentsList';
import Modal from '../Modal';
import ModuleList from './ModuleList';
import ResourceRequestTest from './ResourceRequestTest';
import WithAccounts from '../hoc/WithAccounts';
import WithMasterModuleList from '../hoc/WithMasterModuleList';
import WithUi from '../hoc/WithUi';
import WithUser from '../hoc/WithUser';

import { ContentWidth, Paper, PaperContentWidth } from '../styled/containers';
import { H1, H2, LinkBasic, P } from '../styled/type';
import { DevStyleOverrides, Section } from '../styled/dev';

import { MASTER_MODULE_LIST } from '../../data/masterModuleList.js';

class Dev extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    const {
      accounts,
      clearData,
      maybeLoadProfile,
      profiles,
      showModal,
      user: { email, name },
    } = this.props;
    return (
      <ContentWidth wide paddingTop="xl">
        <Paper>
          <PaperContentWidth wide paddingTop="xl">
            <DevStyleOverrides>
              <H1>Dev</H1>

              <Section>
                <H2>Modal</H2>
                <button onClick={() => showModal()}>show modal</button>

                <Modal>
                  <P>lorem ipsum</P>
                </Modal>
              </Section>

              <Section>
                <H2>User</H2>
                {name ? (
                  <ul>
                    <li>Name: {name}</li>
                    <li>Email: {email}</li>
                  </ul>
                ) : (
                  <p>not logged in</p>
                )}
              </Section>

              <Section>
                <H2>Load Profile</H2>

                <ul>
                  {profiles.map(profile => {
                    return (
                      <li key={profile.user.name}>
                        <LinkBasic
                          as="span"
                          onClick={() => maybeLoadProfile(profile)}>
                          {profile.user.name}
                        </LinkBasic>
                      </li>
                    );
                  })}
                </ul>
              </Section>

              <Section>
                <H2>Logout & Remove Accounts</H2>
                <button onClick={() => clearData()}>clear data</button>
              </Section>

              <Section>
                <H2>Accounts</H2>
                {accounts.length > 0 ? (
                  <DevAccountTable
                    accounts={accounts}
                    showDeleteButtonPrimary
                  />
                ) : (
                  <p>no accounts</p>
                )}
              </Section>

              <Section>
                <H2>Modules → Actions</H2>
                {MASTER_MODULE_LIST.map(module => (
                  <ModuleList
                    key={module.name}
                    module={module}
                    accounts={accounts}
                  />
                ))}
              </Section>

              <Section>
                <H2>Modules → Actions → Tasks + Matched Accounts</H2>
                {MASTER_MODULE_LIST.map(module => (
                  <ModuleList
                    showMatchingAccounts
                    key={module.name}
                    module={module}
                    accounts={accounts}
                  />
                ))}
              </Section>

              <ComponentsList />

              <ResourceRequestTest />
            </DevStyleOverrides>
          </PaperContentWidth>
        </Paper>
      </ContentWidth>
    );
  }
}

const propTypes = {
  maybeLoadProfile: PropTypes.func.isRequired,
  profiles: PropTypes.array.isRequired,
};
Dev.propTypes = propTypes;

export default compose(
  WithAccounts,
  WithMasterModuleList,
  WithUi,
  WithUser
)(Dev);
