import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ReactSVG from 'react-svg';

import ButtonGroup from '../ButtonGroup';

import {
  FlexRow,
  FlexRowSVGContainer,
  Left,
  PaperContentWidth,
  Right,
} from '../styled/containers';
import { H1, P } from '../styled/type';
import {
  InitializeAnimationFadeDelay1,
  InitializeAnimationFadeDelay2,
  InitializeAnimationFadeDelay3,
} from '../styled/animation';

import { FALLBACK_SVG_ACTION_INTRO } from '../../constants/general.js';

const ActionIntro = ({
  action: { icon, introCopy, name },
  advanceSlide,
  exit,
  moduleSlug,
}) => (
  <Fragment>
    <PaperContentWidth paddingTop="xxl">
      <FlexRow>
        <Left>
          <InitializeAnimationFadeDelay1>
            <FlexRowSVGContainer>
              <ReactSVG src={icon ? icon : FALLBACK_SVG_ACTION_INTRO} />
            </FlexRowSVGContainer>
          </InitializeAnimationFadeDelay1>
        </Left>
        <Right>
          <InitializeAnimationFadeDelay2>
            <H1>{name}</H1>
            <P>{introCopy}</P>
          </InitializeAnimationFadeDelay2>
        </Right>
      </FlexRow>
    </PaperContentWidth>

    <InitializeAnimationFadeDelay3>
      <ButtonGroup
        buttonSecondaryText="Not Now"
        buttonPrimaryText="Get Started"
        onClickButtonSecondary={exit}
        onClickButtonPrimary={advanceSlide}
      />
    </InitializeAnimationFadeDelay3>
  </Fragment>
);

const propTypes = {
  action: PropTypes.shape({
    icon: PropTypes.string,
    introCopy: PropTypes.string,
    name: PropTypes.string,
  }),
  advanceSlide: PropTypes.func.isRequired,
  exit: PropTypes.func.isRequired,
  moduleSlug: PropTypes.string.isRequired,
};
ActionIntro.propTypes = propTypes;

export default ActionIntro;
