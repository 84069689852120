import React from 'react';
import PropTypes from 'prop-types';

import ReactSVG from 'react-svg';

import AnimationLinkPaper from '../AnimationLinkPaper';

import styled, { css } from 'styled-components';
import { H6 } from './type';
import { LeftArrow, RightArrow } from './icons';
import { transitionXs, transitionOpacity } from './mixins';

export const Button = styled.button.attrs({
  color: props =>
    props.normal ? props.theme.colors[props.normal.color] : 'whiteAlt',
  colorhover: props =>
    props.hover ? props.theme.colors[props.hover.color] : 'whiteAlt',
  background: props =>
    props.normal ? props.theme.colors[props.normal.background] : 'primary',
  backgroundhover: props =>
    props.hover ? props.theme.colors[props.hover.background] : 'blueAlt',
})`
  display: inline-block;
  min-width: ${props => props.theme.hardCodedSizes.buttonMinWidth}px;
  width: ${props => props.width && props.width};
  height: ${props => props.theme.hardCodedSizes.buttonHeight}px;
  line-height: ${props => props.theme.hardCodedSizes.buttonHeight}px;
  margin-right: ${props => props.theme.spacing.horizontal.xs};
  margin-top: ${props =>
    props.topMargin ? props.theme.spacing.vertical.md : 0};
  padding: 0 24px;
  font-size: ${props => props.theme.type.size.md}px;
  font-family: ${props => props.theme.type.family.heading};
  color: ${props => props.color};
  font-weight: 500;
  text-transform: ${props => !props.regularcase && 'uppercase'};
  letter-spacing: ${props => props.theme.type.letterSpacing.wide};
  white-space: nowrap;
  text-align: ${props => props.textalign && props.textalign};
  background: ${props => props.background};
  border: 0;
  border-radius: ${props => props.theme.hardCodedSizes.buttonHeight / 2}px;
  cursor: pointer;
  ${transitionXs};

  &:hover {
    color: ${props => props.colorhover};
    background: ${props => props.backgroundhover};
  }
`;

export const ButtonPrimary = props => (
  <Button
    {...props}
    normal={{
      color: 'whiteAlt',
      background: 'primary',
    }}
    hover={{ color: 'white', background: 'primary' }}>
    {props.children}
  </Button>
);
export const ButtonSecondary = props => (
  <Button
    {...props}
    normal={{
      color: 'primary',
      background: 'white',
    }}
    hover={{ color: 'whiteAlt', background: 'primary' }}>
    {props.children}
  </Button>
);

// see 'Workarounds' in README re: `regularcase`
export const SelectionButton = props =>
  props.to ? (
    <AnimationLinkPaper to={props.to} display="block" flex="1">
      <Button
        {...props}
        normal={{
          color: 'gray4',
          background: 'grayFadeAlt',
        }}
        hover={{ color: 'white', background: 'primary' }}
        width="100%"
        regularcase={1}
        textalign="left">
        {props.children}
      </Button>
    </AnimationLinkPaper>
  ) : (
    <Button
      {...props}
      normal={{
        color: 'gray4',
        background: 'grayFadeAlt',
      }}
      hover={{ color: 'white', background: 'primary' }}
      regularcase={1}
      width="100%"
      textalign="left">
      {props.children}
    </Button>
  );

const BigButtonContainer = styled.div`
  display: inline-block;
  padding-top: ${props => props.theme.spacing.fixed.md};
  padding-left: ${props => props.theme.spacing.fixed.md};
  padding-right: ${props => props.theme.spacing.fixed.md};
  padding-bottom: ${props => props.theme.spacing.vertical.lg};
  color: ${props => props.theme.colors.gray4};
  text-align: left;
  background: ${props => props.theme.colors.grayFadeAlt};
  border-radius: 8px;
  ${transitionXs};

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primary};
  }
`;
const ButtonText = styled.span`
  display: block;
  padding-top: ${props => props.theme.spacing.vertical.sm};
  font-size: ${props =>
    props.small ? props.theme.type.size.xxs : props.theme.type.size.md}px;
  font-family: ${props => props.theme.type.family.heading};
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: ${props => props.theme.type.letterSpacing.wide};
`;
const ButtonTextAlt = styled.span`
  display: block;
`;

const BBIconContainer = styled.div`
  position: relative;
  width: 155px;
  height: 180px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  background: ${props => props.theme.colors.white};

  // target nested divs created by react-svg
  div {
    height: 100%;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const BigButton = ({ icon, shortName, tagline }) => (
  <BigButtonContainer>
    {icon && (
      <BBIconContainer>
        <ReactSVG src={icon} />
      </BBIconContainer>
    )}
    <ButtonText>{tagline}</ButtonText>
    <ButtonTextAlt>{shortName}</ButtonTextAlt>
  </BigButtonContainer>
);
const bigButtonPropTypes = {
  icon: PropTypes.string,
  shortName: PropTypes.string,
  tagline: PropTypes.string,
};
BigButton.propTypes = bigButtonPropTypes;

const PlusAddButtonContainer = styled.span`
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
  overflow: hidden;

  h6 {
    margin-top: -1em;
  }
`;
const Plus = styled.span`
  color: ${props => props.theme.colors.gray9};
  font-size: 28px;
  font-weight: 300;
  line-height: 0;
  cursor: pointer;
`;
export const PlusAddButton = props => (
  <PlusAddButtonContainer {...props}>
    <Plus>+</Plus>
    <H6>add</H6>
  </PlusAddButtonContainer>
);

const arrowHitArea = css`
  position: relative;
  top: -20px;
  width: 40px;
  height: 40px;
  padding: 34px 35px 17px 49px;
  cursor: pointer;
  ${transitionOpacity};
  z-index: ${props => props.theme.zIndex.one};
  pointer-events: ${props => props.isDisabled && 'none'};
  opacity: ${props => props.isDisabled && '0 !important'};
`;
const ArrowHitAreaLeft = styled.div`
  ${arrowHitArea};
  left: -60px;
`;
const ArrowHitAreaRight = styled.div`
  ${arrowHitArea};
  right: -60px;
`;
export const ArrowButtonLeft = props => (
  <ArrowHitAreaLeft {...props}>
    <LeftArrow color="white" />
  </ArrowHitAreaLeft>
);
export const ArrowButtonRight = props => (
  <ArrowHitAreaRight {...props}>
    <RightArrow color="white" />
  </ArrowHitAreaRight>
);
