import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { findAccountsByGroups } from '../../utilities';

import Spiral from '../visualizations/Spiral';
import WithAccounts from '../hoc/WithAccounts';
import WithProgress from '../hoc/WithProgress';
import WithUi from '../hoc/WithUi';

import {
  Flex,
  FlexRow,
  PaperContentWidth,
  SelectionButtonContainer,
} from '../styled/containers';
import { H1, P } from '../styled/type';
import { LockIcon } from '../styled/icons';
import { TaskListing } from '../styled/task';
import {
  InitializeAnimationFadeDelay1,
  InitializeAnimationFadeDelay2,
  InitializeAnimationFadeDelay4,
} from '../styled/animation';

class SlideConfirm extends Component {
  render() {
    const {
      props: {
        accounts,
        actionName,
        task: { confirmCopy, groups, name },
      },
    } = this;

    return (
      <PaperContentWidth wide>
        <FlexRow>
          <Flex paddingRight="xl" paddingTop="xxl">
            <InitializeAnimationFadeDelay1>
              <H1>{confirmCopy}</H1>
              <P>{actionName}</P>
            </InitializeAnimationFadeDelay1>

            <InitializeAnimationFadeDelay2>
              <SelectionButtonContainer key={name}>
                <LockIcon paddingRight="sm" large isComplete={true} />
                <TaskListing background="transparent">{name}</TaskListing>
              </SelectionButtonContainer>
            </InitializeAnimationFadeDelay2>
          </Flex>
          <Flex paddingTop="xl">
            <InitializeAnimationFadeDelay4>
              <Spiral
                calculateOverlay={() => {}}
                dataset={accounts}
                launchModal={() => {}}
                overlay={{
                  isEnabled: true,
                  activeAccounts: findAccountsByGroups(accounts, groups),
                }}
              />
            </InitializeAnimationFadeDelay4>
          </Flex>
        </FlexRow>
      </PaperContentWidth>
    );
  }
}

const propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      groups: PropTypes.array,
      tasks: PropTypes.array,
    })
  ),
  actionName: PropTypes.string.isRequired,
  progress: PropTypes.array,
  task: PropTypes.shape({
    name: PropTypes.string.isRequired,
    confirmCopy: PropTypes.string,
  }),
};
SlideConfirm.propTypes = propTypes;

export default compose(
  WithAccounts,
  WithProgress,
  WithUi
)(SlideConfirm);
