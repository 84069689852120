import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  setAnimationsIn,
  setIsInitialLoadIn,
  setIsPaperIn,
  setIsRouteIn,
  setIsSlideIn,
} from '../../actions';

const WithAnimation = WrappedComponent => {
  return class extends Component {
    render() {
      return (
        <WrappedComponent animation={this.props.animation} {...this.props} />
      );
    }
  };
};

const mapStateToProps = ({ animation }) => ({
  animation,
});
export default compose(
  connect(
    mapStateToProps,
    {
      setAnimationsIn,
      setIsInitialLoadIn,
      setIsPaperIn,
      setIsRouteIn,
      setIsSlideIn,
    }
  ),
  WithAnimation
);
