import React from 'react';

import styled from 'styled-components';

// Home
export const HomeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: stretch;
`;

export const HomeCenter = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.breakpoints.md}px) {
    display: block;
    flex: 2;
    text-align: center;
  }
  @media (min-width: ${props => props.theme.breakpoints.lg}px) {
    flex: 2;
  }
  @media (min-width: ${props => props.theme.breakpoints.xl}px) {
    flex: 3;
  }
`;

export const HomeLeft = styled.div`
  flex: 2;
`;

const HomeRightContainer = styled.div`
  flex: 1;
`;
const HomeRightInner = styled.div``;
// make the right column slightly narrower, and move it over to the right
export const HomeRight = props => (
  <HomeRightContainer>
    <HomeRightInner>{props.children}</HomeRightInner>
  </HomeRightContainer>
);

export const HomeModalHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const HomeModalHeadingLeft = styled.div`
  float: left;
  width: 70%;
`;
export const HomeModalHeadingRight = styled.div`
  float: right;
  width: 30%;
  text-align: right;
`;

export const HomeModalModuleHeading = styled.div`
  width: 25%;
`;

export const HomeModalModuleContent = styled.div`
  width: 75%;
`;

export const HomeModalModuleGroup = styled.div`
  padding: ${props => props.theme.spacing.vertical.sm2};
  border: 1px solid ${props => props.theme.colors.grayFade};

  & + & {
    margin-top: ${props => props.theme.spacing.vertical.md};
  }
`;

export const HomeModalActionsGroup = styled.div`
  margin-left: ${props => props.theme.spacing.vertical.lg};
  justify-self: flex-end;
`;

export const HomeModalActionBody = styled.div`
  margin-top: ${props => props.theme.spacing.vertical.sm};
`;
