import {
  protectYourAccountsActions,
  protectYourCreditActions,
  secureYourCriticalDataActions,
  secureYourFinancesActions,
} from './masterActionList';

import { ASSET_PATH } from '../constants/general.js';

export const MASTER_MODULE_LIST = [
  {
    name: 'Secure your finances',
    introCopy:
      'If you want to minimize risks to your most sensitive data, a good place to start is with your financial relationships, because financial fraud can have both online and real-world consequences.',
    copy: 'How would you like to secure your finances today?',
    icon: `${ASSET_PATH}/svg/01-ModuleIntro.svg`,
    actions: secureYourFinancesActions,
  },
  {
    name: 'Protect your accounts',
    introCopy:
      'One of the most important ways you can keep your data out of hackers’ hands is to make sure you’re using established best practices around how you log in to your accounts. Whether it’s creating strong passwords, learning smart techniques with security questions, and using failsafe login techniques will keep your accounts safer. And, if one of your accounts is breached, your other accounts will be less vulnerable.',
    copy: 'Here are the actions.',
    actions: protectYourAccountsActions,
  },
  {
    name: 'Secure your critical data',
    introCopy:
      'Have you ever Googled yourself? If you have, you’ve probably noticed that there’s a lot of information about you that’s publicly available on the web. But what you can find about yourself online is only the tip of the iceberg. There are many ways for your data to get into the wrong hands, from viruses to data breaches to lax privacy settings on social media. We’ll show you how to clean it up - and lock down your data.',
    copy: 'Here are the actions.',
    actions: secureYourCriticalDataActions,
  },
  {
    name: 'Protect your credit',
    introCopy:
      'Protecting your credit from identity thieves is crucial to your financial well being; problems with stolen credit can be costly and take years to clear up. Monitoring your credit by getting a free annual credit report is an easy place to start. And freezing your credit is the most failsafe way to make sure vital information isn’t leaking away or being stolen.',
    copy: 'Here are the actions.',
    actions: protectYourCreditActions,
  },
];
