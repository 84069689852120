import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { checkIfCompleted, slugify } from '../../utilities';

import AnimationLinkPaper from '../AnimationLinkPaper';
import WithProgress from '../hoc/WithProgress';

import { BigButton } from '../styled/buttons';
import {
  Center,
  ModuleActionContainer,
  ModuleActionsProgressContainer,
  NumContainer,
  PaperContentWidth,
  ProgressContainer,
} from '../styled/containers';
import { LockIcon } from '../styled/icons';
import { NumOfNum, P } from '../styled/type';
import { ProgressIndicator } from '../styled/accordion';
import {
  InitializeAnimationFadeDelay1,
  InitializeAnimationFadeDelay2,
  InitializeAnimationFadeDelay3,
} from '../styled/animation';

import { FALLBACK_SVG_ACTION } from '../../constants/general.js';

const ModuleActions = ({
  advanceSlide,
  module: { actions, copy, name },
  moduleSlug,
  progress,
}) => {
  const numberOfCompletedTasks =
    actions.flatMap(({ tasks }) =>
      tasks.filter(({ name }) => checkIfCompleted(slugify(name), progress))
    ).length || 0;

  const totalNumberOfTasks = actions.reduce(
    (total, { tasks }) => total + tasks.length,
    0
  );

  return (
    <PaperContentWidth paddingTop="lg">
      <InitializeAnimationFadeDelay1>
        <P centered bold size="bodyLarge">
          {copy}
        </P>
      </InitializeAnimationFadeDelay1>

      <InitializeAnimationFadeDelay3>
        <ModuleActionsProgressContainer>
          <ProgressContainer>
            <ProgressIndicator
              numberCompleted={numberOfCompletedTasks}
              total={totalNumberOfTasks}
            />
          </ProgressContainer>
          <NumContainer>
            <NumOfNum small>
              <span>{numberOfCompletedTasks}</span> of{' '}
              <span>{totalNumberOfTasks}</span>
            </NumOfNum>
          </NumContainer>
        </ModuleActionsProgressContainer>
      </InitializeAnimationFadeDelay3>

      <InitializeAnimationFadeDelay2>
        <Center>
          {actions.map(({ name, icon, shortName, tagline, tasks }) => (
            <ModuleActionContainer key={name}>
              <Fragment>
                <AnimationLinkPaper
                  to={`/secure/${moduleSlug}/${slugify(name)}?show-intro`}>
                  <BigButton
                    icon={icon ? icon : FALLBACK_SVG_ACTION}
                    tagline={tagline}
                    shortName={shortName}
                  />
                </AnimationLinkPaper>
                <Center marginTop="md">
                  {tasks.map(task => (
                    <LockIcon
                      key={task.name}
                      isComplete={checkIfCompleted(
                        slugify(task.name),
                        progress
                      )}
                      large
                    />
                  ))}
                </Center>
              </Fragment>
            </ModuleActionContainer>
          ))}
        </Center>
      </InitializeAnimationFadeDelay2>
    </PaperContentWidth>
  );
};

const propTypes = {
  advanceSlide: PropTypes.func.isRequired,
  module: PropTypes.shape({
    actions: PropTypes.array.isRequired,
    copy: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.string,
  }),
  moduleSlug: PropTypes.string.isRequired,
  progress: PropTypes.array.isRequired,
};
ModuleActions.propTypes = propTypes;

export default WithProgress(ModuleActions);
