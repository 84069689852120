import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import uuid from 'uuid';

import { findAccountInAccountsByName } from '../../utilities';

import Accordion from '../Accordion';
import { DisplayStateLink } from '../styled/type';

import { ACCOUNT_CATEGORIES } from '../../data/accountCategories';

class HomeAccountAccordions extends Component {
  render() {
    const { accounts, calculateOverlay, launchModal, overlay } = this.props;
    return (
      <Fragment>
        {ACCOUNT_CATEGORIES.map(({ name, icon }) => {
          const categorizedAccounts = accounts.filter(
            account => account.category === name
          );
          return (
            <Accordion
              key={name}
              heading={name}
              icon={icon}
              addLink={name !== 'other' ? `/discovery/${name}` : null}
              startExpanded={!!categorizedAccounts.length}
              body={
                categorizedAccounts.length ? (
                  categorizedAccounts.map((account, i) => {
                    const isActive = findAccountInAccountsByName(
                      account.name,
                      overlay.activeAccounts
                    );
                    return (
                      <div key={uuid()}>
                        <DisplayStateLink
                          highlight={isActive}
                          mute={overlay.isEnabled}
                          onClick={() => launchModal(account)}
                          onMouseEnter={() => calculateOverlay(true, account)}
                          onMouseLeave={() => calculateOverlay(false)}>
                          {account.name}
                        </DisplayStateLink>
                      </div>
                    );
                  })
                ) : (
                  <div />
                )
              }
            />
          );
        })}
      </Fragment>
    );
  }
}

const propTypes = {
  accounts: PropTypes.array.isRequired,
  calculateOverlay: PropTypes.func.isRequired,
  launchModal: PropTypes.func.isRequired,
  overlay: PropTypes.object,
};
HomeAccountAccordions.propTypes = propTypes;

export default HomeAccountAccordions;
