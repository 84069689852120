import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { BrowserRouter } from 'react-router-dom';

import throttle from 'lodash/throttle';

import rootReducer from './reducers';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { loadState, saveState } from './utilities/localStorage';

import { ThemeProvider } from 'styled-components';
import theme from './components/styled/theme';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedState = loadState();
const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(thunk))
);

store.subscribe(
  throttle(() => {
    const { animation, ui, ...selectedState } = store.getState();
    saveState(selectedState);
  }, 1000)
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter
      basename={
        process.env.REACT_APP_DEPLOYMENT_SUBDIRECTORY
          ? process.env.REACT_APP_DEPLOYMENT_SUBDIRECTORY
          : ''
      }>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
