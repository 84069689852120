export const ACCOUNT_LOOKUP_LIST = [
  {
    name: 'Chase Bank',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://www.chase.com',
  },
  {
    name: 'Bank of America',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://www.bankofamerica.com',
  },
  {
    name: 'Capital One',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://www.capitalone.com',
  },
  {
    name: 'Citibank',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://citi.com',
  },
  {
    name: 'American Express',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://americanexpress.com',
  },
  {
    name: 'Barclays',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://home.barclays',
  },
  {
    name: 'US Bank',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://www.usbank.com',
  },
  {
    name: 'Wells Fargo',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://wellsfargo.com',
  },
  {
    name: 'USAA',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://usaa.com',
  },
  {
    name: 'KeyBank',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://key.com',
  },
  {
    name: 'NavyFederal',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://navyfederal.com',
  },
  {
    name: 'Fifth Third Bank',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://53.com',
  },
  {
    name: 'BB&T',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://bbt.com',
  },
  {
    name: 'SunTrust Bank',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://suntrust.com',
  },
  {
    name: 'Citizens Bank',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://citizensbank.com',
  },
  {
    name: 'BBVA Compass',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://bbvacompass.com',
  },
  {
    name: 'PNC Bank',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://pnc.com',
  },
  {
    name: 'TD Bank',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://td.com',
  },
  {
    name: 'Regions Bank',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://regions.com',
  },
  {
    name: 'Huntington Bank',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://www.huntington.com',
  },
  {
    name: 'Discover',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://discover.com',
  },
  {
    name: 'Goldman Sachs',
    category: 'financial',
    groups: ['financial', 'banks'],
    url: 'https://www.goldmansachs.com',
  },
  {
    name: 'PayPal',
    category: 'financial',
    groups: ['financial', 'payments'],
    url: 'https://www.paypal.com',
  },
  {
    name: 'Doxo',
    category: 'financial',
    groups: ['financial', 'payments'],
    url: 'https://doxo.com',
  },
  {
    name: 'Square',
    category: 'financial',
    groups: ['financial', 'payments'],
    url: 'https://squareup.com',
  },
  {
    name: 'Venmo',
    category: 'financial',
    groups: ['financial', 'payments'],
    url: 'https://venmo.com',
  },
  {
    name: 'Dwolla',
    category: 'financial',
    groups: ['financial', 'payments'],
    url: 'https://dwolla.com',
  },
  {
    name: 'Braintree',
    category: 'financial',
    groups: ['financial', 'payments'],
    url: 'https://braintreepayments.com',
  },
  {
    name: 'Shopify',
    category: 'financial',
    groups: ['financial', 'payments', 'exposed'],
    url: 'https://shopify.com',
  },
  {
    name: 'QuickBooks Payments',
    category: 'financial',
    groups: ['financial', 'payments', 'exposed'],
    url: 'https://quickbooks.intuit.com/payments',
  },
  {
    name: 'John Hancock Retirement',
    category: 'financial',
    groups: ['financial', 'investments'],
    url: 'https://johnhancock.com',
  },
  {
    name: 'Fidelity',
    category: 'financial',
    groups: ['financial', 'investments'],
    url: 'https://fidelity.com',
  },
  {
    name: 'Charles Schwab',
    category: 'financial',
    groups: ['financial', 'investments'],
    url: 'https://schwab.com',
  },
  {
    name: 'Vanguard',
    category: 'financial',
    groups: ['financial', 'investments'],
    url: 'https://investor.vanguard.com',
  },
  {
    name: 't.rowe price',
    category: 'financial',
    groups: ['financial', 'investments'],
    url: 'https://troweprice.com',
  },
  {
    name: 'TDAmeritrade',
    category: 'financial',
    groups: ['financial', 'investments'],
    url: 'https://tdameritrade.com',
  },
  {
    name: 'MorningStar',
    category: 'financial',
    groups: ['financial', 'investments'],
    url: 'https://morningstar.com',
  },
  {
    name: 'Ameriprise Financial',
    category: 'financial',
    groups: ['financial', 'investments', 'exposed'],
    url: 'https://ameriprise.com',
  },
  {
    name: 'Merrill Lynch',
    category: 'financial',
    groups: ['financial', 'investments'],
    url: 'https://ml.com',
  },
  {
    name: 'Raymond James',
    category: 'financial',
    groups: ['financial', 'investments'],
    url: 'https://raymondjames.com',
  },
  {
    name: 'Edward Jones',
    category: 'financial',
    groups: ['financial', 'investments'],
    url: 'https://edwardjones.com',
  },
  {
    name: 'Great Lakes Borrower Service',
    category: 'financial',
    groups: ['financial'],
    url: 'https://mygreatlakes.org',
  },
  {
    name: 'Credit Karma',
    category: 'financial',
    groups: ['financial'],
    url: 'https://creditkarma.com',
  },
  {
    name: 'LearnVest',
    category: 'financial',
    groups: ['financial'],
    url: 'https://learnvest.com',
  },
  {
    name: 'Mint',
    category: 'financial',
    groups: ['financial'],
    url: 'https://mint.com',
  },
  {
    name: 'WalletHub',
    category: 'financial',
    groups: ['financial'],
    url: 'https://wallethub.com',
  },
  {
    name: 'YNAB',
    category: 'financial',
    groups: ['financial'],
    url: 'https://youneedabudget.com',
  },
  {
    name: 'PocketSmith',
    category: 'financial',
    groups: ['financial'],
    url: 'https://pocketsmith.com',
  },
  {
    name: 'Intuit',
    category: 'financial',
    groups: ['financial'],
    url: 'https://intuit.com',
  },
  {
    name: 'IRS',
    category: 'financial',
    groups: ['financial'],
    url: 'https://irs.gov',
  },
  {
    name: 'H&R Block',
    category: 'financial',
    groups: ['financial'],
    url: 'https://www.hrblock.com/',
  },
  {
    name: 'Yahoo',
    category: 'productivity',
    groups: ['productivity', 'email', 'breach-plus'],
    url: 'https://yahoo.com',
  },
  {
    name: 'Gmail',
    category: 'productivity',
    groups: ['productivity', 'email', 'exposed'],
    url: 'https://gmail.com',
  },
  {
    name: 'Microsoft Outlook',
    category: 'productivity',
    groups: ['productivity', 'email', 'exposed'],
    url: 'https://outlook.live.com',
  },
  {
    name: 'AOL Mail',
    category: 'productivity',
    groups: ['productivity', 'email', 'breach-plus', 'exposed'],
    url: 'https://mail.aol.com',
  },
  {
    name: 'Amazon AWS',
    category: 'productivity',
    groups: ['productivity', 'cloud-services'],
    url: 'https://aws.amazon.com',
  },
  {
    name: 'Mega',
    category: 'productivity',
    groups: ['productivity', 'cloud-services', 'exposed'],
    url: 'https://mega.nz',
  },
  {
    name: 'Evernote',
    category: 'productivity',
    groups: ['productivity', 'cloud-services', 'exposed'],
    url: 'https://evernote.com',
  },
  {
    name: 'Google Drive',
    category: 'productivity',
    groups: ['productivity', 'storage'],
    url: 'https://google.com/drive',
  },
  {
    name: 'Google',
    category: 'productivity',
    groups: ['productivity', 'cloud-services'],
    url: 'https://dropbox.com',
  },
  {
    name: 'Dropbox',
    category: 'productivity',
    groups: ['productivity', 'cloud-services', 'storage', 'exposed'],
    url: 'https://dropbox.com',
  },
  {
    name: 'WeTransfer',
    category: 'productivity',
    groups: [
      'productivity',
      'cloud-services',
      'storage',
      'breach-plus',
      'exposed',
    ],
    url: 'https://wetransfer.com',
  },
  {
    name: 'Quip',
    category: 'productivity',
    groups: ['productivity', 'cloud-services', 'breach-plus', 'exposed'],
    url: 'https://quip.com',
  },
  {
    name: 'OneDrive',
    category: 'productivity',
    groups: ['productivity', 'cloud-services', 'exposed'],
    url: 'https://onedrive.live.com',
  },
  {
    name: 'iCloud',
    category: 'productivity',
    groups: ['productivity', 'cloud-services'],
    url: 'https://icloud.com',
  },
  {
    name: 'Box',
    category: 'productivity',
    groups: ['productivity', 'cloud-services', 'exposed'],
    url: 'https://box.com',
  },
  {
    name: 'Adobe',
    category: 'productivity',
    groups: ['productivity', 'cloud-services', 'breach', 'breach-plus'],
    url: 'https://adobe.com',
  },
  {
    name: '1Password',
    category: 'productivity',
    groups: ['productivity', 'cloud-services'],
    url: 'https://1password.com',
  },
  {
    name: 'Github',
    category: 'productivity',
    groups: ['productivity', 'cloud-services'],
    url: 'https://github.com',
  },
  {
    name: 'Nest',
    category: 'productivity',
    groups: ['productivity', 'smart-home', 'breach-plus', 'exposed'],
    url: 'https://nest.com',
  },
  {
    name: 'Google Home',
    category: 'productivity',
    groups: ['productivity', 'smart-home'],
    url: 'https://store.google.com/product/google_home',
  },
  {
    name: 'Alexa',
    category: 'productivity',
    groups: ['productivity', 'smart-home', 'breach-plus'],
    url: 'https://www.amazon.com/myh/households',
  },
  {
    name: 'DocuSign',
    category: 'productivity',
    groups: ['productivity'],
    url: 'https://docusign.com',
  },
  {
    name: 'unroll me',
    category: 'productivity',
    groups: ['productivity'],
    url: 'https://unroll.me',
  },
  {
    name: 'Zenefits',
    category: 'productivity',
    groups: ['productivity', 'exposed'],
    url: 'https://zenefits.com',
  },
  {
    name: 'Asana',
    category: 'productivity',
    groups: ['productivity', 'exposed'],
    url: 'https://asana.com',
  },
  {
    name: 'Trello',
    category: 'productivity',
    groups: ['productivity', 'exposed'],
    url: 'https://trello.com',
  },
  {
    name: 'Slack',
    category: 'productivity',
    groups: ['productivity', 'breach-plus'],
    url: 'https://slack.com',
  },
  {
    name: 'Wix',
    category: 'productivity',
    groups: ['productivity', 'breach-plus'],
    url: 'https://.wix.com',
  },
  {
    name: 'WordPress',
    category: 'productivity',
    groups: ['productivity', 'breach-plus'],
    url: 'https://wordpress.com',
  },
  {
    name: 'Weebly',
    category: 'productivity',
    groups: ['productivity', 'breach-plus', 'exposed'],
    url: 'https://weebly.com',
  },
  {
    name: 'GoDaddy',
    category: 'productivity',
    groups: ['productivity'],
    url: 'https://godaddy.com',
  },
  {
    name: 'Facebook',
    category: 'social',
    groups: ['social', 'social-networking', 'breach-plus'],
    url: 'https://facebook.com',
  },
  {
    name: 'LinkedIn',
    category: 'social',
    groups: ['social', 'social-networking', 'breach', 'breach-plus', 'exposed'],
    url: 'https://linkedin.com',
  },
  {
    name: 'Google+',
    category: 'social',
    groups: ['social', 'social-networking', 'breach-plus'],
    url: 'https://plus.google.com',
  },
  {
    name: 'MySpace',
    category: 'social',
    groups: ['social', 'social-networking', 'breach', 'exposed'],
    url: 'https://myspace.com',
  },
  {
    name: 'Behance',
    category: 'social',
    groups: ['social', 'social-networking', 'exposed'],
    url: 'https://behance.net',
  },
  {
    name: 'Instagram',
    category: 'social',
    groups: ['social', 'media-sharing'],
    url: 'https://instagram.com',
  },
  {
    name: 'Pinterest',
    category: 'social',
    groups: ['social', 'media-sharing'],
    url: 'https://pinterest.com',
  },
  {
    name: 'Snapchat',
    category: 'social',
    groups: ['social', 'media-sharing', 'breach-plus'],
    url: 'https://accounts.snapchat.com',
  },
  {
    name: 'YouTube',
    category: 'social',
    groups: ['social', 'media-sharing'],
    url: 'https://youtube.com',
  },
  {
    name: 'Vimeo',
    category: 'social',
    groups: ['social'],
    url: 'https://vimeo.com',
  },
  {
    name: 'Twitter',
    category: 'social',
    groups: ['social', 'microblogging'],
    url: 'https://twitter.com',
  },
  {
    name: 'Medium',
    category: 'social',
    groups: ['social', 'microblogging', 'exposed'],
    url: 'https://medium.com',
  },
  {
    name: 'Tumblr',
    category: 'social',
    groups: ['social', 'microblogging', 'exposed'],
    url: 'https://tumblr.com/login',
  },
  {
    name: 'Kickstarter',
    category: 'social',
    groups: ['social'],
    url: 'https://kickstarter.com',
  },
  {
    name: 'WhatsApp',
    category: 'social',
    groups: ['social'],
    url: 'https://whatsapp.com',
  },
  {
    name: 'Quora',
    category: 'social',
    groups: ['social', 'breach-plus'],
    url: 'https://quora.com',
  },
  {
    name: 'Reddit',
    category: 'social',
    groups: ['social'],
    url: 'https://reddit.com',
  },
  {
    name: 'Kik',
    category: 'social',
    groups: ['social', 'breach-plus'],
    url: 'https://kik.com',
  },
  {
    name: 'Discord',
    category: 'social',
    groups: ['social'],
    url: 'https://discordapp.com',
  },
  {
    name: 'GroupMe',
    category: 'social',
    groups: ['social'],
    url: 'https://groupme.com/en-US/',
  },
  {
    name: 'Lifesum',
    category: 'health',
    groups: ['health', 'fitness', 'breach-plus', 'exposed'],
    url: 'https://lifesum.com',
  },
  {
    name: 'MyFitnessPal',
    category: 'health',
    groups: ['health', 'fitness'],
    url: 'https://myfitnesspal.com',
  },
  {
    name: 'RunKeeper',
    category: 'health',
    groups: ['health', 'fitness'],
    url: 'https://runkeeper.com',
  },
  {
    name: 'Fitbit',
    category: 'health',
    groups: ['health', 'fitness'],
    url: 'https://fitbit.com',
  },
  {
    name: 'Strava',
    category: 'health',
    groups: ['health', 'fitness'],
    url: 'https://strava.com',
  },
  {
    name: 'The Bump',
    category: 'health',
    groups: ['health', 'medical'],
    url: 'https://thebump.com',
  },
  {
    name: 'Microsoft Healthvault',
    category: 'health',
    groups: ['health', 'medical'],
    url: 'https://healthvault.com/en-us/',
  },
  {
    name: 'mySymptoms',
    category: 'health',
    groups: ['health', 'medical'],
    url: 'https://skygazerlabs.com/wp/',
  },
  {
    name: 'One Medical',
    category: 'health',
    groups: ['health', 'medical', 'exposed'],
    url: 'https://www.onemedical.com',
  },
  {
    name: '23 and Me',
    category: 'health',
    groups: ['health', 'medical'],
    url: 'https://23andme.com',
  },
  {
    name: 'Metlife',
    category: 'health',
    groups: ['health', 'insurance'],
    url: 'https://metlife.com',
  },
  {
    name: 'Blue Cross',
    category: 'health',
    groups: ['health', 'insurance'],
    url: 'https://bcbs.com',
  },
  {
    name: 'Blue Shield',
    category: 'health',
    groups: ['health', 'insurance'],
    url: 'https://blueshieldcafoundation.org',
  },
  {
    name: 'Kaiser Permanente',
    category: 'health',
    groups: ['health', 'insurance'],
    url: 'https://healthy.kaiserpermanente.org',
  },
  {
    name: 'State Farm',
    category: 'health',
    groups: ['health', 'insurance'],
    url: 'https://statefarm.com/insurance/health',
  },
  {
    name: 'Allstate',
    category: 'health',
    groups: ['health', 'insurance'],
    url: 'https://allstatebenefits.com',
  },
  {
    name: 'UnitedHealthcare',
    category: 'health',
    groups: ['health', 'insurance', 'exposed'],
    url: 'https://uhc.com',
  },
  {
    name: 'Aetna',
    category: 'health',
    groups: ['health', 'insurance'],
    url: 'https://aetna.com',
  },
  {
    name: 'WellCare',
    category: 'health',
    groups: ['health', 'insurance'],
    url: 'https://wellcare.com',
  },
  {
    name: 'Humana',
    category: 'health',
    groups: ['health', 'insurance'],
    url: 'https://humana.com',
  },
  {
    name: 'Cigna',
    category: 'health',
    groups: ['health', 'insurance'],
    url: 'https://cigna.com',
  },
  {
    name: 'Anthem',
    category: 'health',
    groups: ['health', 'insurance'],
    url: 'https://anthem.com',
  },
  {
    name: 'Amazon',
    category: 'shopping',
    groups: ['shopping', 'online-marketplaces'],
    url: 'https://amazon.com',
  },
  {
    name: 'Wayfair',
    category: 'shopping',
    groups: ['shopping', 'online-marketplaces'],
    url: 'https://wayfair.com',
  },
  {
    name: 'Zola',
    category: 'shopping',
    groups: ['shopping', 'online-marketplaces', 'exposed'],
    url: 'https://zola.com',
  },
  {
    name: 'Etsy',
    category: 'shopping',
    groups: ['shopping', 'online-marketplaces'],
    url: 'https://etsy.com',
  },
  {
    name: 'Ebay',
    category: 'shopping',
    groups: ['shopping', 'online-marketplaces'],
    url: 'https://ebay.com',
  },
  {
    name: 'Macys',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands'],
    url: 'https://macys.com',
  },
  {
    name: 'Nordstrom',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands', 'breach-plus'],
    url: 'https://nordstrom.com',
  },
  {
    name: 'Walmart',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands', 'breach-plus'],
    url: 'https://walmart.com',
  },
  {
    name: 'Target',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands'],
    url: 'https://target.com',
  },
  {
    name: 'Apple',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands'],
    url: 'https://apple.com',
  },
  {
    name: 'Costco',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands', 'exposed'],
    url: 'https://costco.com',
  },
  {
    name: 'Kohls',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands'],
    url: 'https://kohls.com',
  },
  {
    name: 'Sears',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands'],
    url: 'https://sears.com',
  },
  {
    name: 'Best Buy',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands'],
    url: 'https://www.bestbuy.com/',
  },
  {
    name: 'Amour Vert',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands'],
    url: 'https://amourvert.com',
  },
  {
    name: 'Crate and Barrel',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands'],
    url: 'https://crateandbarrel.com',
  },
  {
    name: 'Gabriella NY Bridal Salon',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands'],
    url: 'https://gabriellanewyork.com',
  },
  {
    name: 'Heath Ceramics',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands'],
    url: 'https://heathceramics.com',
  },
  {
    name: "Kiehl's",
    category: 'shopping',
    groups: ['shopping', 'traditional-brands', 'exposed'],
    url: 'https://kiehls.com',
  },
  {
    name: 'LOHO Bride',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands'],
    url: 'https://lohobride.com',
  },
  {
    name: 'Madewell',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands'],
    url: 'https://madewell.com',
  },
  {
    name: 'Pottery Barn',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands', 'breach-plus'],
    url: 'https://potterybarn.com',
  },
  {
    name: 'Reformation',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands'],
    url: 'https://thereformation.com',
  },
  {
    name: 'REI',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands'],
    url: 'https://rei.com',
  },
  {
    name: 'Sur La Table',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands'],
    url: 'https://surlatable.com',
  },
  {
    name: 'West Elm',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands'],
    url: 'https://westelm.com',
  },
  {
    name: 'Filson',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands', 'exposed'],
    url: 'https://filson.com',
  },
  {
    name: 'Everlane',
    category: 'shopping',
    groups: ['shopping', 'traditional-brands'],
    url: 'https://everlane.com',
  },
  {
    name: 'Target',
    category: 'shopping',
    groups: ['shopping', 'online-retailers', 'breach'],
    url: 'https://bloomthat.com',
  },
  {
    name: 'BloomThat',
    category: 'shopping',
    groups: ['shopping', 'online-retailers'],
    url: 'https://bloomthat.com',
  },
  {
    name: 'Lunya',
    category: 'shopping',
    groups: ['shopping', 'online-retailers'],
    url: 'https://lunya.co',
  },
  {
    name: 'Recliner',
    category: 'shopping',
    groups: ['shopping', 'online-retailers'],
    url: 'https://recliner.nyc',
  },
  {
    name: 'Rent the Runway',
    category: 'shopping',
    groups: ['shopping', 'online-retailers'],
    url: 'https://renttherunway.com',
  },
  {
    name: 'Spring',
    category: 'shopping',
    groups: ['shopping', 'online-retailers'],
    url: 'https://shopspring.com',
  },
  {
    name: 'Stitch Fix',
    category: 'shopping',
    groups: ['shopping', 'online-retailers', 'exposed'],
    url: 'https://stitchfix.com',
  },
  {
    name: 'The Citizenry',
    category: 'shopping',
    groups: ['shopping', 'online-retailers'],
    url: 'https://the-citizenry.com',
  },
  {
    name: 'Grovemade',
    category: 'shopping',
    groups: ['shopping', 'online-retailers'],
    url: 'https://grovemade.com',
  },
  {
    name: 'hardgraft',
    category: 'shopping',
    groups: ['shopping', 'online-retailers'],
    url: 'https://hardgraft.com',
  },
  {
    name: 'Kaufmann Mercantile',
    category: 'shopping',
    groups: ['shopping', 'online-retailers'],
    url: 'https://kaufmann-mercantile.com',
  },
  {
    name: 'Machine Era Co',
    category: 'shopping',
    groups: ['shopping', 'online-retailers', 'breach-plus'],
    url: 'https://www.machine-era.com',
  },
  {
    name: 'Joss and Main',
    category: 'shopping',
    groups: ['shopping', 'online-retailers'],
    url: 'https://jossandmain.com',
  },
  {
    name: 'Orchard Mile',
    category: 'shopping',
    groups: ['shopping', 'online-retailers'],
    url: 'https://orchardmile.com',
  },
  {
    name: 'TripAdvisor',
    category: 'shopping',
    groups: ['shopping', 'exposed'],
    url: 'https://tripadvisor.com',
  },
  {
    name: 'Airbnb',
    category: 'shopping',
    groups: ['shopping', 'exposed'],
    url: 'https://www.airbnb.com/',
  },
  {
    name: 'StubHub',
    category: 'shopping',
    groups: ['shopping'],
    url: 'https://www.stubhub.com/',
  },
  {
    name: 'Instacart',
    category: 'shopping',
    groups: ['shopping'],
    url: 'https://www.instacart.com/',
  },
  {
    name: 'Grubhub',
    category: 'shopping',
    groups: ['shopping'],
    url: 'https://www.grubhub.com/',
  },
  {
    name: 'Seamless',
    category: 'shopping',
    groups: ['shopping'],
    url: 'https://seamless.com',
  },
  {
    name: 'Caviar',
    category: 'shopping',
    groups: ['shopping'],
    url: 'https://trycaviar.com',
  },
  {
    name: 'TransUnion',
    category: 'other',
    groups: ['other', 'credit', 'breach-plus'],
    url: 'https://transunion.com',
  },
  {
    name: 'Equifax',
    category: 'other',
    groups: ['other', 'credit', 'exposed', 'breach'],
    url: 'https://equifax.com',
  },
  {
    name: 'Experian',
    category: 'other',
    groups: ['other', 'credit', 'exposed', 'breach'],
    url: 'https://experian.com',
  },
  {
    name: 'Exactis',
    category: 'other',
    groups: ['other', 'breach', 'exposed'],
    url: 'https://experian.com',
  },
  {
    name: 'Apollo',
    category: 'other',
    groups: ['other', 'breach', 'breach-plus'],
    url: 'https://experian.com',
  },
];
