import { ADD_PROGRESS, DELETE_ALL_PROGRESS } from '../constants/actions';

const initialState = [];

export default function progress(state = initialState, action) {
  switch (action.type) {
    case ADD_PROGRESS:
      return [...state, action.newProgress];

    case DELETE_ALL_PROGRESS:
      return initialState;

    default:
      return state;
  }
}
