import React from 'react';
import { Link } from 'react-router-dom';

import { ButtonPrimary, ButtonSecondary } from '../styled/buttons';
import { ContentWidth, Paper } from '../styled/containers';
import { H1, P } from '../styled/type';

const ScanOffer = () => (
  <ContentWidth wide paddingTop="lg2">
    <Paper>
      <H1>
        <span role="img" aria-label="$">
          {'💲'}
        </span>
      </H1>

      <P>We can run a scan on your email inbox to see if you have any more.</P>

      <ButtonSecondary as={Link} to="/">
        Maybe later.
      </ButtonSecondary>
      <ButtonPrimary as={Link} to="/scan-results">
        Yes, please!
      </ButtonPrimary>
    </Paper>
  </ContentWidth>
);

export default ScanOffer;
