import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import AnimationLinkPaper from './AnimationLinkPaper';

import {
  Breadcrumb,
  CurrentBreadcrumb,
  DummyBreadcrumb,
  H2,
} from './styled/type';
import { BreadcrumbsContainer } from './styled/containers';

const Breadcrumbs = ({
  actionName,
  actionSlug,
  dummyBreadcrumb,
  moduleName,
  moduleSlug = '',
  taskName,
  taskSlug,
}) => (
  <BreadcrumbsContainer>
    <H2 color="graybAlt">
      {dummyBreadcrumb && <DummyBreadcrumb>{dummyBreadcrumb}</DummyBreadcrumb>}
      {moduleSlug ? (
        <AnimationLinkPaper to={`/secure/${moduleSlug}`}>
          <Breadcrumb>{moduleName}</Breadcrumb>
        </AnimationLinkPaper>
      ) : (
        <CurrentBreadcrumb>{moduleName}</CurrentBreadcrumb>
      )}
      {actionName &&
        !actionSlug && (
          <Fragment>
            <span> &gt; </span>
            <CurrentBreadcrumb>{actionName}</CurrentBreadcrumb>
          </Fragment>
        )}
      {actionName &&
        actionSlug && (
          <Fragment>
            <span> &gt; </span>
            <AnimationLinkPaper to={`/secure/${moduleSlug}/${actionSlug}`}>
              {actionName}
            </AnimationLinkPaper>
          </Fragment>
        )}
      {taskName && (
        <Fragment>
          <span> &gt; </span>
          <CurrentBreadcrumb>{taskName}</CurrentBreadcrumb>
        </Fragment>
      )}
    </H2>
  </BreadcrumbsContainer>
);

const propTypes = {
  actionName: PropTypes.string,
  actionSlug: PropTypes.string,
  dummyBreadcrumb: PropTypes.string,
  moduleName: PropTypes.string,
  moduleSlug: PropTypes.string,
  taskName: PropTypes.string,
  taskSlug: PropTypes.string,
};
Breadcrumbs.propTypes = propTypes;

export default Breadcrumbs;
