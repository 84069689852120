export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const ADD_PROGRESS = 'ADD_PROGRESS';
export const DELETE_ACCOUNT_BY_ID = 'DELETE_ACCOUNT_BY_ID';
export const DELETE_ACCOUNT_BY_NAME = 'DELETE_ACCOUNT_BY_NAME';
export const DELETE_ALL_ACCOUNTS = 'DELETE_ALL_ACCOUNTS';
export const DELETE_ALL_PROGRESS = 'DELETE_ALL_PROGRESS';
export const SET_ARE_INTERACTIONS_BLOCKED = 'SET_ARE_INTERACTIONS_BLOCKED';
export const SET_HOME_HEADER_HEIGHT = 'SET_HOME_HEADER_HEIGHT';
export const SET_IS_INITIAL_LOAD_IN = 'SET_IS_INITIAL_LOAD_IN';
export const SET_IS_PAPER_IN = 'SET_IS_PAPER_IN';
export const SET_IS_ROUTE_IN = 'SET_IS_ROUTE_IN';
export const SET_IS_SLIDE_IN = 'SET_IS_SLIDE_IN';
export const SET_MODAL_VISIBILITY = 'SET_MODAL_VISIBILITY';
export const TOGGLE_VISUALIZATION = 'TOGGLE_VISUALIZATION';
export const UPDATE_USER = 'UPDATE_USER';
