import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteAccountById } from '../../actions';

import uuid from 'uuid';

import { sanitize } from '../../utilities';

import { P } from '../styled/type';
import { Td, Tr } from '../styled/containers';

class DevAccountTable extends Component {
  render() {
    const {
      accounts,
      accountsToHighlight = [],
      deleteAccountById,
      showDeleteButton,
    } = this.props;
    return accounts.length ? (
      <table>
        <tbody>
          {accounts.map(({ id, name, groups = [] }) => {
            const highlightAccount = accountsToHighlight.find(
              account => sanitize(account.name) === sanitize(name)
            );
            return (
              <Tr key={uuid()} highlightAccount={!!highlightAccount}>
                <Td>{name}</Td>
                <Td>
                  {groups.map((group, i) => (i > 0 ? ', ' + group : group))}
                </Td>
                {showDeleteButton && (
                  <Td>
                    <button onClick={() => deleteAccountById(id)}>
                      delete
                    </button>
                  </Td>
                )}
              </Tr>
            );
          })}
        </tbody>
      </table>
    ) : (
      <P>
        <em>no accounts</em>
      </P>
    );
  }
}

const propTypes = {
  accounts: PropTypes.array.isRequired,
  deleteAccountById: PropTypes.func.isRequired,
  accountsToHighlight: PropTypes.array,
  showDeleteButton: PropTypes.bool,
};
DevAccountTable.propTypes = propTypes;

export default connect(
  null,
  { deleteAccountById }
)(DevAccountTable);
