import React from 'react';
import PropTypes from 'prop-types';

import uuid from 'uuid';

import { checkIfCompleted, slugify } from '../../utilities';

import AnimationLinkRoute from '../AnimationLinkRoute';
import WithProgress from '../hoc/WithProgress';

import { LockIcon } from '../styled/icons';
import { DisplayStateLink } from '../styled/type';

const HomeActionList = ({
  action,
  calculateOverlay,
  isInactive,
  moduleSlug,
  progress,
}) => {
  return (
    <div>
      {isInactive ? (
        <DisplayStateLink
          cursor="default"
          onMouseLeave={() => calculateOverlay(false)}>
          {action.name}
          {action.tasks.map(task => (
            <LockIcon
              key={uuid()}
              isComplete={checkIfCompleted(slugify(task.name), progress)}
            />
          ))}
        </DisplayStateLink>
      ) : (
        <AnimationLinkRoute
          to={`/secure/${moduleSlug}/${slugify(action.name)}?from-home`}>
          <DisplayStateLink
            onMouseEnter={() => calculateOverlay(true, null, action)}
            onMouseLeave={() => calculateOverlay(false)}>
            {action.name}
            {action.tasks.map(task => (
              <LockIcon
                key={uuid()}
                isComplete={checkIfCompleted(slugify(task.name), progress)}
              />
            ))}
          </DisplayStateLink>
        </AnimationLinkRoute>
      )}
    </div>
  );
};

const propTypes = {
  action: PropTypes.object.isRequired,
  calculateOverlay: PropTypes.func.isRequired,
  isInactive: PropTypes.bool,
  moduleSlug: PropTypes.string,
  progress: PropTypes.array,
};
HomeActionList.propTypes = propTypes;

export default WithProgress(HomeActionList);
