import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import uuid from 'uuid';

import {
  findAccountsByGroups,
  getAllTaskGroupsFromAction,
  slugify,
} from '../../utilities';

import DevAccountTable from './DevAccountTable';

import { InnerSection } from '../styled/dev';
import { P } from '../styled/type';

const ActionListWithMatchedAccounts = ({
  accounts,
  action: { groups, name },
  action,
  moduleSlug,
}) => {
  const actionRollupGroups = getAllTaskGroupsFromAction(action);
  const eligibleAccounts = findAccountsByGroups(accounts, actionRollupGroups);
  return (
    <InnerSection>
      <P>
        <Link to={`/secure/${moduleSlug}/${slugify(name)}`}>{name}</Link>
      </P>
      <InnerSection>
        <ul>
          {action.tasks.map(task => (
            <li key={uuid()}>{task.name}</li>
          ))}
          <hr />
          <li>
            matching groups:{' '}
            {actionRollupGroups.map(group => (
              <em key={uuid()}>{group} </em>
            ))}
          </li>
        </ul>
      </InnerSection>
      <ul>
        {eligibleAccounts.length > 0 ? (
          <InnerSection key={uuid()}>
            <li>
              <DevAccountTable accounts={eligibleAccounts} />
            </li>
          </InnerSection>
        ) : (
          <InnerSection key={uuid()}>
            <li>
              <p key={uuid()}>no matches</p>
            </li>
          </InnerSection>
        )}
      </ul>
    </InnerSection>
  );
};

const propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      groups: PropTypes.array,
      tasks: PropTypes.array,
    })
  ),
  action: PropTypes.shape({
    name: PropTypes.string.isRequired,
    groups: PropTypes.array,
    tasks: PropTypes.array,
  }),
  moduleSlug: PropTypes.string.isRequired,
};
ActionListWithMatchedAccounts.propTypes = propTypes;

export default ActionListWithMatchedAccounts;
