import fp from 'lodash/fp';
import intersection from 'lodash/intersection';
import union from 'lodash/union';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

import { ACCOUNT_LOOKUP_LIST } from '../data/accountLookupList.js';

/*
 * Generic
 */
const takeFirst = arr => arr[0];

const lookupObjectByName = (arr = [], name = '') =>
  arr.find(obj => slugify(obj.name) === name) || {};

export const removeUndefined = arr => arr.filter(x => x !== undefined);

/*
 * Account
 */
// findAccountInAccountsByName: ( name * accounts ) → account
export const findAccountInAccountsByName = (accountName, accounts) =>
  accounts &&
  accounts.find(
    account => account && sanitize(account.name) === sanitize(accountName)
  );

// filterAccountsByGroup: ( accounts * group ) → accounts
export const filterAccountsByGroup = (accounts, group) =>
  accounts.filter(({ groups = [] }) => groups.includes(group));

// filterAccountsByCategoryAndGroup: ( accounts * category * group ) → accounts
export const filterAccountsByCategoryAndGroup = (accounts, category, group) =>
  accounts.filter(
    account => account.category === category && account.groups.includes(group)
  );

// filterAccountsByCategory: ( accounts * category ) → accounts
export const filterAccountsByCategory = (accounts, category) =>
  accounts.filter(account => account.category === category);

// filterOtherAccountsByCategoryAndExcludedGroups: ( accounts * category * excludedGroups ) → accounts
export const filterOtherAccountsByCategoryAndExcludedGroups = (
  accounts,
  category,
  excludedGroups
) =>
  accounts.filter(
    account =>
      account.category === category &&
      intersection(account.groups, excludedGroups).length <= 0
  );

// removeDuplicateAccounts: accounts → accounts
export const removeDuplicateAccounts = arr => uniqBy(arr, 'id');

// _findAccountsByGroups: ( accounts * groups ) → accounts
//   - used internally as the specific "finding" method
const _findAccountsByGroups = (accounts, groups) =>
  groups.flatMap(group => filterAccountsByGroup(accounts, group));

// findAccountsByGroups: ( accounts * groups ) → accounts
//   - used externally, since it also removes duplicates
export const findAccountsByGroups = fp.compose(
  removeDuplicateAccounts,
  _findAccountsByGroups
);

// filterFinancialAccounts: ( accounts * groups ) → accounts
//   - return Accounts with category 'financial' but no group 'bank', 'payment', or 'investment'
export const filterFinancialAccounts = (accounts, group) =>
  accounts.filter(
    ({ category = '', groups = [] }) =>
      category === 'financial' &&
      !groups.includes('bank') &&
      !groups.includes('payment') &&
      !groups.includes('investment')
  );

// mergeInputAccountWithLookupAccount: name → account
//   - provides the account lookup list
export const mergeInputAccountWithLookupAccount = name =>
  Object.assign(
    {},
    { name },
    findAccountInAccountsByName(name, ACCOUNT_LOOKUP_LIST)
  );

/*
 * Module → Action → Task
 */
// findModuleBySlug: ( modules * slug ) → module
export const findModuleBySlug = (modules, slug) =>
  lookupObjectByName(modules, slug);

// _findActionBySlug: ( modules * slug ) → action
//   - used internally as the specific "finding" method
const _findActionBySlug = (modules, slug) =>
  modules.map(({ actions }) => lookupObjectByName(actions, slug));

// filterModulesActionsTasksByAccounts: ( modules * accounts ) → [ { module: actions: tasks } ]
export const filterModulesActionsTasksByAccounts = (modules, accounts) => {
  const flattenedAccountsGroups = uniq(
    accounts.flatMap(account => account && account.groups)
  );

  return (
    accounts &&
    modules.map(({ name, actions }) => ({
      name: name,
      actions: actions.map(action => ({
        ...action,
        tasks: action.tasks.filter(
          task => intersection(task.groups, flattenedAccountsGroups).length > 0
        ),
      })),
    }))
  );
};

// used externally, since it also removes duplicates/nulls
//   - findActionBySlug: ( modules * slug ) → action
export const findActionBySlug = fp.compose(
  takeFirst,
  removeUndefined,
  _findActionBySlug
);

// findActionByModuleSlugAndActionSlug: ( modules * slug * slug ) → action
export const findActionByModuleSlugAndActionSlug = (
  modules,
  moduleSlug,
  actionSlug
) => {
  const { actions } = lookupObjectByName(modules, moduleSlug);
  return lookupObjectByName(actions, actionSlug);
};

// _findTaskBySlug: ( modules * slug) → task
//   - used internally as the specific "finding" method
const _findTaskBySlug = (modules, slug) =>
  modules.map(({ actions }) =>
    actions.map(({ tasks }) => lookupObjectByName(tasks, slug))
  );

// findTaskBySlug: ( modules * slug ) → task
export const findTaskBySlug = fp.compose(
  takeFirst,
  removeUndefined,
  takeFirst,
  removeUndefined,
  _findTaskBySlug
);

// findTaskByModuleActionTaskSlugs: ( modules * slug * slug * slug ) → task
export const findTaskByModuleActionTaskSlugs = (
  modules,
  moduleSlug,
  actionSlug,
  taskSlug
) => {
  const { actions } = lookupObjectByName(modules, moduleSlug);
  const { tasks } = lookupObjectByName(actions, actionSlug);
  return lookupObjectByName(tasks, taskSlug);
};

// checkIfCompleted: ( slug * progress ) → bool
export const checkIfCompleted = (taskSlug, progress) =>
  !!progress.filter(entry => entry.taskSlug === taskSlug && entry.isCompleted)
    .length;

// getAllTaskGroupsFromAction: action → [group]
export const getAllTaskGroupsFromAction = action =>
  action && action.tasks ? union(...action.tasks.map(task => task.groups)) : [];

/*
 * text helpers
 */
export const sanitize = input => input.replace(/[^\w.]+/g, '').toLowerCase();

const removeLeading = input => input.replace(/^ */g, '');
const removeTrailing = input => input.replace(/ *$/g, '');
const removeDuplicateSpaces = input => input.replace(/ +$/g, '');
const nonWordToHyphen = input => input.replace(/[^\w]+/g, '-');
const underscoreToHyphen = input => input.replace(/_+/g, '-');
const whitespaceToHyphen = input => input.replace(/ +/g, '-');
const removeDuplicateHyphens = input => input.replace(/-+/g, '-');
const lowerCase = input => input.toLowerCase();

export const slugify = fp.compose(
  lowerCase,
  removeDuplicateHyphens,
  whitespaceToHyphen,
  underscoreToHyphen,
  nonWordToHyphen,
  removeDuplicateSpaces,
  removeTrailing,
  removeLeading
);

export const getOuterHeight = el => {
  var styles = window.getComputedStyle(el);
  var margin =
    parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);
  return Math.ceil(el.offsetHeight + margin);
};
