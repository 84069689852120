import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactSVG from 'react-svg';

import WithAnimation from './hoc/WithAnimation';

import styled from 'styled-components';
import { AnimationFade } from './styled/animation';
import {
  ArrowButtonLeft,
  ArrowButtonRight,
  ButtonSecondary,
} from './styled/buttons';
import { Center, ContentWidth } from './styled/containers';
import {
  NewSvg,
  OldSvg,
  OnboardingSVGContainer,
  PositionAbsolute,
} from './styled/onboarding';
import { H1 } from './styled/type';

import { ONBOARDING_SLIDES_CONTENT } from '../data/onboardingSlidesContent';
import { FALLBACK_SVG_MODULE_INTRO, TRANSITION } from '../constants/general.js';
const { MD_DURATION } = TRANSITION;

export const OnboardingContainer = styled.div`
  position: relative;
`;

class Onboarding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSlide: 0,
      isOldSvgIn: true,
      isNewSvgIn: false,
      activeSvgSlide: 0,
      noAnimation: false,
      numberOfSlides: ONBOARDING_SLIDES_CONTENT.length - 1,
    };
  }
  previousSlide() {
    this.props.setIsSlideIn(false);

    setTimeout(() => {
      this.setState({
        activeSlide: this.state.activeSlide - 1,
      });
      this.props.setIsSlideIn(true);
    }, MD_DURATION);
  }
  nextSlide() {
    this.props.setIsSlideIn(false);

    this.setState({
      isNewSvgIn: true,
      isOldSvgIn: false,
    });

    setTimeout(() => {
      this.setState({
        activeSlide: this.state.activeSlide + 1,
        isNewSvgIn: false,
        isOldSvgIn: true,
        noAnimation: true,
      });

      setTimeout(() => {
        this.setState({
          noAnimation: false,
        });
      }, 0);
      this.props.setIsSlideIn(true);
    }, MD_DURATION);
  }
  exit() {
    const { history, setIsRouteIn } = this.props;

    setIsRouteIn(false);

    setTimeout(() => {
      history.push('/discovery/financial');
    }, MD_DURATION);
  }
  render() {
    const {
      props: {
        animation: { isSlideIn },
      },
      state: { activeSlide, numberOfSlides },
    } = this;
    return (
      <ContentWidth wide paddingTop="lg2" position="relative">
        <OnboardingContainer>
          <PositionAbsolute left={0}>
            <ArrowButtonLeft
              onClick={() => this.previousSlide()}
              isDisabled={activeSlide === 0}
            />
          </PositionAbsolute>
          <PositionAbsolute right={0}>
            <ArrowButtonRight
              onClick={() => this.nextSlide()}
              isDisabled={activeSlide === numberOfSlides}
            />
          </PositionAbsolute>

          <AnimationFade isIn={isSlideIn}>
            <Center>
              <H1 color="white" size="h1Alt" marginBottom="xl">
                {ONBOARDING_SLIDES_CONTENT[activeSlide].copy}
              </H1>
            </Center>
          </AnimationFade>

          <OnboardingSVGContainer>
            <AnimationFade
              noAnimation={this.state.noAnimation}
              isIn={this.state.isOldSvgIn}>
              <OldSvg>
                <ReactSVG
                  src={
                    ONBOARDING_SLIDES_CONTENT[activeSlide].svg
                      ? ONBOARDING_SLIDES_CONTENT[activeSlide].svg
                      : FALLBACK_SVG_MODULE_INTRO
                  }
                />
              </OldSvg>
            </AnimationFade>
            {activeSlide < numberOfSlides && (
              <AnimationFade
                noAnimation={this.state.noAnimation}
                isIn={this.state.isNewSvgIn}>
                <NewSvg>
                  <ReactSVG
                    src={
                      ONBOARDING_SLIDES_CONTENT[activeSlide + 1].svg
                        ? ONBOARDING_SLIDES_CONTENT[activeSlide + 1].svg
                        : FALLBACK_SVG_MODULE_INTRO
                    }
                  />
                </NewSvg>
              </AnimationFade>
            )}
          </OnboardingSVGContainer>

          <AnimationFade
            isIn={activeSlide === ONBOARDING_SLIDES_CONTENT.length - 1}>
            <Center marginTop="xl">
              <ButtonSecondary onClick={() => this.exit()}>
                Let's do it!
              </ButtonSecondary>
            </Center>
          </AnimationFade>
        </OnboardingContainer>
      </ContentWidth>
    );
  }
}

const propTypes = {
  animation: PropTypes.shape({
    isSlideIn: PropTypes.bool.isRequired,
  }),
  setIsSlideIn: PropTypes.func.isRequired,
};
Onboarding.propTypes = propTypes;

export default WithAnimation(Onboarding);
