import React from 'react';
import PropTypes from 'prop-types';

import WithProgress from '../hoc/WithProgress';
import { PaperSmall } from '../styled/containers';
import { H2, LinkBasic, P } from '../styled/type';

import { ACCOUNT_CATEGORIES } from '../../data/accountCategories';

const numberOfAccountsByCategory = (accounts, category) =>
  accounts.filter(account => account.category === category).length || 0;

function determineGetStartedInfo(accounts, masterModuleList, progress) {
  if (!progress.length) {
    return {
      copy1: 'New to digital safety? Start here!',
      copy2:
        "We'll walk you through a few of the best things you can do for your security.",
      link: '/secure/secure-your-finances?show-intro',
    };
  } else {
    for (const { name: categoryName } of ACCOUNT_CATEGORIES) {
      if (!numberOfAccountsByCategory(accounts, categoryName)) {
        return {
          copy1: `Want to discover ${categoryName} accounts?`,
          copy2:
            "We'll walk you through different sub-categories in the account discovery process.",
          link: `/discovery/${categoryName}`,
        };
      }
    }
  }

  // fallback
  return {
    copy1: 'Excellent work!',
    copy2:
      "Looks like you've discovered accounts from every category. Keep going on the actions!",
  };
}
const GetStarted = ({ accounts, masterModuleList, progress }) => {
  const { copy1, copy2, link = '' } = determineGetStartedInfo(
    accounts,
    masterModuleList,
    progress
  );
  return (
    <div>
      <H2>Get Started</H2>
      <PaperSmall>
        <P marginTop={0} marginBottom="sm">
          {copy1}
        </P>
        <P size="sm" marginTop={0} marginBottom={0}>
          {copy2}
          {link && <LinkBasic to={link}> Start ＞</LinkBasic>}
        </P>
      </PaperSmall>
    </div>
  );
};

const propTypes = {
  accounts: PropTypes.array.isRequired,
  masterModuleList: PropTypes.array.isRequired,
  progress: PropTypes.array.isRequired,
};
GetStarted.propTypes = propTypes;

export default WithProgress(GetStarted);
