import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { event, select } from 'd3-selection';
import { drag } from 'd3-drag';
import {
  forceCenter,
  forceLink,
  forceManyBody,
  forceSimulation,
} from 'd3-force';

/* import times from 'lodash/times';
 * import union from 'lodash/union'; */

import { getColor } from '../../utilities/visualization';

import { VisualizationContainer } from '../styled/containers';

const HEIGHT = 560;
const WIDTH = 560;

class ForceDirectedGraph extends Component {
  constructor(props) {
    super(props);
    this.createChart = this.createChart.bind(this);
    this.state = {
      _hasMounted: false,
    };
  }

  componentDidMount() {
    this.setState({
      _hasMounted: false,
    });

    const { dataset } = this.props;

    /* const filler =
     *   dataset.length < 40
     *     ? times(40 - dataset.length, () => ({
     *         name: 'dummy',
     *         category: 'dummy',
     *       }))
     *     : {};
     * const datasetPlusFiller = union(dataset, filler); */

    let links = [];
    dataset.forEach(account => {
      const t1 = dataset.filter(
        x => x.name !== account.name && x.category === account.category
      );
      t1.forEach(link => {
        links.push({
          target: account.name,
          source: link.name,
          strength: 0.4,
        });
      });
    });

    /* this.createChart(datasetPlusFiller, links); */
    this.createChart(dataset, links);
  }
  shouldComponentUpdate(nextProps) {
    // only mount once
    return this.state._hasMounted;
  }

  createChart(localDataset, newLinks) {
    const svg = select(this.svgNode);

    // simulation setup with all forces
    var linkForce = forceLink()
      .id(link => link.name)
      .distance(80)
      .strength(link => link.strength);

    const simulation = forceSimulation()
      .force('link', linkForce)
      .force('charge', forceManyBody().strength(-20))
      .force('center', forceCenter(WIDTH / 2, HEIGHT / 2));

    var dragDrop = drag()
      .on('start', node => {
        node.fx = node.x;
        node.fy = node.y;
      })
      .on('drag', node => {
        simulation.alphaTarget(0.7).restart();
        node.fx = event.x;
        node.fy = event.y;
      })
      .on('end', node => {
        if (!event.active) {
          simulation.alphaTarget(0);
        }
        node.fx = null;
        node.fy = null;
      });

    function selectNode(selectedNode) {
      // console.log('selectNode', selectedNode);
    }

    const nodeElements = svg
      .append('g')
      .selectAll('circle')
      .data(localDataset)
      .enter()
      .append('circle')
      .attr('r', 14)
      .attr('fill', d => getColor(d.category, true))
      .call(dragDrop)
      .on('click', selectNode);

    simulation.nodes(localDataset).on('tick', () => {
      nodeElements.attr('cx', node => node.x).attr('cy', node => node.y);
    });

    simulation.force('link').links(newLinks);
  }

  render() {
    return (
      <VisualizationContainer>
        <div ref={node => (this.chart2Node = node)} id="chart2">
          <svg
            width={WIDTH}
            height={HEIGHT}
            ref={node => (this.svgNode = node)}
            id="svgNode"
          />
        </div>
      </VisualizationContainer>
    );
  }
}

const propTypes = {
  dataset: PropTypes.array.isRequired,
};
ForceDirectedGraph.propTypes = propTypes;

export default ForceDirectedGraph;
