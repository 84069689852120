import React from 'react';
import PropTypes from 'prop-types';

import {
  filterAccountsByCategoryAndGroup,
  filterOtherAccountsByCategoryAndExcludedGroups,
} from '../../utilities';

import AccountCheckboxTable from './sub-components/AccountCheckboxTable';

import { P } from '../styled/type';
import { Flex, FlexRow, PaperContentWidth } from '../styled/containers';
import {
  InitializeAnimationFadeDelay1,
  InitializeAnimationFadeDelay2,
} from '../styled/animation';

const SlideSummary = ({ accounts, category, categoryParam, groupName }) => {
  const accountGroups = category
    .map(groupObject => groupObject.groupName)
    .slice(1, category.length - 2);

  const excludedGroups = category
    .slice(1, category.length - 3)
    .map(groupObject => groupObject.groupName);

  return (
    <PaperContentWidth wide paddingTop="lg">
      <InitializeAnimationFadeDelay1>
        <P centered bold size="bodyLarge">
          Great! Here are the accounts we will add to your digital footprint:
        </P>
      </InitializeAnimationFadeDelay1>

      <FlexRow paddingTop="md">
        {accountGroups.map(accountGroup => {
          const filteredAccounts =
            accountGroup === 'other'
              ? filterOtherAccountsByCategoryAndExcludedGroups(
                  accounts,
                  categoryParam,
                  excludedGroups
                )
              : filterAccountsByCategoryAndGroup(
                  accounts,
                  categoryParam,
                  accountGroup
                );

          return (
            <Flex key={accountGroup}>
              <InitializeAnimationFadeDelay2>
                {accounts.length > 0 ? (
                  <AccountCheckboxTable
                    filteredAccounts={filteredAccounts}
                    accountGroup={accountGroup}
                  />
                ) : (
                  <p>no accounts</p>
                )}
              </InitializeAnimationFadeDelay2>
            </Flex>
          );
        })}
      </FlexRow>
    </PaperContentWidth>
  );
};

const propTypes = {
  accounts: PropTypes.array.isRequired,
  category: PropTypes.array.isRequired,
  categoryParam: PropTypes.string.isRequired,
  group: PropTypes.shape({
    copy: PropTypes.string,
    groupName: PropTypes.string,
    introSvg: PropTypes.string,
    title: PropTypes.string,
  }),
};
SlideSummary.propTypes = propTypes;

export default SlideSummary;
