import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import pageNotFound from './components/pageNotFound';
import Accounts from './components/dev/Accounts';
import Action from './components/action/Action';
import Discovery from './components/discovery/Discovery';
import Dev from './components/dev/Dev';
import Header from './components/Header';
import Home from './components/Home';
import Login from './components/dev/Login';
import ModalUnderlay from './components/ModalUnderlay';
import Module from './components/module/Module';
import Onboarding from './components/Onboarding';
import RouteChangeInterceptor from './components/RouteChangeInterceptor';
import ScanOffer from './components/dev/ScanOffer';
import ScanResults from './components/dev/ScanResults';
import Splash from './components/Splash';
import Task from './components/task/Task';
import WithAnimation from './components/hoc/WithAnimation';
import WithUi from './components/hoc/WithUi';
import WithUser from './components/hoc/WithUser';

import { GlobalStyle } from './components/styled/global';
import {
  BodyBg,
  ContentBackground,
  FlexColumn,
  InteractionsBlocker,
  NavContainer,
} from './components/styled/containers';
import { AnimationFadeLong } from './components/styled/animation';
import { NavLink, NavClickLink } from './components/styled/type';

import { SKIP_SPLASH, TRANSITION } from './constants/general.js';
const { LG_DURATION, SPLASH_DURATION } = TRANSITION;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavShowing: false,
      isSplashCompleted: false,
      isSplashRendered: true,
    };
  }
  keypressHandler = e => {
    if (e.key === 't' && e.ctrlKey) {
      e.preventDefault();
      this.setState({ isNavShowing: !this.state.isNavShowing });
    }
  };
  componentDidUpdate(prevProps) {
    const {
      location: { pathname: oldPath },
    } = prevProps;
    const {
      hideModal,
      location: { pathname: newPath },
      ui: { isModalVisible },
    } = this.props;

    // when user navigates/route changes
    if (oldPath !== newPath) {
      window.scrollTo(0, 0);

      if (isModalVisible) {
        hideModal();
      }
    }
  }
  componentDidMount() {
    window.addEventListener('keypress', this.keypressHandler.bind(this));

    setTimeout(() => {
      this.props.setIsInitialLoadIn();

      if (SKIP_SPLASH) {
        this.setState({
          isSplashCompleted: true,
          isSplashRendered: false,
        });
      } else {
        setTimeout(() => {
          this.setState({
            isSplashCompleted: true,
          });
          setTimeout(() => {
            this.setState({
              // trigger unmount, which will stop three.js
              isSplashRendered: false,
            });
          }, LG_DURATION);
        }, SPLASH_DURATION);
      }
    }, 0);
  }
  componentWillUnmount() {
    window.removeEventListener('keypress', this.keypressHandler);
  }
  render() {
    const {
      props: {
        animation: { isInitialLoadIn },
        location: { pathname },
        maybeLoadProfile,
        profiles,
        toggleVisualization,
        ui: { areInteractionsBlocked, homeHeaderHeight },
      },
      state: { isNavShowing, isSplashCompleted, isSplashRendered },
    } = this;
    return (
      <RouteChangeInterceptor>
        <AnimationFadeLong isIn={isInitialLoadIn}>
          <GlobalStyle />
          <BodyBg />

          {isSplashRendered && <Splash isSplashCompleted={isSplashCompleted} />}

          <AnimationFadeLong isIn={isSplashCompleted}>
            <FlexColumn>
              <Switch>
                <Route
                  path="/"
                  exact
                  render={props => <Header {...props} isHome={true} />}
                />
                <Route component={Header} />
              </Switch>

              <ContentBackground
                top={homeHeaderHeight}
                clear={pathname.includes('onboarding')}>
                <Switch>
                  <Route component={Home} exact path="/" />
                  <Route component={Onboarding} path="/onboarding" />
                  <Route component={Login} path="/login" />
                  <Route component={Accounts} path="/accounts" />
                  <Route
                    component={Discovery}
                    path="/discovery/:category/:group?"
                  />
                  <Route component={ScanOffer} path="/scan-offer" />
                  <Route component={ScanResults} path="/scan-results" />
                  <Route component={Dev} path="/dev" />

                  <Route
                    component={Task}
                    path="/secure/:moduleSlug/:actionSlug/:taskSlug"
                    exact
                  />
                  <Route
                    component={Task}
                    path="/secure/:moduleSlug/:actionSlug/:taskSlug/:slideIndex"
                    exact
                  />
                  <Route
                    component={Action}
                    path="/secure/:moduleSlug/:actionSlug"
                    exact
                  />
                  <Route component={Module} path="/secure/:moduleSlug" exact />
                  <Route component={pageNotFound} path="/" />
                </Switch>
              </ContentBackground>
              {isNavShowing && (
                <NavContainer>
                  <NavLink to="/">Home</NavLink>
                  <NavLink to="/onboarding">Onboarding</NavLink>
                  <NavLink to="/login">Login</NavLink>
                  <NavLink to="/accounts">Accounts</NavLink>
                  <NavLink to="/dev">Dev</NavLink>
                  <hr />
                  <NavClickLink onClick={() => toggleVisualization()} small>
                    toggle viz
                  </NavClickLink>
                  <hr />
                  {profiles.map(profile => (
                    <NavClickLink
                      onClick={() => maybeLoadProfile(profile)}
                      small
                      key={profile.user.name}>
                      Load {profile.user.name}
                    </NavClickLink>
                  ))}
                </NavContainer>
              )}
            </FlexColumn>
          </AnimationFadeLong>
        </AnimationFadeLong>

        <ModalUnderlay />
        <InteractionsBlocker areInteractionsBlocked={areInteractionsBlocked} />
      </RouteChangeInterceptor>
    );
  }
}

const propTypes = {
  ui: PropTypes.shape({
    homeHeaderHeight: PropTypes.number.isRequired,
  }),
};
App.propTypes = propTypes;

export default compose(
  withRouter,
  WithUi,
  WithUser,
  WithAnimation
)(App);
