import React, { Component } from 'react';

import { slugify } from '../../utilities';

import { DISCOVERY_SLIDES_CONTENT } from '../../data/discoverySlidesContent.js';

const WithDiscovery = WrappedComponent => {
  return class extends Component {
    render() {
      const {
        history,
        match: {
          params: { category: categoryParam, group: groupParam = 'intro' },
        },
      } = this.props;

      const category = DISCOVERY_SLIDES_CONTENT[categoryParam];
      const categoryExists = !!category;
      const group = category.find(
        categoryObject => slugify(categoryObject.groupName) === groupParam
      );
      const groupExists = !!group;

      if (!categoryExists || !groupExists) {
        // invalid category, exit Discovery
        history.push('/');
        return <div />;
      }

      const groupIndex =
        category.findIndex(
          discoveryObject => slugify(discoveryObject.groupName) === groupParam
        ) || 0;

      return (
        <WrappedComponent
          category={category}
          categoryParam={categoryParam}
          group={group}
          groupIndex={groupIndex}
          {...this.props}
        />
      );
    }
  };
};

export default WithDiscovery;
