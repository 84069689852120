import React from 'react';
import PropTypes from 'prop-types';

import ReactSVG from 'react-svg';

import {
  Flex,
  FlexRow,
  FlexRowSVGContainer,
  PaperContentWidth,
} from '../styled/containers';
import { H1, P, TaskOutboundLink } from '../styled/type';
import {
  InitializeAnimationFadeDelay1,
  InitializeAnimationFadeDelay2,
  InitializeAnimationFadeDelay3,
} from '../styled/animation';

import { FALLBACK_SVG_TASK_STEP } from '../../constants/general.js';

const SlideStepTypeLink = ({
  step: { actionButton = {}, copy, heading },
  task: { name },
}) => {
  return (
    <PaperContentWidth wide paddingTop="xxl">
      <FlexRow>
        <Flex paddingRight="xl">
          <InitializeAnimationFadeDelay1>
            <H1>{heading ? heading : name}</H1>
            <P>{copy}</P>
          </InitializeAnimationFadeDelay1>
          {actionButton.actionButtonLink && (
            <InitializeAnimationFadeDelay3>
              <TaskOutboundLink
                href={actionButton.actionButtonLink}
                target="_blank"
                rel="noopener noreferrer">
                {actionButton.actionButtonText
                  ? actionButton.actionButtonText
                  : actionButton.actionButtonLink}
              </TaskOutboundLink>
            </InitializeAnimationFadeDelay3>
          )}
        </Flex>
        <Flex>
          <InitializeAnimationFadeDelay2>
            <FlexRowSVGContainer center>
              <ReactSVG src={FALLBACK_SVG_TASK_STEP} />
            </FlexRowSVGContainer>
          </InitializeAnimationFadeDelay2>
        </Flex>
      </FlexRow>
    </PaperContentWidth>
  );
};

const propTypes = {
  step: PropTypes.shape({
    actionButton: PropTypes.shape({
      actionButtonLink: PropTypes.string,
      actionButtonText: PropTypes.string,
    }),
    copy: PropTypes.string,
    heading: PropTypes.string,
  }),
  task: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};
SlideStepTypeLink.propTypes = propTypes;

export default SlideStepTypeLink;
