import styled, { keyframes } from 'styled-components';
import { fontSizer } from './mixins';

import { TRANSITION } from '../../constants/general.js';
const { SPLASH_DURATION } = TRANSITION;

export const SplashContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: ${props => props.theme.spacing.vertical.lg};
  text-align: center;
`;
export const SplashLogotype = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 50px;
  font-family: ${props => props.theme.type.family.heading};
  color: ${props => props.theme.colors.whiteAlt};
  ${props =>
    fontSizer(
      props.theme.type.size.splashLogotype,
      props.theme.type.multipliers.heading,
      props.theme.breakpoints
    )};
  font-weight: 300;
  letter-spacing: ${props => props.theme.type.letterSpacing.extraWide};
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
`;
export const SplashSphere = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 50%;
  background: ${props => props.theme.colors.blueDepthDot2};
  filter: blur(40px);
  border-radius: 50%;
  opacity: 0.5;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

const sphereKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  18% {
    opacity: 0;
  }
  66% {
    opacity: 1;
  }
  84% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
export const SphereAnimation = styled.div`
  opacity: 0;
  height: ${props => props.height && props.height};
  animation: ${sphereKeyframes} ${SPLASH_DURATION}ms linear forwards;
`;

const logotypeKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  16% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  84% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
export const LogotypeAnimation = styled.div`
  opacity: 0;
  animation: ${logotypeKeyframes} ${SPLASH_DURATION}ms linear forwards;
`;
