import React from 'react';

import { RightArrow } from './icons';

import styled from 'styled-components';
import { overloadedButton, transitionMd } from './mixins';

export const StyledInput = styled.input`
  ${overloadedButton};
  display: inline-block;
  border: 0;
`;

const FADE_HEIGHT = 26;

export const TableBottomFade = styled.div`
  position: absolute;
  top: ${props =>
    props.theme.hardCodedSizes.containedInnerPaperContentHeight -
    FADE_HEIGHT}px;
  left: 0;
  width: 100%;
  height: ${FADE_HEIGHT}px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 100)
  );
  z-index: ${props => props.theme.zIndex.one};
  pointer-events: none;
  opacity: ${props => (props.showBottomFade ? '1' : '0')};
  ${transitionMd};
`;
const StyledAccountTableContainer = styled.div`
  position: relative;
  height: ${props =>
    props.theme.hardCodedSizes.containedInnerPaperContentHeight}px;
`;
const StyledAccountTable = styled.div`
  position: relative;
  height: ${props =>
    props.theme.hardCodedSizes.containedInnerPaperContentHeight}px;
  overflow-y: scroll;
`;
export const AccountTableContainer = props => (
  <StyledAccountTableContainer>
    <TableBottomFade showBottomFade={props.showBottomFade} />
    <StyledAccountTable {...props}>{props.children}</StyledAccountTable>
  </StyledAccountTableContainer>
);

export const AccountListing = styled.span`
  ${overloadedButton};
  display: block;

  & + & {
    margin-top: ${props => props.theme.spacing.vertical.md};
  }
  &:last-child {
    margin-bottom: ${props => props.theme.spacing.vertical.md};
  }
`;

export const RightArrowButtonAlt = props => (
  <RightArrow color="primary" {...props} asButton tabIndex="0" />
);

export const DiscoveryIconSVGContainer = styled.div`
  width: 20px;
  height: 28px;
  padding-right: ${props => props.theme.spacing.horizontal.md};

  // target nested divs created by react-svg
  div {
    height: 100%;
  }
  svg {
    width: 100%;
    height: 100%;

    path {
      fill: ${props => props.theme.colors.grayb};
    }
  }
`;
