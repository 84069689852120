import React from 'react';
import PropTypes from 'prop-types';

import { checkIfCompleted, slugify } from '../../utilities';

import Accordion from '../Accordion';
import HomeActionList from './HomeActionList';
import WithProgress from '../hoc/WithProgress';

const HomeModuleAccordions = ({
  calculateOverlay,
  masterModuleList,
  progress,
}) =>
  masterModuleList.map(({ actions, isInactive, name }) => {
    const completedTasks = actions.flatMap(({ tasks }) =>
      tasks.filter(({ name }) => checkIfCompleted(slugify(name), progress))
    );
    const totalNumberOfTasks = actions.reduce(
      (total, { tasks }) => total + tasks.length,
      0
    );
    return (
      <Accordion
        key={name}
        heading={name}
        headingLink={!isInactive ? `/secure/${slugify(name)}?show-intro` : ''}
        progress={{
          numberCompleted: completedTasks.length,
          total: totalNumberOfTasks,
        }}
        body={actions.map(action => (
          <HomeActionList
            key={action.name}
            action={action}
            calculateOverlay={calculateOverlay}
            isInactive={isInactive}
            moduleSlug={slugify(name)}
          />
        ))}
      />
    );
  });

const propTypes = {
  calculateOverlay: PropTypes.func.isRequired,
  masterModuleList: PropTypes.array.isRequired,
};
HomeModuleAccordions.propTypes = propTypes;

export default WithProgress(HomeModuleAccordions);
