import { ASSET_PATH } from '../constants/general.js';

export const ONBOARDING_SLIDES_CONTENT = [
  {
    copy: 'Maybe you created your first digital account a long time ago',
    svg: `${ASSET_PATH}/svg/onboarding/01-AOL.svg`,
  },
  {
    copy: "Or maybe you've had one since before you could type.",
    svg: `${ASSET_PATH}/svg/onboarding/02-Insta.svg`,
  },
  {
    copy: 'Either way, you have a lot of them now.',
    svg: `${ASSET_PATH}/svg/onboarding/03-Accounts.svg`,
  },
  {
    copy: 'They make lots of things easier',
    svg: `${ASSET_PATH}/svg/onboarding/04-Categories.svg`,
  },
  {
    copy: 'To do this, they learn a lot of things about you.',
    svg: `${ASSET_PATH}/svg/onboarding/05-Data.svg`,
  },
  {
    copy: 'Which is mostly fine.',
    svg: `${ASSET_PATH}/svg/onboarding/06-Secure.svg`,
  },
  {
    copy: "But when it's not, we want you to be ready.",
    svg: `${ASSET_PATH}/svg/onboarding/07-Insecure.svg`,
  },
];
