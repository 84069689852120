import styled from 'styled-components';

import { overloadedButton, transitionMd } from './mixins';

const DOT_SIZE = 23;
const DOT_DISTANCE = 78;

export const DotContainer = styled.div`
  position: absolute;
  top: ${props => props.theme.spacing.vertical.xl};
  left: 50%;
  transform: translateX(-50%);
  z-index: ${props => props.theme.zIndex.one};
`;
export const Dot = styled.div`
  display: inline-block;
  position: relative;
  width: ${DOT_SIZE}px;
  height: ${DOT_SIZE}px;
  border-radius: 50%;
  background: ${props =>
    props.active ? props.theme.colors.gray7 : props.theme.colors.white};
  cursor: ${props => props.active && 'pointer'};
  pointer-events: ${props => !props.active && 'none'};
  ${transitionMd};
  border: 1px solid
    ${props =>
      !props.active
        ? props.theme.colors.grayFadeAlt
        : props.theme.colors.gray7};

  &:hover {
    background: ${props => props.active && props.theme.colors.gray9};
  }
  svg {
    display: none;
    width: ${DOT_SIZE}px;
    height: ${DOT_SIZE}px;
    padding: 20%;
    stroke: ${props => props.theme.colors.gray9};
  }
  &:last-child svg {
    display: flex;
    box-sizing: border-box;

    path {
      fill: ${props => props.active && props.theme.colors.white};
    }
  }
  & + & {
    margin-left: ${DOT_DISTANCE}px;

    &:before {
      content: '';
      position: absolute;
      top: ${DOT_SIZE / 2}px;
      right: calc(100% + 1px);
      margin-right: 0;
      width: calc(${DOT_DISTANCE}px);
      height: 1px;
      background-color: ${props => props.theme.colors.grayFadeAlt};
      pointer-events: none;
    }
  }
`;

export const TaskListing = styled.span`
  ${overloadedButton};
  display: block;
  cursor: default;
`;
