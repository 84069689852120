import React from 'react';
import PropTypes from 'prop-types';

import ReactSVG from 'react-svg';

import {
  FlexRowSVGContainer,
  FlexRow,
  Left,
  PaperContentWidth,
  Right,
} from '../styled/containers';
import { H1, P } from '../styled/type';
import {
  InitializeAnimationFadeDelay1,
  InitializeAnimationFadeDelay2,
} from '../styled/animation';

import { FALLBACK_SVG_TASK_INTRO } from '../../constants/general.js';

const SlideIntro = ({ task: { icon, introCopy, name } }) => {
  return (
    <PaperContentWidth wide paddingTop="xxl">
      <FlexRow>
        <Left>
          <InitializeAnimationFadeDelay1>
            <FlexRowSVGContainer>
              <ReactSVG src={icon ? icon : FALLBACK_SVG_TASK_INTRO} />
            </FlexRowSVGContainer>
          </InitializeAnimationFadeDelay1>
        </Left>
        <Right>
          <InitializeAnimationFadeDelay2>
            <H1>{name}</H1>
            <P>{introCopy}</P>
          </InitializeAnimationFadeDelay2>
        </Right>
      </FlexRow>
    </PaperContentWidth>
  );
};

const propTypes = {
  task: PropTypes.shape({
    icon: PropTypes.string,
    introCopy: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
};
SlideIntro.propTypes = propTypes;

export default SlideIntro;
