import React, { Component } from 'react';

import { MASTER_MODULE_LIST } from '../../data/masterModuleList.js';

const WithMasterModuleList = WrappedComponent => {
  return class extends Component {
    render() {
      return (
        <WrappedComponent
          masterModuleList={MASTER_MODULE_LIST}
          {...this.props}
        />
      );
    }
  };
};

export default WithMasterModuleList;
