import React, { Component } from 'react';
import { connect } from 'react-redux';

import DevAccountTable from './DevAccountTable';
import DevAddAccountForm from './DevAddAccountForm';

import { ContentWidth, Paper, PaperContentWidth } from '../styled/containers';
import { Section } from '../styled/dev';
import { H1, H2 } from '../styled/type';

class Accounts extends Component {
  render() {
    return (
      <ContentWidth wide paddingTop="xl">
        <Paper>
          <PaperContentWidth wide paddingTop="xl">
            <H1>Accounts</H1>

            <Section>
              <H2>Add Account</H2>
              <DevAddAccountForm />
            </Section>

            <Section>
              <H2>Accounts</H2>
              {this.props.accounts.length > 0 ? (
                <div>
                  <DevAccountTable
                    accounts={this.props.accounts}
                    showDeleteButton
                  />
                </div>
              ) : (
                <p>no accounts</p>
              )}
            </Section>
          </PaperContentWidth>
        </Paper>
      </ContentWidth>
    );
  }
}

const mapStateToProps = ({ accounts }) => ({
  accounts,
});

export default connect(mapStateToProps)(Accounts);
