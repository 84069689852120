import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PerspectiveCamera,
  Scene,
  SphereGeometry,
  WebGLRenderer,
} from 'three';

import styled from 'styled-components';
import { AnimationFadeLong } from './styled/animation';
import {
  SplashContainer,
  SplashLogotype,
  SplashSphere,
} from './styled/splash.js';
import { InitializeAnimationFadeDelay1 } from './styled/animation';
import { LogotypeAnimation, SphereAnimation } from './styled/splash';

import theme from '../components/styled/theme';
import { SKIP_SPLASH } from '../constants/general.js';

export const ThreeJsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

class Splash extends Component {
  componentDidMount() {
    if (!SKIP_SPLASH) {
      this.startRendering();
    }
  }
  componentWillUnmount() {
    if (!SKIP_SPLASH) {
      const canvas = document.getElementsByTagName('canvas')[0];
      const gl = canvas.getContext('webgl');
      gl.getExtension('WEBGL_lose_context').loseContext();

      this.scene = null;
      this.camera = null;
      this.renderer = null;
      this.group = null;
    }
  }
  startRendering() {
    // lifted from https://codepen.io/ryonakae/pen/EjwwoO
    this.scene = new Scene();
    this.camera = new PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    this.camera.position.set(0, 0, 250);

    this.renderer = new WebGLRenderer({
      alpha: true,
      antialias: true,
    });
    this.renderer.setSize(window.innerWidth / 1.4, window.innerHeight / 1.4);
    this.threeNode.append(this.renderer.domElement);

    this.group = new Object3D();

    const radius = 120;
    const separation = 10;

    for (let s = 0; s <= 180; s += separation) {
      const radianS = (s * Math.PI) / 180;
      const pZ = radius * Math.cos(radianS);

      for (let t = 0; t < 360; t += separation) {
        const radianT = (t * Math.PI) / 180;
        const pX = radius * Math.sin(radianS) * Math.cos(radianT);
        const pY = radius * Math.sin(radianS) * Math.sin(radianT);

        const geometory = new SphereGeometry(1.5, 6, 6);
        const material = new MeshBasicMaterial({
          color: theme.colors.special.blueDepthDot1,
        });
        const mesh = new Mesh(geometory, material);
        mesh.position.x = pX;
        mesh.position.y = pY;
        mesh.position.z = pZ;
        this.group.add(mesh);
      }
    }

    this.scene.add(this.group);

    const update = () => {
      if (!this.group) {
        return;
      }
      const rotateX = this.group.rotation.x + 0.002;
      const rotateY = this.group.rotation.y + 0.005;
      const rotateZ = this.group.rotation.z + 0.01;
      this.group.rotation.set(rotateX, rotateY, rotateZ);

      this.camera.lookAt(this.scene.position);
      this.renderer.render(this.scene, this.camera);

      requestAnimationFrame(update);
    };
    update();
  }
  render() {
    const { isSplashCompleted } = this.props;
    return (
      <SplashContainer>
        <AnimationFadeLong isIn={!isSplashCompleted}>
          <InitializeAnimationFadeDelay1>
            <SplashSphere />
          </InitializeAnimationFadeDelay1>

          <SphereAnimation height="100%">
            <ThreeJsContainer>
              <div ref={node => (this.threeNode = node)} />
            </ThreeJsContainer>
          </SphereAnimation>

          <SplashLogotype>
            <LogotypeAnimation>Digital Safety</LogotypeAnimation>
          </SplashLogotype>
        </AnimationFadeLong>
      </SplashContainer>
    );
  }
}

const propTypes = {
  isSplashCompleted: PropTypes.bool.isRequired,
};
Splash.propTypes = propTypes;

export default Splash;
