import React from 'react';
import PropTypes from 'prop-types';

import ReactSVG from 'react-svg';

import {
  InitializeAnimationFadeDelay1,
  InitializeAnimationFadeDelay2,
} from './styled/animation';

import { IntroSVGContainer, PaperContentWidth } from './styled/containers';
import { P } from './styled/type';

import { FALLBACK_SVG_MODULE_INTRO } from '../constants/general.js';

const SharedIntro = ({ icon, copy }) => (
  <PaperContentWidth paddingTop="lg">
    <InitializeAnimationFadeDelay1>
      <P centered>{copy}</P>
    </InitializeAnimationFadeDelay1>

    <InitializeAnimationFadeDelay2>
      <IntroSVGContainer>
        <ReactSVG src={icon ? icon : FALLBACK_SVG_MODULE_INTRO} />
      </IntroSVGContainer>
    </InitializeAnimationFadeDelay2>
  </PaperContentWidth>
);

const propTypes = {
  icon: PropTypes.string,
  copy: PropTypes.string,
};
SharedIntro.propTypes = propTypes;

export default SharedIntro;
