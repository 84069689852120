import React from 'react';

import Lock from '../Lock';

import styled from 'styled-components';
import { css } from 'styled-components';
import { transitionXs } from './mixins';

export const Icon = styled.span`
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: ${props =>
    props.marginRight && props.theme.spacing.vertical[props.marginRight]};
  padding-left: 3px;
  padding-right: ${props =>
    props.paddingRight && props.theme.spacing.horizontal[props.paddingRight]};

  svg {
    display: block;
    width: ${props => (props.large ? '25px' : '14px')};
    height: ${props => (props.large ? '25px' : '14px')};
    fill: ${props =>
      props.isComplete ? props.theme.colors.primary : props.theme.colors.grayb};
  }
`;

export const LockIcon = props => (
  <Icon {...props} children={props.children}>
    <Lock />
  </Icon>
);

const arrow = css`
  position: relative;
  left: 5px;
  width: 13px;
  height: 13px;
  border-top: 2px solid
    ${props =>
      props.color ? props.theme.colors[props.color] : props.theme.colors.gray9};
  border-right: 2px solid
    ${props =>
      props.color ? props.theme.colors[props.color] : props.theme.colors.gray9};
  cursor: ${props => props.asButton && 'pointer'};
  ${transitionXs};

  &:hover,
  &:focus {
    left: ${props => props.asButton && '8px'};
  }
`;
export const RightArrow = styled.div`
  ${arrow};
  transform: rotate(45deg) translateY(0);
`;
export const LeftArrow = styled.div`
  ${arrow};
  transform: rotate(-135deg) translateY(0);
`;
