import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

const WithProgress = WrappedComponent => {
  return class extends Component {
    render() {
      return (
        <WrappedComponent progress={this.props.progress} {...this.props} />
      );
    }
  };
};

const mapStateToProps = ({ progress }) => ({
  progress,
});
export default compose(
  connect(mapStateToProps),
  WithProgress
);
