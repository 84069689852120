import styled, { keyframes } from 'styled-components';

import { transitionLg, transitionMd } from './mixins';

export const AnimationFade = styled.div`
  opacity: ${props => (props.isIn ? 1 : 0)};
  ${transitionMd};
  pointer-events: ${props => !props.isIn && 'none'};
`;
export const AnimationFadeLong = styled.div`
  height: 100%; // needed for full-height Home FlexColumn
  opacity: ${props => (props.isIn ? 1 : 0)};
  ${transitionLg};
  pointer-events: ${props => !props.isIn && 'none'};
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const InitializeAnimationFade = styled.div`
  animation: ${fadeIn} ${props => props.theme.transition.duration.lg} linear;
`;

export const InitializeAnimationFadeDelay0 = styled.div`
  opacity: 0;
  animation: ${fadeIn} ${props => props.theme.transition.duration.sm} linear
    ${props => props.theme.transition.duration.xs} forwards;
`;
export const InitializeAnimationFadeDelay1 = styled.div`
  opacity: 0;
  animation: ${fadeIn} ${props => props.theme.transition.duration.md} linear
    ${props => props.theme.transition.duration.sm} forwards;
`;
export const InitializeAnimationFadeDelay2 = styled.div`
  opacity: 0;
  animation: ${fadeIn} ${props => props.theme.transition.duration.lg} linear
    ${props => props.theme.transition.duration.md} forwards;
`;
export const InitializeAnimationFadeDelay3 = styled.div`
  opacity: 0;
  height: ${props => props.height && props.height};
  animation: ${fadeIn} ${props => props.theme.transition.duration.xl} linear
    ${props => props.theme.transition.duration.lg} forwards;
`;
export const InitializeAnimationFadeDelay4 = styled.div`
  opacity: 0;
  animation: ${fadeIn} ${props => props.theme.transition.duration.xxl} linear
    ${props => props.theme.transition.duration.xl} forwards;
`;
export const InitializeAnimationFadeDelay5 = styled.div`
  opacity: 0;
  animation: ${fadeIn} ${props => props.theme.transition.duration.xxxl} linear
    ${props => props.theme.transition.duration.xxl} forwards;
`;
