import styled from 'styled-components';

export const Section = styled.div`
  padding-bottom: ${props => props.theme.spacing.vertical.xl};
`;
export const InnerSection = styled.div`
  padding: 0 0 ${props => props.theme.spacing.vertical.xs}
    ${props => props.theme.spacing.horizontal.md};
`;
export const DevStyleOverrides = styled.div`
  a {
    color: ${props => props.theme.colors.primary};

    &:hover {
      text-decoration: underline;
    }
  }
  p {
    margin-bottom: 5px !important;
  }
  ul {
    padding-bottom: 15px;
  }
  hr {
    margin-right: 160px;
    opacity: 0.2;
  }
`;
