import React from 'react';
import PropTypes from 'prop-types';

import {
  filterAccountsByCategoryAndGroup,
  filterOtherAccountsByCategoryAndExcludedGroups,
} from '../../utilities';

import AccountTable from './sub-components/AccountTable';
import AddAccountForm from './sub-components/AddAccountForm';

import { H1, P } from '../styled/type';
import { Flex, FlexRow, PaperContentWidth } from '../styled/containers';
import {
  InitializeAnimationFadeDelay1,
  InitializeAnimationFadeDelay2,
  InitializeAnimationFadeDelay3,
} from '../styled/animation';

import { ACCOUNT_CATEGORIES } from '../../data/accountCategories';

const SlideAccountEntry = ({
  accounts,
  category,
  categoryParam,
  copy,
  groupName,
  title,
}) => {
  // "named groups" are the category's groups without intro/other/summary/conclusion
  const excludedGroups = category
    .slice(1, category.length - 3)
    .map(groupObject => groupObject.groupName);

  const filteredAccounts =
    groupName === 'other'
      ? filterOtherAccountsByCategoryAndExcludedGroups(
          accounts,
          categoryParam,
          excludedGroups
        )
      : filterAccountsByCategoryAndGroup(accounts, categoryParam, groupName);

  const { icon } = ACCOUNT_CATEGORIES.find(
    category => category.name === categoryParam
  );

  return (
    <PaperContentWidth wide paddingTop="xl">
      <FlexRow paddingTop="xl">
        <Flex paddingRight="xxl">
          <InitializeAnimationFadeDelay1>
            <H1>{title}</H1>
            <P maxWidth="80%">{copy}</P>
          </InitializeAnimationFadeDelay1>

          <InitializeAnimationFadeDelay2>
            <AddAccountForm
              groupName={groupName}
              categoryParam={categoryParam}
              icon={icon}
            />
          </InitializeAnimationFadeDelay2>
        </Flex>

        <Flex paddingLeft="xl">
          <InitializeAnimationFadeDelay3>
            <AccountTable
              filteredAccounts={filteredAccounts}
              showDeleteButton
            />
          </InitializeAnimationFadeDelay3>
        </Flex>
      </FlexRow>
    </PaperContentWidth>
  );
};

const propTypes = {
  accounts: PropTypes.array.isRequired,
  category: PropTypes.array.isRequired,
  categoryParam: PropTypes.string.isRequired,
  copy: PropTypes.string,
  groupName: PropTypes.string.isRequired,
};
SlideAccountEntry.propTypes = propTypes;

export default SlideAccountEntry;
