import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { slugify } from '../../utilities';

import AnimationFadeRoute from '../AnimationFadeRoute';
import Breadcrumbs from '../Breadcrumbs';
import ButtonGroup from '../ButtonGroup';
import SlideAccountEntry from './SlideAccountEntry';
import SlideConclusion from './SlideConclusion';
import SlideSummary from './SlideSummary';
import SharedIntro from '../SharedIntro';
import WithAccounts from '../hoc/WithAccounts';
import WithAnimation from '../hoc/WithAnimation';
import WithDiscovery from '../hoc/WithDiscovery';

import { AnimationFade } from '../styled/animation';
import { ContentWidth, Paper } from '../styled/containers';

import { TRANSITION } from '../../constants/general.js';
const { MD_DURATION } = TRANSITION;

const DELAY_UNTIL_LEAVING_DISCOVERY = 5000;

class Discovery extends Component {
  constructor(props) {
    super(props);

    const { category, groupIndex } = this.props;
    const ActiveSlide = this.getAppropriateDiscoverySlideComponent();

    this.state = {
      ActiveSlide,
      activeSlideIndex: groupIndex,
      numberOfSlides: category.length,
      isMounted: true,
      exiting: false,
    };

    this.boundAdvanceSlide = this.advanceSlide.bind(this);
    this.boundPreviousSlide = this.previousSlide.bind(this);
  }
  componentDidMount() {
    const { activeSlideIndex, numberOfSlides, isMounted, exiting } = this.state;

    if (activeSlideIndex === numberOfSlides - 1 && !exiting) {
      this.setState({
        exiting: true,
      });
      clearTimeout(this.exitTimeout);
      this.exitTimeout = setTimeout(() => {
        if (isMounted && exiting) {
          this.exit();
        }
      }, DELAY_UNTIL_LEAVING_DISCOVERY);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const ActiveSlide = this.getAppropriateDiscoverySlideComponent();
      const activeSlideIndex = this.getSlideIndex();

      this.setState({
        ActiveSlide,
        activeSlideIndex,
      });

      if (
        activeSlideIndex === this.state.numberOfSlides - 1 &&
        !this.state.exiting
      ) {
        this.setState({
          exiting: true,
        });
        clearTimeout(this.exitTimeout);
        this.exitTimeout = setTimeout(() => {
          if (this.state.isMounted && this.state.exiting) {
            this.exit();
          }
        }, DELAY_UNTIL_LEAVING_DISCOVERY);
      } else {
        this.setState({
          exiting: false,
        });
      }
    }
  }
  componentWillUnmount() {
    clearTimeout(this.exitTimeout);
    this.setState({
      isMounted: false,
    });
  }
  getAppropriateDiscoverySlideComponent() {
    const {
      group: { groupName },
    } = this.props;

    if (groupName === 'summary') {
      return SlideSummary;
    } else if (groupName === 'conclusion') {
      return SlideConclusion;
    } else if (groupName === 'intro') {
      return SharedIntro;
    } else {
      return SlideAccountEntry;
    }
  }
  getSlideIndex() {
    const {
      category,
      group: { groupName },
    } = this.props;

    const groupIndex =
      category.findIndex(groupObject => groupObject.groupName === groupName) ||
      0;

    return groupIndex;
  }
  advanceSlide() {
    const {
      props: { category, categoryParam, history, setIsSlideIn },
      state: { activeSlideIndex, numberOfSlides },
    } = this;

    const nextLocation =
      activeSlideIndex + 1 > numberOfSlides - 1
        ? '/'
        : `/discovery/${categoryParam}/${slugify(
            category[activeSlideIndex + 1].groupName
          )}`;

    setIsSlideIn(false);

    setTimeout(() => {
      history.push(nextLocation);
    }, MD_DURATION);
  }
  previousSlide() {
    const {
      props: { category, categoryParam, history, setIsSlideIn },
      state: { activeSlideIndex },
    } = this;

    const previousLocation =
      activeSlideIndex - 1 < 0
        ? '/'
        : `/discovery/${categoryParam}/${slugify(
            category[activeSlideIndex - 1].groupName
          )}`;

    setIsSlideIn(false);

    setTimeout(() => {
      history.push(previousLocation);
    }, MD_DURATION);
  }
  exit() {
    this.props.setIsRouteIn(false);

    setTimeout(() => {
      this.props.history.push('/');
    }, MD_DURATION);
  }
  render() {
    const {
      props: {
        accounts,
        animation: { isSlideIn },
        category,
        categoryParam,
        group: { groupName, copy, introSvg, title },
      },
      state: { ActiveSlide, activeSlideIndex, numberOfSlides },
    } = this;

    const isPenultimateSlide = activeSlideIndex >= numberOfSlides - 2;
    const isFinalSlide = activeSlideIndex >= numberOfSlides - 1;
    let buttonPrimaryText;
    let buttonSecondaryText;

    if (isPenultimateSlide) {
      buttonSecondaryText = 'Back';
      buttonPrimaryText = 'Done';
    } else if (activeSlideIndex === 0) {
      buttonSecondaryText = 'Not Now';
      buttonPrimaryText = 'Get Started';
    } else {
      buttonSecondaryText = 'Back';
      buttonPrimaryText = 'Next';
    }

    return (
      <AnimationFadeRoute>
        <ContentWidth wide paddingTop="lg2">
          <Breadcrumbs dummyBreadcrumb={`Add ${categoryParam} Accounts`} />

          <Paper>
            <AnimationFade isIn={isSlideIn}>
              <ActiveSlide
                accounts={accounts}
                activeSlideIndex={activeSlideIndex}
                category={category}
                categoryParam={categoryParam}
                copy={copy}
                groupName={groupName}
                icon={introSvg}
                numberOfSlides={numberOfSlides}
                title={title}
              />
            </AnimationFade>

            <AnimationFade isIn={!isFinalSlide}>
              <ButtonGroup
                buttonSecondaryText={buttonSecondaryText}
                buttonPrimaryText={buttonPrimaryText}
                onClickButtonSecondary={this.boundPreviousSlide}
                onClickButtonPrimary={this.boundAdvanceSlide}
              />
            </AnimationFade>
          </Paper>
        </ContentWidth>
      </AnimationFadeRoute>
    );
  }
}

const propTypes = {
  accounts: PropTypes.array.isRequired,
  addAccount: PropTypes.func.isRequired,
  category: PropTypes.array.isRequired,
  categoryParam: PropTypes.string.isRequired,
  group: PropTypes.shape({
    copy: PropTypes.string,
    groupName: PropTypes.string,
    introSvg: PropTypes.string,
    title: PropTypes.string,
  }),
  groupIndex: PropTypes.number,
  setIsRouteIn: PropTypes.func.isRequired,
  setIsSlideIn: PropTypes.func.isRequired,
};
Discovery.propTypes = propTypes;

export default compose(
  WithAccounts,
  WithAnimation,
  WithDiscovery
)(Discovery);
