import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import AnimationFadeRoute from '../AnimationFadeRoute';
import Breadcrumbs from '../Breadcrumbs';
import ModuleActions from './ModuleActions';
import ModuleIntro from './ModuleIntro';
import WithAction from '../hoc/WithAction';
import WithAnimation from '../hoc/WithAnimation';

import { ContentWidth, Paper } from '../styled/containers';

import { TRANSITION } from '../../constants/general.js';
const { MD_DURATION } = TRANSITION;

class Module extends Component {
  constructor(props) {
    super(props);

    const {
      location: { search },
    } = this.props;
    const showIntro = search.includes('show-intro');

    this.state = {
      ActiveSlide: showIntro ? ModuleIntro : ModuleActions,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const {
      location: { search },
    } = this.props;

    // browser back button use cases
    if (
      search.includes('show-intro') &&
      this.state.ActiveSlide === ModuleActions
    ) {
      this.setState({
        ActiveSlide: ModuleIntro,
      });
    } else if (
      !search.includes('show-intro') &&
      this.state.ActiveSlide === ModuleIntro
    ) {
      this.setState({
        ActiveSlide: ModuleActions,
      });
    }
  }
  exit() {
    this.props.setIsRouteIn(false);

    setTimeout(() => {
      this.props.history.goBack();
    }, MD_DURATION);
  }
  advanceSlide() {
    const {
      history,
      location: { pathname },
      setIsPaperIn,
    } = this.props;

    setIsPaperIn(false);

    setTimeout(() => {
      // only 'intro' uses advanceSlide(), and this will remove `?show-intro`
      history.push(pathname);
    }, MD_DURATION);
  }
  render() {
    const {
      props: {
        match: {
          params: { moduleSlug },
        },
        module,
      },
      state: { ActiveSlide },
    } = this;

    return (
      <AnimationFadeRoute>
        <ContentWidth wide paddingTop="lg2">
          <Breadcrumbs moduleName={module.name} />

          <Paper>
            <ActiveSlide
              advanceSlide={this.advanceSlide.bind(this)}
              exit={this.exit.bind(this)}
              module={module}
              moduleSlug={moduleSlug}
            />
          </Paper>
        </ContentWidth>
      </AnimationFadeRoute>
    );
  }
}

const propTypes = {
  module: PropTypes.shape({
    actions: PropTypes.array,
    name: PropTypes.string.isRequired,
  }),
  setIsRouteIn: PropTypes.func.isRequired,
  setIsPaperIn: PropTypes.func.isRequired,
};
Module.propTypes = propTypes;

export default compose(
  WithAction,
  WithAnimation
)(Module);
