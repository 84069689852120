import React, { Component } from 'react';
import PropTypes from 'prop-types';

import times from 'lodash/times';
import union from 'lodash/union';
import uuid from 'uuid';
import isEqual from 'lodash/isEqual';

import { findAccountInAccountsByName } from '../../utilities';
import { getColor, phyllotaxis } from '../../utilities/visualization';

import styled from 'styled-components';
import { VisualizationContainer } from '../styled/containers';

const HEIGHT = 560;
const RADIUS = 24.6;
const WIDTH = 560;
const FILLER_MINIMUM = 40;

const Circle = styled.circle`
  fill: ${props => props.displayState.color};
`;
const Svg = styled.svg`
  height: 560px;
  width: 560px;
  transform: scale(0.5) translateX(-50%) translateY(-50%);

  @media (min-width: ${props => props.theme.breakpoints.lg}px) {
    transform: scale(0.8) translateX(-10%) translateY(-10%);
  }
  @media (min-width: ${props => props.theme.breakpoints.xl}px) {
    transform: scale(1);
  }
`;

class Spiral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _hasMounted: false,
    };
  }
  componentDidMount() {
    this.setState({
      _hasMounted: false,
    });
  }
  shouldComponentUpdate(nextProps) {
    const overlayHasChanged = !isEqual(nextProps.overlay, this.props.overlay);
    // only render the first time or overlay changes
    return overlayHasChanged || this.state._hasMounted;
  }
  translateOverlayToDisplayState(account, overlay = {}) {
    /* console.log('translateOverlayToDisplayState()', account, overlay) */
    const isActive =
      !overlay.isEnabled ||
      (account &&
        findAccountInAccountsByName(account.name, overlay.activeAccounts));
    return {
      color: getColor(account.category, isActive),
    };
  }
  render() {
    const { calculateOverlay, dataset, launchModal, overlay } = this.props;

    const fillerAmount = Math.max(dataset.length * 1.8, FILLER_MINIMUM);

    const filler =
      dataset.length < fillerAmount
        ? times(fillerAmount - dataset.length, () => ({
            name: 'dummy',
            category: 'dummy',
          }))
        : {};

    const datasetPlusFiller = union(dataset, filler);

    const newDataset = datasetPlusFiller.map((d, i) => {
      d.pos = phyllotaxis(RADIUS, WIDTH, HEIGHT, i + 1);
      d.isHollow = false; // stub
      d.displayState = this.translateOverlayToDisplayState(d, overlay);
      return d;
    });

    return (
      <VisualizationContainer>
        <Svg>
          {newDataset.map(d => (
            <Circle
              key={uuid()}
              account={d.name}
              accountCategory={d.category}
              className="circle"
              displayState={d.displayState}
              hollow={d.isHollow}
              cx={d.pos.x}
              cy={d.pos.y}
              onClick={() => d.category !== 'dummy' && launchModal(d)}
              onMouseEnter={() =>
                d.category !== 'dummy' && calculateOverlay(true, d)
              }
              onMouseLeave={() => calculateOverlay(false)}
              r={14}
            />
          ))}
        </Svg>
      </VisualizationContainer>
    );
  }
}

const propTypes = {
  calculateOverlay: PropTypes.func.isRequired,
  dataset: PropTypes.array.isRequired,
  overlay: PropTypes.object,
};
Spiral.propTypes = propTypes;

export default Spiral;
