import {
  ADD_ACCOUNT,
  DELETE_ACCOUNT_BY_ID,
  DELETE_ACCOUNT_BY_NAME,
  DELETE_ALL_ACCOUNTS,
} from '../constants/actions';

const initialState = [];

export default function accounts(state = initialState, action) {
  switch (action.type) {
    case ADD_ACCOUNT:
      return [...state, action.newAccount];
    case DELETE_ACCOUNT_BY_ID:
      return state.filter(account => account.id !== action.id);
    case DELETE_ACCOUNT_BY_NAME:
      return state.filter(account => account.name !== action.name);
    case DELETE_ALL_ACCOUNTS:
      return initialState;
    default:
      return state;
  }
}
