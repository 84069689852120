import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  hideModal,
  setHomeHeaderHeight,
  showModal,
  toggleModal,
  toggleVisualization,
} from '../../actions';

const WithUi = WrappedComponent => {
  return class extends Component {
    render() {
      return <WrappedComponent ui={this.props.ui} {...this.props} />;
    }
  };
};

const mapStateToProps = ({ ui }) => ({
  ui,
});
export default compose(
  connect(
    mapStateToProps,
    {
      hideModal,
      setHomeHeaderHeight,
      showModal,
      toggleModal,
      toggleVisualization,
    }
  ),
  WithUi
);
