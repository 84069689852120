// copy doc: https://docs.google.com/document/d/1dETPDvtZ9XKwKifEaX8MCUuSuLLM3NeP1bl7ADgx464/edit#

import {
  anonymizeFinancialAccountsTasks,
  checkAccountBreachTasks,
  checkKidsCreditTasks,
  makeYourLoginsSaferTasks,
  monitorAndFreezeCreditTasks,
  setFinancialAlertsTasks,
  use2FATasks,
  usePasswordManagerTasks,
} from './masterTaskList';

import { ASSET_PATH } from '../constants/general.js';

export const secureYourFinancesActions = [
  {
    name: 'Anonymize your financial accounts',
    shortName: 'Anonymize your finances',
    tagline: 'Be Anonymous',
    introCopy:
      'If you’re like most people, you probably use one email address for everything: shopping, news, banking, and so on. Chances are, that email address has been exposed online at some point. When your email address is combined with other information about you - and especially when the email address actually contains information like your name -- it gives data thieves more information about you, and makes your most sensitive accounts more vulnerable to attacks.',
    copy:
      'Lock down your critical account information and make it less vulnerable to a breach with these simple tasks for making your financial accounts more secret.',
    icon: `${ASSET_PATH}/svg/02-ModuleActionsA.svg`,
    tasks: anonymizeFinancialAccountsTasks,
  },
  {
    name: 'Set alerts for financial transactions',
    shortName: 'Set financial alerts',
    tagline: 'Be Aware',
    introCopy:
      'Think about online banking app or website as your financial health monitor; if you were having a heart attack, wouldn’t you want to be alerted? Likewise, if your bank account were being drained by an imposter, you’d want to be notified quickly. Nearly all financial institutions let you set notifications (SMS, notification, or email) that will help you keep track of your finances and flag any suspicious activity.',
    copy:
      'It might take a few minutes out of your day to customize your banking alerts and then keep tabs on your notifications, but the peace of mind is worth it.',
    icon: `${ASSET_PATH}/svg/02-ModuleActionsB.svg`,
    tasks: setFinancialAlertsTasks,
  },
];
export const protectYourAccountsActions = [
  {
    name: 'Make your logins safer',
    shortName: 'Make your logins safer',
    tagline: 'Be protected',
    introCopy:
      'Strong passwords are like safety deposit boxes or a locker at the gym. Upgrade your passwords to put stronger locks on the places that store your digital information. And don’t give thieves a side-door into your accounts -- update your security questions to be less hackable. ',
    copy:
      'These three tasks are like a three-legged stool to help keep your most critical logins safe.',
    icon: `${ASSET_PATH}/svg/protect-accounts/protect-login.svg`,
    tasks: makeYourLoginsSaferTasks,
  },
  {
    name: 'Use a password manager',
    shortName: 'Use a password manager',
    tagline: 'Be a protector',
    introCopy:
      'Hackers and ID thieves use all the latest tools. You should too. Password managers are easy to use, and they make it easy to have strong and memorable passwords without breaking your brain. Before you get started on these tasks, do a little research to find a solid password manager. Popular password managers today include 1Password and LastPass, and typically cost between $20 and $40 a year (more for family plans with multiple logins). Apple also has some password management capabilities built in to its Safari browser, which you can use in conjunction with password managers.',
    copy:
      'Get up and running on your new password manager here. You’ll be glad you did.',
    icon: `${ASSET_PATH}/svg/protect-accounts/protect-password.svg`,
    tasks: usePasswordManagerTasks,
  },
  {
    name: 'Use two-factor authentication',
    shortName: 'Use two-factor authentication',
    tagline: "Be 2FA'd",
    introCopy:
      "Two-factor authentication (2FA) is like a second lock, and it confirms that it's really you accessing your account.",
    copy:
      '2FA adds an extra step to your log-in procedure by asking for a second form of authentication besides just your password - typically a code delivered to your mobile device that you must enter in order to log in. Get started here.',
    icon: `${ASSET_PATH}/svg/protect-accounts/protect-2fa.svg`,
    tasks: use2FATasks,
  },
];
export const secureYourCriticalDataActions = [
  {
    name: 'Check your accounts for breaches',
    shortName: 'Check account breaches',
    tagline: 'Breach Alert',
    introCopy:
      'Data breaches are so commonplace these days that there’s a good chance you’ve been the victim - and you might not even know it. Find out if your data has been compromised - and what to do about it.',
    copy:
      'So you have breached accounts. Don’t despair! You’re not alone. We’ll show you what to do next.',
    icon: `${ASSET_PATH}/svg/secure-data/data-breach.svg`,
    tasks: checkAccountBreachTasks,
  },
];
export const protectYourCreditActions = [
  {
    name: 'Monitor and freeze your credit',
    shortName: 'Monitor and protect credit',
    tagline: 'Be protected',
    introCopy:
      'The three big credit agencies are required by law to give you a free annual credit report - it’s that important. Regular credit monitoring should be a required part of your credit health checkup. Freezing your credit will take a bit more legwork - you’ll need to contact each of the three major credit companies - but the peace of mind will make it worth your while. And it’s also completely free.',
    copy:
      'Evaluate and protect your credit by taking these simple precautions.',
    icon: `${ASSET_PATH}/svg/protect-credit/credit-monitor.svg`,
    tasks: monitorAndFreezeCreditTasks,
  },
  {
    name: "Check your kids' credit",
    shortName: 'Check your child’s credit',
    tagline: 'Be a protector',
    introCopy:
      'Child identity theft is big business, and it can fly under the radar since kids don’t check their credit scores or try to get credit. Sometimes identity theft of kids doesn’t get discovered until they are old enough to try to get their first credit card - and get denied. Of the big three credit bureaus, only Experian allows you to scan your kids’ social security numbers to make sure they do not have credit reports. The other two bureaus require that you mail a request if your child is under 14. You are entitled to freeze the credit of your kids under 16, though you’ll have to do it in writing. To avoid problems in the first place, be extra cautious about giving out your kids’ social security numbers.',
    copy:
      'A child shouldn’t have a credit report at all, so a good first step to securing your child’s credit is to make sure she doesn’t have any reports at the credit bureaus. If she does, it’s time to start cleaning up.',
    icon: `${ASSET_PATH}/svg/protect-credit/credit-kids.svg`,
    tasks: checkKidsCreditTasks,
  },
];
