import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import HomeHeader from './home/HomeHeader';
import HeaderInner from './HeaderInner';
import WithAccounts from './hoc/WithAccounts';
import WithMasterModuleList from './hoc/WithMasterModuleList';

import { AnimationFade } from './styled/animation';
import { HeaderContainer, Hr } from './styled/header';

class Header extends Component {
  render() {
    const { accounts, isHome, masterModuleList } = this.props;
    return (
      <HeaderContainer>
        <HeaderInner accounts={accounts} masterModuleList={masterModuleList} />
        <Hr />
        <AnimationFade isIn={isHome}>
          <HomeHeader isHome={isHome} />
        </AnimationFade>
      </HeaderContainer>
    );
  }
}

const propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      groups: PropTypes.array,
      tasks: PropTypes.array,
    })
  ),
  isHome: PropTypes.bool,
  masterModuleList: PropTypes.array.isRequired,
};
Header.propTypes = propTypes;

export default compose(
  WithAccounts,
  WithMasterModuleList,
  withRouter
)(Header);
